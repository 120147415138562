import { Icon } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Frequency } from '@/types';
import { prettifyDate, prettifyDateRange } from '@/utils/prettifier/transformDateToTime';
import { getGermanWeekday, transformDateToWeekdayAndDate } from '@/utils/prettifier/transformDateToWeekdayAndDate';

import { InfoBox, InfoBoxContent, InfoBoxIcon } from '../info-box';

type DateInfoBoxProps = {
  startsAt: Date;
  endsAt?: Date;
  lastDate?: Date;
  frequency: Frequency;
};

export const DateInfoBox: React.FC<DateInfoBoxProps> = ({ startsAt, endsAt, frequency, lastDate }) => {
  const { t } = useTranslation();

  return (
    <InfoBox>
      <InfoBoxIcon>
        <Icon iconKey='clock' />
      </InfoBoxIcon>
      <InfoBoxContent>
        {frequency === Frequency.ONCE && (
          <>
            <span className='break-words'>{transformDateToWeekdayAndDate(startsAt)}</span>
            <span className='break-words'>{prettifyDateRange(startsAt, endsAt)}</span>
          </>
        )}
        {frequency === Frequency.WEEKLY && (
          <>
            <span className='break-words'>{t('globals.date.repeat.weekly', { day: getGermanWeekday(startsAt) })}</span>
            <span className='break-words'>{prettifyDateRange(startsAt, endsAt)}</span>
            {!!lastDate && (
              <span className='break-words'>
                {t('globals.date.repeat.weekly_until', { date: prettifyDate(lastDate) })}
              </span>
            )}
          </>
        )}
      </InfoBoxContent>
    </InfoBox>
  );
};
