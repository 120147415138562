import { Button } from '@component-library';
import cx from 'classnames';
import React from 'react';

export type AddActivityFooterProps = {
  leftButton?: { label: string; onClick: () => void };
  rightButton?: { label: string; isLoading?: boolean };
};

export const AddActivityFooter: React.FC<AddActivityFooterProps> = ({ leftButton, rightButton }) => {
  const justifyContent = (() => {
    if (leftButton && rightButton) return 'justify-between';
    if (leftButton) return 'justify-start';
    return 'justify-end';
  })();

  return (
    <div
      className={cx(
        'fixed bottom-0 w-full flex flex-row items-center h-16 border-t border-gray-600 shadow-sm p-3 bg-white z-20',
        justifyContent,
      )}
    >
      {leftButton && (
        <Button onClick={leftButton.onClick} type='button'>
          {leftButton.label}
        </Button>
      )}
      {rightButton && (
        <Button variant='contained' type='submit' isLoading={rightButton.isLoading}>
          {rightButton.label}
        </Button>
      )}
    </div>
  );
};
