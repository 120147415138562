import { config } from '@config';
import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: config.FIREBASE.apiKey,
  authDomain: config.FIREBASE.authDomain,
  projectId: config.FIREBASE.projectId,
  storageBucket: config.FIREBASE.storageBucket,
  messagingSenderId: config.FIREBASE.messagingSenderId,
  appId: config.FIREBASE.appId,
  measurementId: config.FIREBASE.measurementId,
};

export const firebaseApp = initializeApp(firebaseConfig);
