import React from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingOverlay } from '@/components/loading-overlay';
import { useAuth } from '@/lib/auth';

export const RequireBoot: React.FC = () => {
  const { state } = useAuth();

  if (state === 'BOOTING') return <LoadingOverlay />;

  return <Outlet />;
};
