import { Divider } from '@component-library';
import React from 'react';

export const MyActivitiesDetailsSkeleton: React.FC = () => {
  return (
    <div className='animate-pulse'>
      <div className='flex flex-row ml-7 items-center gap-2 mb-4'>
        <span className='h-8 w-36 bg-gray-300 rounded-full' />
      </div>
      <Divider className='border-gray-300' />
      <div className='px-5'>
        <div className='flex flex-col mt-7 items-start gap-4 m-auto max-w-sm'>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-32 h-4 bg-gray-300 rounded-full' />
              <span className='w-24 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-28 h-4 bg-gray-300 rounded-full' />
              <span className='w-36 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
              <span className='w-24 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
        </div>
        <div className='ml-1 mt-8 flex flex-row justify-between'>
          <div className='flex flex-row h-98'>
            <div className='w-8 h-8 bg-gray-300 rounded-full' />
            <div className='w-8 h-98 bg-gray-300 rounded-full -ml-1.5' />
            <div className='-ml-1.5 h-full w-24 bg-gray-300 flex items-center rounded-full'></div>
          </div>
          <span className='bg-gray-300 h-8 w-20 rounded-full'></span>
        </div>
      </div>
    </div>
  );
};
