import { Divider, Icon } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { normalizeDSTForServer as normalizeDST } from '@/utils/prettifier/transformDateToTime';

import { MyActivitiesIndexActivity } from './components/activity';
import { MyActivitiesIndexActivitySkeleton } from './components/activity-skeleton';
import { MyActivitiesIndexNoActivities } from './components/no-activities';
import { useMyActivities } from './hooks/useMyActivities';

const Content: React.FC = () => {
  const { t } = useTranslation();
  const dateThreeHoursAgo = new Date();
  dateThreeHoursAgo.setHours(dateThreeHoursAgo.getHours() - 3);

  const { data, status } = useMyActivities({ queryParams: { after: normalizeDST(dateThreeHoursAgo).toISOString() } });

  if (status !== 'success') {
    return (
      <>
        <MyActivitiesIndexActivitySkeleton />
        <Divider className='border-gray-300' />
        <MyActivitiesIndexActivitySkeleton />
        <Divider className='border-gray-300' />
        <MyActivitiesIndexActivitySkeleton />
      </>
    );
  }

  if (data.length === 0) {
    return <MyActivitiesIndexNoActivities />;
  }

  return (
    <>
      {data.map((activity, index) => (
        <React.Fragment key={index}>
          <MyActivitiesIndexActivity
            id={activity.id}
            title={activity.title ?? t(`globals.sportType.${activity.sport}`)}
            startsAt={activity.startsAt}
            endsAt={activity.endsAt}
            frequency={activity.frequency}
            location={activity.location}
            slots={activity.slots}
            participants={activity.participants}
            commentCount={activity.commentsCount}
          />
          {index + 1 !== data.length && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};

export const MyActivitiesIndexPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'my-activities' });

  return (
    <div>
      <div className='flex flex-row ml-7 items-center gap-2 mb-4'>
        <Icon iconKey='sports' />
        <span className='text-2xl text-gray-600'>{t('title')}</span>
      </div>
      <Divider />
      <Content />
    </div>
  );
};
