export enum SportType {
  BADMINTON = 'badminton',
  BILLIARDS = 'billiards',
  BOULDERING = 'bouldering',
  CYCLING = 'cycling',
  DANCE = 'dance',
  FRISBEE = 'frisbee',
  GYM = 'gym',
  LONGBOARDING = 'longboarding',
  OUTDOOR_GYM = 'outdoor-gym',
  RUNNING = 'running',
  SKATEBOARDING = 'skateboarding',
  SOCCER = 'soccer',
  SPIKEBALL = 'spikeball',
  SQUASH = 'squash',
  SWIMMING = 'swimming',
  TABLE_TENNIS = 'table-tennis',
  TENNIS = 'tennis',
  VOLLEYBALL = 'volleyball',
  FLUNKYBALL = 'flunkyball',
  YOGA = 'yoga',
}

// Location can not be used as the name because it already used by the standard library
export type LocationType = {
  description: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
};

export type Participant = {
  id: string;
  firstName: string;
  profilePictureUrl?: string;
};

export type Organizer = {
  id: string;
  firstName: string;
  profilePictureUrl?: string;
};

export type BaseActivity = {
  id: string;
  sport: SportType;
  slots?: number;
  participants: Participant[];
  organizers: Organizer[];
  startsAt: Date;
  endsAt?: Date;
  startTime: Date;
  frequency: Frequency;
  durationInMinutes: number;
  firstDate: Date;
  lastDate?: Date;
  location: {
    description: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
  commentsCount: number;
  title?: string;
  coverPictureUrl?: string;
  logoPictureUrl?: string;
  link?: string;
  withJoinFeature: boolean;
};

export enum Frequency {
  ONCE = 'once',
  WEEKLY = 'weekly',
}
