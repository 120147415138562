import { UseBaseQueryOptions } from 'react-query';

import { Frequency } from '@/types';

import { RequestData } from '../hooks/requests/types';
import { useApiDeleteRequest } from '../hooks/useApiDeleteRequest';
import { useApiGetRequest } from '../hooks/useApiGetRequest';
import { useApiPatchRequest } from '../hooks/useApiPatchRequest';
import { useApiPostRequest } from '../hooks/useApiPostRequest';

const POST_ACTIVITY_PATH = '/activities';

export type PostActivityPathVariables = undefined;

export type PostActivityQueryParams = undefined;

export type PostActivityBodyData = {
  type: string;
  startsAt: string;
  endsAt?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  locationDescription: string;
  slots?: number;
  description: string;
  skillLevel?: string;
  price: number;
  frequency: Frequency;
  repeatUntil?: string;
};

type PostActivitySuccessResponse = {
  id: string;
  type: string;
  startsAt: string;
  endsAt?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  locationDescription: string;
  slots?: number;
  description: string;
  skillLevel?: string;
  price: number;
};
type PostActivityErrorResponse = Error;

export const usePostActivity = () => {
  return useApiPostRequest<
    PostActivityPathVariables,
    PostActivityQueryParams,
    PostActivityBodyData,
    PostActivitySuccessResponse,
    PostActivityErrorResponse
  >(POST_ACTIVITY_PATH);
};

const GET_ACTIVITIES_PATH = '/activities';

export type GetActivitiesPathVariables = undefined;

export type GetActivitiesQueryParams = {
  types?: string[];
};

export type GetActivitiesSuccessResponse = {
  data: {
    id: string;
    type: string;
    startsAt: string;
    endsAt?: string;
    startTime: string;
    frequency: Frequency;
    durationInMinutes: number;
    firstDate: string;
    lastDate?: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
    createdBy: string;
    participants: {
      id: string;
      firstName?: string;
      profilePictureUrl?: string;
    }[];
    organizers: {
      id: string;
      firstName?: string;
      profilePictureUrl?: string;
    }[];
    locationDescription: string;
    slots?: number;
    commentsCount: number;
    coverPictureUrl?: string;
    logoPictureUrl?: string;
    title?: string;
  }[];
};

export const useGetActivities = (
  options?: UseBaseQueryOptions<GetActivitiesSuccessResponse, Error>,
  params?: RequestData<GetActivitiesPathVariables, GetActivitiesQueryParams, undefined>,
) => {
  return useApiGetRequest<GetActivitiesPathVariables, GetActivitiesQueryParams, GetActivitiesSuccessResponse, Error>(
    ['get-activities'],
    GET_ACTIVITIES_PATH,
    params,
    options,
  );
};

const GET_ACTIVITIES_COUNT_PATH = '/activities/count';

export type GetActivitiesCountPathVariables = undefined;

export type GetActivitiesCountQueryParams = {
  types?: string[];
};

export type GetActivitiesCountSuccessResponse = number;

export const useGetActivitiesCount = (
  options?: UseBaseQueryOptions<GetActivitiesCountSuccessResponse, Error>,
  params?: RequestData<GetActivitiesCountPathVariables, GetActivitiesCountQueryParams, undefined>,
) => {
  return useApiGetRequest<
    GetActivitiesCountPathVariables,
    GetActivitiesCountQueryParams,
    GetActivitiesCountSuccessResponse,
    Error
  >(['get-activities-count', params], GET_ACTIVITIES_COUNT_PATH, params, options);
};

const GET_ACTIVITY_BY_ID_PATH = '/activities/{id}';

export type GetActivityByIdPathVariables = {
  id: string;
};

export type GetActivityByIdQueryParams = undefined;

export type GetActivityByIdSuccessResponse = {
  id: string;
  type: string;
  startsAt: string;
  endsAt?: string;
  startTime: string;
  frequency: Frequency;
  durationInMinutes: number;
  firstDate: string;
  lastDate?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  createdBy: string;
  participants: {
    id: string;
    firstName?: string;
    profilePictureUrl?: string;
  }[];
  organizers: {
    id: string;
    firstName?: string;
    profilePictureUrl?: string;
  }[];
  locationDescription: string;
  slots?: number;
  description: string;
  skillLevel?: string;
  price: number;
  commentsCount: number;
  coverPictureUrl?: string;
  logoPictureUrl?: string;
  title?: string;
  link?: string;
};

// NOTE: Probably add 404 error

export const useGetActivityById = (
  params: RequestData<GetActivityByIdPathVariables, GetActivityByIdQueryParams, undefined>,
  options?: UseBaseQueryOptions<GetActivityByIdSuccessResponse, Error>,
) => {
  return useApiGetRequest<
    GetActivityByIdPathVariables,
    GetActivityByIdQueryParams,
    GetActivityByIdSuccessResponse,
    Error
  >(['get-activity-by-id', params?.pathVariables], GET_ACTIVITY_BY_ID_PATH, params, options);
};

const GET_MY_ACTIVITIES_PATH = '/my-activities';

export type GetMyActivitiesPathVariables = undefined;

export type GetMyActivitiesQueryParams = {
  before?: string;
  after?: string;
};

export type GetMyActivitiesSuccessResponse = {
  data: {
    id: string;
    type: string;
    startsAt: string;
    endsAt?: string;
    startTime: string;
    frequency: Frequency;
    durationInMinutes: number;
    firstDate: string;
    lastDate?: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
    createdBy: string;
    participants: {
      id: string;
      firstName?: string;
      profilePictureUrl?: string;
    }[];
    locationDescription: string;
    slots?: number;
    commentsCount: number;
    coverPictureUrl?: string;
    logoPictureUrl?: string;
    title?: string;
  }[];
};

export const useGetMyActivities = (
  params: RequestData<GetMyActivitiesPathVariables, GetMyActivitiesQueryParams, undefined>,
  options?: UseBaseQueryOptions<GetMyActivitiesSuccessResponse, Error>,
) => {
  return useApiGetRequest<
    GetMyActivitiesPathVariables,
    GetMyActivitiesQueryParams,
    GetMyActivitiesSuccessResponse,
    Error
  >(['get-my-activities'], GET_MY_ACTIVITIES_PATH, params, options);
};

const PATCH_ACTIVITIES_JOIN_PATH = '/activities/{id}/join';

export type PatchActivitiesJoinPathVariables = {
  id: string;
};

export type PatchActivitiesJoinSuccessResponse = GetActivityByIdSuccessResponse;

export const usePatchActivityJoin = () => {
  return useApiPatchRequest<
    PatchActivitiesJoinPathVariables,
    undefined,
    undefined,
    PatchActivitiesJoinSuccessResponse,
    Error
  >(PATCH_ACTIVITIES_JOIN_PATH);
};

const PATCH_ACTIVITIES_LEAVE_PATH = '/activities/{id}/leave';

export type PatchActivitiesLeavePathVariables = {
  id: string;
};

export type PatchActivitiesLeaveSuccessResponse = GetActivityByIdSuccessResponse;

export const usePatchActivityLeave = () => {
  return useApiPatchRequest<
    PatchActivitiesLeavePathVariables,
    undefined,
    undefined,
    PatchActivitiesLeaveSuccessResponse,
    Error
  >(PATCH_ACTIVITIES_LEAVE_PATH);
};

const DELETE_ACTIVITIES_PATH = '/activities/{id}';

export type DeleteActivitiesPathVariables = {
  id: string;
};

export type DeleteActivitiesSuccessResponse = undefined;

export const useDeleteActivity = () => {
  return useApiDeleteRequest<DeleteActivitiesPathVariables, undefined, DeleteActivitiesSuccessResponse, Error>(
    DELETE_ACTIVITIES_PATH,
  );
};

const GET_ACTIVITIES_COMMENTS_BY_ID_PATH = '/activities/{activityId}/comments';

export type GetActivitiesCommentsByIdPathVariables = {
  activityId: string;
};

export type GetActivitiesCommentsByIdQueryParams = undefined;

export type GetActivitiesCommentsByIdSuccessResponse = {
  data: {
    id: string;
    content: string;
    createdAt: string;
    author: {
      id: string;
      firstName?: string;
      profilePictureUrl?: string;
    };
  }[];
};

export const useGetActivityCommentsById = (
  params: RequestData<GetActivitiesCommentsByIdPathVariables, GetActivitiesCommentsByIdQueryParams, undefined>,
  options?: UseBaseQueryOptions<GetActivitiesCommentsByIdSuccessResponse, Error>,
) => {
  return useApiGetRequest<
    GetActivitiesCommentsByIdPathVariables,
    GetActivitiesCommentsByIdQueryParams,
    GetActivitiesCommentsByIdSuccessResponse,
    Error
  >(['get-activity-comments-by-id', params?.pathVariables], GET_ACTIVITIES_COMMENTS_BY_ID_PATH, params, options);
};

export const POST_ACTIVITIES_COMMENTS_BY_ID_PATH = '/activities/{activityId}/comments';

export type PostActivitiesCommentsByIdPathVariables = {
  activityId: string;
};

export type PostActivitiesCommentsByIdQueryParams = undefined;

export type PostActivitiesCommentsByIdBodyData = {
  content: string;
};

export type PostActivitiesCommentsByIdSuccessResponse = {
  id: string;
  content: string;
  createdAt: string;
  author: {
    id: string;
    firstName?: string;
    profilePictureUrl?: string;
  };
};

export const usePostActivityCommentsById = () => {
  return useApiPostRequest<
    PostActivitiesCommentsByIdPathVariables,
    PostActivitiesCommentsByIdQueryParams,
    PostActivitiesCommentsByIdBodyData,
    PostActivitiesCommentsByIdSuccessResponse,
    Error
  >(POST_ACTIVITIES_COMMENTS_BY_ID_PATH);
};
