import { ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

type DateInfoBoxProps = {
  isActive: boolean;
  onClick: () => void;
  className?: string;
};

export const DateInfoBox: React.FC<PropsWithChildren<DateInfoBoxProps>> = ({
  isActive,
  children,
  onClick,
  className,
}) => {
  return (
    <div
      className={cx(
        'relative',
        'flex',
        'flex-row',
        'text-gray-700',
        'rounded',
        'items-center',
        'w-full',
        'justify-center',
        'py-2',
        isActive ? 'bg-indigo-100' : 'bg-gray-200',
        className,
      )}
      onClick={onClick}
    >
      <span className='text-base'>{children}</span>
      <ChevronDownIcon
        className={cx('absolute', 'transition', 'duration-200', 'right-1.5', isActive && 'rotate-180')}
        width={18}
        height={18}
      />
    </div>
  );
};
