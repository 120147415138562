import { useMutation } from 'react-query';

import { deleteRequest } from './requests/delete';
import { RequestData } from './requests/types';

export const useApiDeleteRequest = <PathVariables, QueryParams, SuccessResponse, ErrorResponse = Error>(
  path: string,
) => {
  const controller = new AbortController();
  const { signal } = controller;

  const mutation = useMutation<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, undefined>>(
    (params) => deleteRequest<SuccessResponse>(path, params?.pathVariables, params?.queryParams, signal),
  );

  const abort = () => {
    controller.abort();
    mutation.reset();
  };

  return {
    abort,
    ...mutation,
  };
};
