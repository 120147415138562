import { Button, TextInput } from '@component-library';
import { getAuth, sendPasswordResetEmail } from '@firebase/auth';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { Logger } from '@/lib/logs/logger';
import { isErrorWithCodeProperty } from '@/lib/type-guards/isErrorWithCodeProperty';
import { validateEmail } from '@/lib/validators/validateEmail';
import { paths } from '@/routing/paths';

type FormData = {
  email: string;
};

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { register, formState, handleSubmit } = useForm<FormData>();
  const [loading, setLoading] = useState(false);

  const handleSendEmail = (data: FormData) => {
    setLoading(true);
    sendPasswordResetEmail(getAuth(), data.email, { url: `${window.location.origin}${paths.login}` })
      .then(() => {
        toast.success(t('resetPassword.emailSent'));
      })
      .catch((e) => {
        if (isErrorWithCodeProperty(e) && e.code !== 'auth/user-not-found') {
          Logger.error(e.message);
        }
        toast.error(t('globals.errors.unexpected'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleSendEmail)} className='flex flex-col gap-6 max-w-lg w-full'>
      <div className='text-gray-600 flex flex-row items-center gap-2 mb-4'>
        <CustomLink to={{ pathname: paths.login }} state={state}>
          <ChevronLeftIcon width={20} height={20} />
        </CustomLink>
        <span className='text-2xl text-center leading-4'>{t('resetPassword.title')}</span>
      </div>
      <TextInput
        {...register('email', {
          required: t('globals.validation.required'),
          validate: (value) => validateEmail(value) || t('globals.validation.email'),
        })}
        error={formState.errors.email?.message}
        label={`${t('resetPassword.labels.email')} *`}
        placeholder='maxmustermann@gmail.com'
        type='email'
      />
      <Button type='submit' className='self-center px-12 mt-2' variant='outlined' isLoading={loading}>
        {t('resetPassword.labels.submit')}
      </Button>
    </form>
  );
};
