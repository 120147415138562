import { useApiPostRequest } from '../hooks/useApiPostRequest';

export type SocialCallbackBodyData = {
  idToken: string;
  provider: string;
  user: {
    authProviderId: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    profilePictureUrl?: string;
    emailVerified: boolean;
  };
};

export type SocialCallbackSuccessResponse = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  profilePictureUrl?: string;
  privacyPolicyAcceptedAt?: string;
  emailVerifiedAt?: string;
};

export const useSocialSignInCallback = () => {
  return useApiPostRequest<undefined, undefined, SocialCallbackBodyData, SocialCallbackSuccessResponse, Error>(
    '/auth/social-callback',
  );
};
