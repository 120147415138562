import React from 'react';
import { Outlet } from 'react-router-dom';

import { AddActivityFormProvider } from './context/form-provider';

export const AddActivityPage: React.FC = () => {
  return (
    <AddActivityFormProvider>
      <Outlet />
    </AddActivityFormProvider>
  );
};
