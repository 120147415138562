import { t } from 'i18next';

export const getGermanWeekday = (date: Date): string => {
  switch (date.getDay()) {
    case 0:
      return t('globals.date.weekdays.sunday');
    case 1:
      return t('globals.date.weekdays.monday');
    case 2:
      return t('globals.date.weekdays.tuesday');
    case 3:
      return t('globals.date.weekdays.wednesday');
    case 4:
      return t('globals.date.weekdays.thursday');
    case 5:
      return t('globals.date.weekdays.friday');
    case 6:
      return t('globals.date.weekdays.saturday');
    default:
      throw new Error('Invalid weekday');
  }
};

export const transformDateToWeekdayAndDate = (date: Date): string => {
  let day = date.getDate().toString();
  day = day.length === 1 ? `0${day}` : day;
  let month = (date.getMonth() + 1).toString();
  month = month.length === 1 ? `0${month}` : month;

  return `${getGermanWeekday(date)}, ${day}.${month}.${date.getFullYear()}`;
};
