import { ReactComponent as LogoSvg } from '@assets/logo.svg';
import React from 'react';

import { exhaustiveCheck } from '@/lib/type-guards/exhaustive-check';

type LogoProps = {
  variant: 'small' | 'medium' | 'large';
  onClick?: () => void;
  color?: 'brand' | 'gray';
};

export const Logo: React.FC<LogoProps> = ({ variant, onClick, color = 'brand' }) => {
  const size = (() => {
    switch (variant) {
      case 'small':
        return {
          width: 80,
          height: 34,
        };
      case 'medium':
        return {
          width: 100,
          height: 50,
        };
      case 'large':
        return {
          width: 150,
          height: 65,
        };
      default:
        return exhaustiveCheck(variant);
    }
  })();

  const colorClass = (() => {
    switch (color) {
      case 'brand':
        return 'text-logo';
      case 'gray':
        return 'text-gray-900';
      default:
        return exhaustiveCheck(color);
    }
  })();

  return (
    <div className={colorClass} onClick={onClick}>
      <LogoSvg {...size} />
    </div>
  );
};
