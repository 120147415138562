import React from 'react';
import { twMerge } from 'tailwind-merge';

type DividerProps = {
  className?: string;
};

export const Divider: React.FC<DividerProps> = ({ className }) => {
  return <div className={twMerge('border-t border-gray-600 w-full', className)} />;
};
