import { Divider } from '@component-library';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Comments } from '@/components/comments/comments';
import { CustomLink } from '@/components/custom-link/custom-link';
import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { paths } from '@/routing/paths';

export const ActivityCommentsPage: React.FC = () => {
  const { activityId } = useParams();
  const { t } = useTranslation();

  if (!activityId) {
    // NOTE: It could make more sense to throw an error when we have error boundaries in place
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }

  return (
    <div className='w-full p-4'>
      <div className='flex flex-row gap-3 items-center text-gray-600 my-2'>
        <CustomLink to={{ pathname: paths.activity(activityId).base }}>
          <ChevronLeftIcon width={22} height={22} />
        </CustomLink>
        <span className='text-2xl'>{t('globals.comments.title')}</span>
      </div>
      <Divider className='border-gray-400' />
      <div className='py-5'>
        <Comments activityId={activityId} />
      </div>
    </div>
  );
};
