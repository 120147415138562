import { useEffect } from 'react';
import { UseBaseQueryOptions } from 'react-query';

import { RequestData } from '@/api/hooks/requests/types';
import {
  GetMyActivitiesPathVariables,
  GetMyActivitiesQueryParams,
  GetMyActivitiesSuccessResponse,
  useGetMyActivities,
} from '@/api/resources/activity';
import { Logger } from '@/lib/logs/logger';
import { transformActivitiesResponse } from '@/lib/transformer/transformActivitiesResponse';

import { Organizer } from '../../../../../types';

export const useMyActivities = (
  params: RequestData<GetMyActivitiesPathVariables, GetMyActivitiesQueryParams, undefined>,
  options?: UseBaseQueryOptions<GetMyActivitiesSuccessResponse, Error>,
) => {
  const { data, status, ...rest } = useGetMyActivities(params, options);

  useEffect(() => {
    if (status === 'error') {
      Logger.errorOnce(`useMyActivities failed: ${rest.error?.message}`);
    }
  }, [status]);

  if (status !== 'success') {
    return { data: undefined, status, ...rest };
  }

  // NOTE: This is a temporary solution until we have the organizers in the response from the API
  // NOTE: Implementing it this way allows to reuse the transformActivitiesResponse function
  const organizers: Organizer[] = [];

  return {
    data: transformActivitiesResponse(
      data.data.map((myActivity) => ({ ...myActivity, organizers })),
    ).map((myActivity) => ({ ...myActivity, organizers: undefined })),

    status,
    ...rest,
  };
};
