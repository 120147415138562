import { Icon } from '@component-library';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type DrawerProps = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  IconProps?: {
    className?: string;
  };
};

export const Drawer: React.FC<PropsWithChildren<DrawerProps>> = ({
  children,
  open,
  handleOpen,
  handleClose,
  IconProps,
}) => {
  const toggleOpen = () => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const { className } = IconProps || {};

  return (
    <>
      <button
        className={twMerge('absolute bottom-0 top-0 right-0 m-auto h-fit w-fit p-4 text-gray-600', className)}
        onClick={() => {
          toggleOpen();
        }}
      >
        <Icon width={36} height={36} iconKey='menu' />
      </button>
      <button
        className={twMerge(
          classNames(
            'fixed top-5 right-0 p-4 text-gray-600 cursor-pointer ease-in-out transition-all duration-300 z-50 h-fit bg-white m-auto rounded-l-full',
            { 'right-64': open, '-z-50': !open },
          ),
          className,
        )}
        onClick={() => {
          toggleOpen();
        }}
      >
        <Icon
          width={36}
          height={36}
          iconKey='x'
          className={classNames({ 'text-gray-600': open, 'text-transparent': !open })}
        />
      </button>
      {open && (
        <div
          className='fixed bg-gray-300/50 w-screen h-screen left-0 top-0 z-20'
          onClick={() => {
            handleClose();
          }}
        />
      )}
      <div
        className={`fixed w-64 bg-white bottom-0 -right-64 top-0  ease-in-out transition-all duration-300 flex flex-col z-20  ${
          open ? '-translate-x-full drop-shadow-right' : 'translate-x-0'
        }`}
      >
        {children}
      </div>
    </>
  );
};
