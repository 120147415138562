import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Loader } from '..';

type ButtonProps = { isLoading?: boolean; variant?: 'outlined' | 'text' | 'contained' } & React.ButtonHTMLAttributes<
  HTMLButtonElement
>;

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = 'outlined',
  isLoading = false,
  disabled,
  ...props
}) => {
  const textClassNames = [
    'bg-transparent',
    'text-indigo-500',
    !disabled && 'hover:bg-indigo-50',
    !disabled && 'hover:text-indigo-800',
  ];
  const outlinedClassNames = [
    'bg-white',
    'text-indigo-500',
    'border',
    'border-gray-500',
    !disabled && 'hover:border-indigo-800',
    !disabled && 'hover:bg-indigo-50',
    !disabled && 'hover:text-indigo-800',
  ];
  const containedClassNames = [
    'border',
    'border-indigo-500',
    'bg-indigo-500',
    'text-white',
    (disabled || isLoading) && 'bg-indigo-300',
    !disabled && 'hover:bg-indigo-600',
    !disabled && 'hover:text-white',
  ];

  const variantClassNames = (() => {
    if (variant === 'outlined') return outlinedClassNames;

    if (variant === 'contained') return containedClassNames;

    if (variant === 'text') return textClassNames;

    return [];
  })();

  return (
    <button
      disabled={isLoading || disabled}
      className={twMerge(
        'flex',
        'flex-row',
        'justify-center',
        'items-center',
        'gap-3',
        'text-base',
        'py-1.5',
        'px-5',
        'rounded-lg',
        'text-center',
        'font-normal',
        !disabled && 'hover:shadow-lg',
        !disabled && 'hover:border-transparent',
        variantClassNames,
        isLoading && 'disabled',
        className,
      )}
      {...props}
    >
      {isLoading && <Loader size={16} className='border-gray-300' />}
      {children}
    </button>
  );
};
