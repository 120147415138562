import { Divider } from '@component-library';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { PropsWithChildren } from 'react';
import { Path } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';

type FullWidthLayoutProps = {
  title: string;
  backTo: Partial<Path>;
};

export const FullWidthLayout: React.FC<PropsWithChildren<FullWidthLayoutProps>> = ({ children, title, backTo }) => {
  return (
    <div className='pt-1 flex flex-col w-[calc(100%+16px)] -ml-2'>
      <div className='text-gray-600 flex flex-row items-center gap-2 mb-4 px-2'>
        <CustomLink to={backTo}>
          <ChevronLeftIcon width={24} height={24} />
        </CustomLink>
        <span className='text-2xl text-center leading-4'>{title}</span>
      </div>
      <Divider />
      <div className='px-4'>{children}</div>
    </div>
  );
};
