const addActivityPaths = {
  base: '/add-activity',
  stepOne: '/add-activity/step-one',
  stepTwo: '/add-activity/step-two',
  stepThree: '/add-activity/step-three',
  stepFour: '/add-activity/step-four',
};

const filterPaths = {
  location: '/filter/location',
  types: '/filter/types',
};

export const paths = {
  addActivity: addActivityPaths,
  auth: '/auth',
  details: (id: string) => ({
    base: `/details/${id}`,
    comments: `/details/${id}/comments`,
    loginAndJoin: `/details/${id}/login-and-join`,
  }),
  activity: (id: string) => ({
    base: `/activity/${id}`,
    comments: `/activity/${id}/comments`,
  }),
  filter: filterPaths,
  home: '/',
  login: '/login',
  myActivities: {
    base: '/my-activities',
    details: (id: string) => `/my-activities/${id}`,
  },
  resetPassword: '/reset-password',
  signUp: '/sign-up',
  myProfile: '/my-profile',
  terms: '/terms',
  privacy: '/privacy',
  about: '/about',
  imprint: '/imprint',
};
