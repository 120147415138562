import { Button } from '@component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/lib/auth';

import { DeleteActivityModal } from './modals/delete-activity-modal';
import { LeaveActivityModal } from './modals/leave-activity-modal';

type DeleteLeaveActivityButtonProps = {
  activityId: string;
  createdBy: string;
  onSuccess?: () => void;
};

export const DeleteLeaveActivityButton: React.FC<DeleteLeaveActivityButtonProps> = ({
  activityId,
  createdBy,
  onSuccess,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.activity' });

  const { state: signedInState, user } = useAuth();
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  if (signedInState !== 'SIGNED_IN') {
    throw new Error('This component should only be rendered when the user is signed in');
  }

  const isOwner = user.id === createdBy;

  return (
    <>
      <LeaveActivityModal
        activityId={activityId}
        open={isLeaveModalOpen}
        handleClose={() => setIsLeaveModalOpen(false)}
        onSuccess={onSuccess}
      />
      <DeleteActivityModal
        activityId={activityId}
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        onSuccess={onSuccess}
      />
      <Button
        variant='text'
        className='text-red-700'
        onClick={() => {
          if (isOwner) {
            setIsDeleteModalOpen(true);
          } else {
            setIsLeaveModalOpen(true);
          }
        }}
      >
        {isOwner ? t('delete.label') : t('leave.label')}
      </Button>
    </>
  );
};
