import { Button } from '@component-library';
import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary as ExternalErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

const ErrorBoundaryFallback: FC<{ resetErrorBoundary: () => void }> = ({ resetErrorBoundary }) => {
  const [t] = useTranslation();

  return (
    <div
      role='alert'
      className='absolute top-0 bottom-0 left-0 right-0 flex flex-col justify-center items-center text-center gap-2'
    >
      <h1 className='text-2xl'>{t('globals.errorBoundary.title')}</h1>
      <h2 className='text-xl'>{t('globals.errorBoundary.subtitle')}</h2>
      <p dangerouslySetInnerHTML={{ __html: t('globals.errorBoundary.description') }} />
      <Button variant='contained' className='mt-4' onClick={resetErrorBoundary}>
        {t('globals.errorBoundary.tryAgain')}
      </Button>
    </div>
  );
};

export const ErrorBoundary: FC<PropsWithChildren> = ({ children }) => (
  <ExternalErrorBoundary fallback={<ErrorBoundaryFallback resetErrorBoundary={() => window.location.reload()} />}>
    {children}
  </ExternalErrorBoundary>
);
