import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updateProfile,
} from '@firebase/auth';
import { validateSync } from 'class-validator';
import { usePostHog } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

import { SocialCallbackSuccessResponse, useSocialSignInCallback } from '@/api/resources/authentication';
import { User } from '@/models/user';
import { paths } from '@/routing/paths';
import { socialSignIn } from '@/utils/auth';
import { firebaseApp } from '@/utils/firebase';

import { tokenStorage } from '../../../config/tokenStorage';
import { Logger } from '../logs/logger';
import { isErrorWithCodeProperty } from '../type-guards/isErrorWithCodeProperty';
import {
  AuthenticationProvider,
  Authenticator,
  BootingAuthenticatorValue,
  SignedOutAuthenticatorValue,
  SignUpInterface,
  SingedInAuthenticatorValue,
} from './AuthenticationContext';
import { AlreadyLinkedToOtherAuthProviderException } from './exceptions/already-linked-to-other-auth-provider.exception';
import { EmailAlreadyInUseException } from './exceptions/email-already-in-use.exception';

type AuthFunctions = Omit<
  Authenticator,
  'accessToken' | 'state' | 'authenticatedUser' | 'setUser' | 'user' | 'updateUser'
>;

const userStorageKey = 'sporty-user';

const firebaseSignUp = async (props: SignUpInterface): Promise<{ idToken: string; authProviderId: string }> => {
  const response = await createUserWithEmailAndPassword(getAuth(), props.email, props.password);

  const { currentUser } = getAuth();

  if (!currentUser) {
    throw new Error('The user should always be signed in after creating a new account.');
  }

  await updateProfile(currentUser, { displayName: props.firstName });

  await sendEmailVerification(currentUser, {
    url: `${window.location.origin}${paths.home}`,
  });

  const idToken = await response.user.getIdToken();
  return {
    idToken,
    authProviderId: currentUser.uid,
  };
};

export const SportyAuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isBooting, setIsBooting] = useState(true);
  const [signedInInfos, setSingedInInfos] = useState<{ user: User; idToken: string } | undefined>();
  const { mutateAsync: socialAuthHandshakeWithSportyBackend } = useSocialSignInCallback();
  const posthog = usePostHog();
  const receiver = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get('t');

    if (token) {
      const auth = getAuth(firebaseApp);
      signInWithCustomToken(auth, token).then(async (credential) => {
        const firebaseUser = {
          authProviderId: credential.user.uid,
          email: credential.user.email || undefined,
          emailVerified: credential.user.emailVerified,
        };

        const idToken = await credential.user.getIdToken();

        const userResponse = await socialAuthHandshakeWithSportyBackend({
          bodyData: {
            idToken,
            provider: 'firebase',
            user: firebaseUser,
          },
        });

        await handleSignIn(userResponse, idToken);

        searchParams.delete('t');
        window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
      });
    }
  }, []);

  const handleSignIn = async (userResponse: SocialCallbackSuccessResponse, idToken: string) => {
    tokenStorage.setItem(userStorageKey, JSON.stringify(userResponse));
    const user = new User(userResponse);
    const errors = validateSync(user);

    posthog.identify(user.id, user);

    if (errors.length > 0) {
      Logger.errorOnce(`User from backend is not valid: ${errors.toString()}`);
    }

    setSingedInInfos({
      user,
      idToken,
    });
  };

  const authFunctions = useMemo<AuthFunctions>(
    () => ({
      async socialLogin(method) {
        try {
          const authProviderData = await socialSignIn(method);

          if (authProviderData === null) {
            // TODO: It can happen that the authProviderData is null, if something else goes wrong.
            // TODO: This causes the wrong error message.
            throw new AlreadyLinkedToOtherAuthProviderException();
          }

          const userResponse = await socialAuthHandshakeWithSportyBackend({ bodyData: authProviderData });
          await handleSignIn(userResponse, authProviderData.accessToken);
        } catch (e) {
          if (!(e instanceof AlreadyLinkedToOtherAuthProviderException) && e instanceof Error) {
            Logger.errorOnce(`socialLogin: "${e.message}"`);
          }

          throw e;
        }
      },
      async emailPasswordLogin(email: string, password: string): Promise<void> {
        const response = await signInWithEmailAndPassword(getAuth(), email, password);

        const firebaseUser = {
          authProviderId: response.user.uid,
          email,
          emailVerified: response.user.emailVerified,
        };

        const idToken = await response.user.getIdToken();

        const userResponse = await socialAuthHandshakeWithSportyBackend({
          bodyData: {
            idToken,
            provider: 'firebase',
            user: firebaseUser,
          },
        });

        await handleSignIn(userResponse, idToken);
      },
      async emailPasswordSignUp(props: SignUpInterface): Promise<void> {
        try {
          const { authProviderId, idToken } = await firebaseSignUp(props);

          const userResponse = await socialAuthHandshakeWithSportyBackend({
            bodyData: {
              idToken,
              provider: 'firebase',
              user: {
                authProviderId,
                email: props.email,
                emailVerified: false,
                firstName: props.firstName,
              },
            },
          });

          await handleSignIn(userResponse, idToken);
        } catch (e) {
          if (!isErrorWithCodeProperty(e) || e.code !== 'auth/email-already-in-use') {
            throw e;
          }

          throw new EmailAlreadyInUseException();
        }
      },
      async signOut(): Promise<void> {
        const auth = getAuth(firebaseApp);
        await auth.signOut();
        setSingedInInfos(undefined);
      },
    }),
    [],
  );

  const updateUser = (user: User): void => {
    if (signedInInfos === undefined) {
      throw new Error('User is not signed in');
    }

    tokenStorage.setItem(userStorageKey, JSON.stringify(user));
    setSingedInInfos({
      ...signedInInfos,
      user,
    });
  };

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    auth.setPersistence(browserLocalPersistence).then(() => {
      if (!auth.currentUser) {
        setIsBooting(false);
        return;
      }

      auth.currentUser.getIdToken().then(async (token) => {
        try {
          const stringifiedUser = tokenStorage.getItem(userStorageKey);
          if (!stringifiedUser) {
            throw new Error('No user found in storage');
          }

          const parsedUser = JSON.parse(stringifiedUser);
          const user = new User(parsedUser);

          const errors = validateSync(user);

          if (errors.length > 0) {
            const message = `User from storage is not valid: ${errors.toString()}`;
            Logger.errorOnce(message);
            throw new Error(message);
          }

          setSingedInInfos({
            user,
            idToken: token,
          });
        } catch (e) {
          authFunctions.signOut();
        }
        setIsBooting(false);
      });
    });
  }, []);

  const value = ((): SingedInAuthenticatorValue | SignedOutAuthenticatorValue | BootingAuthenticatorValue => {
    if (signedInInfos) {
      return {
        idToken: signedInInfos.idToken,
        user: signedInInfos.user,
        state: 'SIGNED_IN',
      };
    }

    if (isBooting) {
      return {
        state: 'BOOTING',
        user: undefined,
      };
    }

    return {
      state: 'SIGNED_OUT',
      user: undefined,
    };
  })();

  return (
    <AuthenticationProvider
      value={{
        ...authFunctions,
        updateUser,
        ...value,
      }}
    >
      <iframe ref={receiver} src='https://sporty-demo.netlify.app/receive.html' height={0} width={0} />
      {children}
    </AuthenticationProvider>
  );
};
