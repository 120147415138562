import { Button, Icon, IconTextButton, Modal, ModalContent } from '@component-library';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomLink } from '@/components/custom-link/custom-link';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { paths } from '@/routing/paths';

import { ReactComponent as IconFacebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as IconGoogle } from '../../assets/icons/google.svg';

type WebviewAuthProps = {
  joinActivityId?: string;
};

// NOTE: social auth is not supported in webview. So this component is used to display a message to the user
export const WebviewAuth: React.FC<WebviewAuthProps> = ({ joinActivityId }) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const [isUnsupportedBrowserModalOpen, setIsUnsupportedBrowserModalOpen] = useState(false);

  const handleOnGoogleClick = async () => {
    track.event({
      name: AnalyticsEvents.SOCIAL_AUTH,
      params: { provider: 'google', details: 'opened_in_webview' },
    });
    setIsUnsupportedBrowserModalOpen(true);
  };

  const handleOnFacebookClick = async () => {
    track.event({
      name: AnalyticsEvents.SOCIAL_AUTH,
      params: { provider: 'facebook', details: 'opened_in_webview' },
    });
    setIsUnsupportedBrowserModalOpen(true);
  };

  const closeUnsupportedBrowserModal = () => {
    setIsUnsupportedBrowserModalOpen(false);
  };

  return (
    <>
      <HintModal open={isUnsupportedBrowserModalOpen} onClose={closeUnsupportedBrowserModal} />
      <div className='flex flex-col gap-4'>
        <CustomLink
          to={{ pathname: paths.login, search: joinActivityId ? `?join-activity-id=${joinActivityId}` : undefined }}
          state={{
            previousPath: paths.auth,
          }}
        >
          <IconTextButton icon={<Icon iconKey='email-and-at' />}>{t('home.auth.email')}</IconTextButton>
        </CustomLink>
        <div className='flex flex-col gap-4 border-2 p-2 rounded-lg border-gray-400'>
          <div className='text-sm'>{t('home.auth.webview.message')}</div>
          <IconTextButton icon={<IconGoogle />} onClick={handleOnGoogleClick}>
            {t('home.auth.social.google')}
          </IconTextButton>
          <IconTextButton icon={<IconFacebook />} onClick={handleOnFacebookClick}>
            {t('home.auth.social.facebook')}
          </IconTextButton>
        </div>
      </div>
    </>
  );
};

const HintModal: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={onClose}>
      <ModalContent>
        <div className='text-center text-gray-900'>{t('home.auth.webview.message')}</div>
        <Button className='flex-grow md:flex-grow-0 text-gray-800 m-auto mt-4' variant='outlined' onClick={onClose}>
          {t('home.auth.webview.buttons.close')}
        </Button>
      </ModalContent>
    </Modal>
  );
};
