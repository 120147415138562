import badmintonSrc from '@assets/images/sports/badminton.png';
import volleyballSrc from '@assets/images/sports/beachvolleyball.png';
import billiardsSrc from '@assets/images/sports/billiards.png';
import boulderingSrc from '@assets/images/sports/bouldern.png';
import cyclingSrc from '@assets/images/sports/cycling.png';
import danceSrc from '@assets/images/sports/dance.png';
import outdoorGymSrc from '@assets/images/sports/fitness.png';
import flunkyballSrc from '@assets/images/sports/flunkyball.png';
import frisbeeSrc from '@assets/images/sports/frisbee.png';
import gymSrc from '@assets/images/sports/gym.png';
import longboardingSrc from '@assets/images/sports/longboard.png';
import runningSrc from '@assets/images/sports/running.png';
import skateboardingSrc from '@assets/images/sports/skateboard.png';
import soccerSrc from '@assets/images/sports/soccer.png';
import spikeballSrc from '@assets/images/sports/spikeball.png';
import squashSrc from '@assets/images/sports/squash.png';
import swimSrc from '@assets/images/sports/swim.png';
import tableTennisSrc from '@assets/images/sports/table-tennis.png';
import tennisSrc from '@assets/images/sports/tennis.png';
import yogaSrc from '@assets/images/sports/yoga.png';

import { exhaustiveCheck } from '@/lib/type-guards/exhaustive-check';
import { SportType } from '@/types';

export const getImageSrcBySportType = (sport: SportType) => {
  switch (sport) {
    case SportType.BADMINTON:
      return badmintonSrc;
    case SportType.BILLIARDS:
      return billiardsSrc;
    case SportType.BOULDERING:
      return boulderingSrc;
    case SportType.CYCLING:
      return cyclingSrc;
    case SportType.DANCE:
      return danceSrc;
    case SportType.LONGBOARDING:
      return longboardingSrc;
    case SportType.SOCCER:
      return soccerSrc;
    case SportType.FRISBEE:
      return frisbeeSrc;
    case SportType.FLUNKYBALL:
      return flunkyballSrc;
    case SportType.GYM:
      return gymSrc;
    case SportType.OUTDOOR_GYM:
      return outdoorGymSrc;
    case SportType.RUNNING:
      return runningSrc;
    case SportType.TENNIS:
      return tennisSrc;
    case SportType.VOLLEYBALL:
      return volleyballSrc;
    case SportType.YOGA:
      return yogaSrc;
    case SportType.SKATEBOARDING:
      return skateboardingSrc;
    case SportType.SWIMMING:
      return swimSrc;
    case SportType.SPIKEBALL:
      return spikeballSrc;
    case SportType.SQUASH:
      return squashSrc;
    case SportType.TABLE_TENNIS:
      return tableTennisSrc;
    default:
      return exhaustiveCheck(sport);
  }
};
