import { Button, TextInput } from '@component-library';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useJoinActivitiyAfterAuth } from '@/hooks/useJoinActivitiyAfterAuth';
import { useAuth } from '@/lib/auth';
import { validateEmail } from '@/lib/validators/validateEmail';
import { paths } from '@/routing/paths';

type FormData = {
  email: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const { state } = useLocation();
  const onSuccessPath = state?.onSuccessPath;
  const navigate = useCustomNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const joinActivityId = searchParams.get('join-activity-id');
  const { handleJoinActivity } = useJoinActivitiyAfterAuth();

  const { emailPasswordLogin } = useAuth();

  const { register, formState, handleSubmit } = useForm<FormData>();

  const [signInError, setSignInError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleLogin = (data: FormData) => {
    setLoading(true);
    setSignInError(undefined);
    emailPasswordLogin(data.email, data.password)
      .then(() => {
        if (joinActivityId) {
          handleJoinActivity(joinActivityId).then(() => {
            if (onSuccessPath) navigate({ pathname: onSuccessPath });
          });
        }
      })
      .catch(() => {
        setSignInError(t('login.error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleLogin)} className='flex flex-col gap-6 max-w-lg w-full'>
        <span className='text-3xl ml-2 mb-2'>{t('login.title')}</span>
        <TextInput
          {...register('email', {
            required: t('globals.validation.required'),
            validate: (value) => validateEmail(value) || t('globals.validation.email'),
          })}
          error={formState.errors.email?.message}
          label={`${t('login.labels.email')} *`}
          placeholder='maxmustermann@gmail.com'
          type='email'
        />
        <div>
          <TextInput
            {...register('password', { required: t('globals.validation.required') })}
            error={formState.errors.password?.message}
            label={`${t('login.labels.password')} *`}
            placeholder='*******'
            type='password'
          />
          <div className='flex flex-row gap-3 text-base mt-2 ml-2'>
            <Trans
              i18nKey='login.forgotPassword'
              components={{
                Link: <CustomLink to={{ pathname: paths.resetPassword }} className='underline' />,
              }}
            />
          </div>
        </div>

        {signInError && <p className='ml-1 text-sm text-red-500'>{signInError}</p>}
        <Button type='submit' className='self-center px-12 mt-5' variant='outlined' isLoading={loading}>
          {t('login.labels.submit')}
        </Button>
      </form>
      <div className='mt-12 font-bold text-base text-center'>
        <Trans
          i18nKey='login.noAccountYet'
          components={{
            Link: (
              <CustomLink
                to={{
                  pathname: paths.signUp,
                  search: joinActivityId ? `?join-activity-id=${joinActivityId}` : undefined,
                }}
                state={state}
                className='underline'
              />
            ),
          }}
        />
      </div>
    </>
  );
};
