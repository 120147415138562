import { Button } from '@component-library';
import React from 'react';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

export const NotFoundPage: React.FC = () => {
  const navigate = useCustomNavigate();

  return (
    <section className='flex items-center h-full p-16 text-gray-800'>
      <div className='container flex flex-col items-center justify-center px-5 mx-auto my-8'>
        <div className='max-w-md text-center'>
          <h2 className='mb-8 font-extrabold text-8xl'>
            <span className='sr-only'>Error</span>404
          </h2>
          <p className='text-2xl font-semibold md:text-3xl'>Entschuldigung, wir konnten diese Seite nicht finden.</p>
          <p className='mt-4 mb-8 '>Aber keine Sorge, auf unserer Startseite findest du noch viel mehr.</p>
          <Button onClick={() => navigate({ pathname: paths.home })}>Zurück zur Startseite</Button>
        </div>
      </div>
    </section>
  );
};
