import { CloseableNavbar } from '@component-library';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';

import { useAddActivity } from '../../context/hooks/use-form-provider';

export type ActiveSteps = 'one' | 'two' | 'three' | 'four';

type AddActivityNavbarProgressIndicatorProps = {
  className?: string;
  activeStep: ActiveSteps;
};

const ActiveIndicator: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <span className='h-6 w-6 bg-indigo-400 text-white rounded-full flex items-center justify-center'>{children}</span>
  );
};

const AddActivityNavbarProgressIndicator: React.FC<AddActivityNavbarProgressIndicatorProps> = ({
  activeStep,
  className,
}) => {
  return (
    <div className={cx('flex flex-row items-center space-x-2', className)}>
      {['one', 'two', 'three', 'four'].map((step, index) => {
        return step === activeStep ? (
          <ActiveIndicator key={step}>{index + 1}</ActiveIndicator>
        ) : (
          <span key={step} className='h-2.5 w-2.5 bg-indigo-400 rounded-full'></span>
        );
      })}
    </div>
  );
};

type AddActivityNavbarProps = {
  activeStep: ActiveSteps;
};

export const AddActivityNavbar: React.FC<AddActivityNavbarProps> = ({ activeStep }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.layout.navbar' });
  const navigate = useCustomNavigate();
  const { previousPath } = useAddActivity();

  const handleClose = () => {
    navigate({ pathname: previousPath });
  };

  return (
    <CloseableNavbar onClose={handleClose}>
      <span className='text-lg text-gray-700'>{t('title')}</span>
      <AddActivityNavbarProgressIndicator
        activeStep={activeStep}
        className='absolute left-1/2 transform -translate-x-1/2'
      />
    </CloseableNavbar>
  );
};
