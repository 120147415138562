import MuiModal from '@mui/base/Modal';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { Icon } from '..';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  withXButton?: boolean;
};

type BackdropProps = {
  open?: boolean;
  className: string;
  // see: https://github.com/mui/material-ui/issues/32882
  ownerState?: Record<string, unknown>;
};

const Backdrop = React.forwardRef<HTMLDivElement, BackdropProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ownerState, className, ...other } = props;
  return (
    <div
      className={twMerge('bg-gray-400 bg-opacity-50 fixed -z-10 inset-0 flex justify-center items-center', className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.displayName = 'Backdrop';

export const ModalContent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className='px-4 py-6'>{children}</div>;
};

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  open,
  children,
  handleClose,
  withXButton = false,
}) => {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      className='fixed flex justify-center items-center inset-0 z-50'
      slots={{ backdrop: Backdrop }}
    >
      <>
        <div className={twMerge('relative rounded-md bg-white shadow-md max-w-screen mx-4', withXButton && 'pt-3')}>
          {withXButton && (
            <div className='absolute top-0 right-0 text-gray-600 p-2 cursor-pointer' onClick={handleClose}>
              <Icon iconKey='x' width={24} height={24} />
            </div>
          )}
          {children}
        </div>
      </>
    </MuiModal>
  );
};
