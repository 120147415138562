import { Loader } from '@component-library';
import { Portal } from '@mui/base';
import React from 'react';

export const LoadingOverlay: React.FC = () => {
  return (
    <Portal>
      <div className='fixed z-10 w-screen h-screen top-0 left-0 flex items-center justify-center'>
        <div className='absolute w-screen h-screen top-0 left-0 bg-gray-300 opacity-30' />
        <Loader size={50} />
      </div>
    </Portal>
  );
};
