import { Icon } from '@component-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useBottomSheet } from '@/components/bottom-sheet/hooks/useBottomSheet';
import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useAuth } from '@/lib/auth';
import { paths } from '@/routing/paths';

import { Auth } from '../../components/auth/auth';

export const IndexAuthPage: React.FC = () => {
  const { t } = useTranslation();
  const { snapTo } = useBottomSheet();
  const navigate = useCustomNavigate();
  const { state } = useAuth();

  useEffect(() => {
    snapTo('max');
  }, [snapTo]);

  const navigateToHome = () => {
    navigate({ pathname: paths.home });
  };

  if (state === 'SIGNED_IN') {
    return <CustomNavigate to={{ pathname: paths.myActivities.base }} />;
  }

  return (
    <div className='my-5 flex flex-col w-full  items-stretch gap-4 m-auto text-center'>
      <div className='absolute right-0 top-0 text-gray-600 p-2 cursor-pointer' onClick={navigateToHome}>
        <Icon iconKey='x' width={30} height={30} />
      </div>
      <span className='text-xl font-thin text-gray-700 mt-1 mb-5'>{t('home.auth.title')}</span>
      <Auth onSuccessPath={paths.home} previousPath={paths.auth} />
    </div>
  );
};
