import React, { PropsWithChildren } from 'react';

import { CustomLink, CustomLinkProps } from '@/components/custom-link/custom-link';

type MapPillLinkProps = {
  LinkProps: CustomLinkProps;
  onClick?: undefined;
};

type MapPillOnClickProps = {
  onClick: () => void;
  LinkProps?: undefined;
};

type MapPillProps = MapPillLinkProps | MapPillOnClickProps;

export const MapPill: React.FC<PropsWithChildren<MapPillProps>> = ({ children, LinkProps, onClick }) => {
  const classNames =
    'bg-gray-50 flex flex-row gap-2 max-w-fit rounded-full px-2 py-1 text-gray-900 items-center shadow-mapPill';

  if (onClick) {
    return (
      <div className={classNames} onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <CustomLink {...LinkProps} className={classNames}>
      {children}
    </CustomLink>
  );
};

export const MapPillIcon: React.FC<PropsWithChildren> = ({ children }) => <>{children}</>;

export const MapPillText: React.FC<PropsWithChildren> = ({ children }) => (
  <span className='text-lg pr-1'>{children}</span>
);
