import { useContext } from 'react';

import { AddActivityFormContext, AddActivityFormContextValue } from '../form-provider';

export const useAddActivity = (): AddActivityFormContextValue => {
  const { data, updateData, previousPath } = useContext(AddActivityFormContext);
  if (!data || !updateData || !previousPath) {
    throw new Error('useAddActivityForm must be used within AddActivityFormContext');
  }
  return { data, updateData, previousPath };
};
