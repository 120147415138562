import React, { useMemo } from 'react';

import { Organizer } from '@/types';

import { Members } from '../members';

type OrganizersProps = {
  organizers: Organizer[];
};

const MAX_AMOUNT_ORGANIZERS_SHOWN = 2;

export const Organizers: React.FC<OrganizersProps> = ({ organizers }) => {
  const names = useMemo(() => {
    const firstNames = organizers
      .slice(0, MAX_AMOUNT_ORGANIZERS_SHOWN)
      .map(({ firstName }) => firstName)
      .join(' & ');

    if (organizers.length > MAX_AMOUNT_ORGANIZERS_SHOWN) {
      return `${firstNames} & ...`;
    }

    return firstNames;
  }, [organizers]);

  return (
    <div className='flex flex-row gap-4 items-center'>
      <Members members={organizers} maxAmountAvatar={MAX_AMOUNT_ORGANIZERS_SHOWN + 1} />
      <div className='text-gray-900 text-base'>{names}</div>
    </div>
  );
};
