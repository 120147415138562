import React from 'react';

export const ActivityIndexSkeletonPage: React.FC = () => {
  return (
    <div className='flex flex-row justify-between text-base py-2 px-4 text-gray-300 animate-pulse mt-3'>
      <div className='flex flex-col px-3 gap-4 w-full'>
        <div className='w-36 h-8 bg-gray-300 rounded-full mb-2' />
        <div className='w-full h-28 bg-gray-300 rounded-lg mb-2' />
        <div className='flex flex-row items-center'>
          <div className='bg-gray-300 h-5 w-5 rounded-full mr-3 ' />
          <div className='flex flex-col flex-grow gap-1'>
            <span className='w-44 h-4 bg-gray-300 rounded-full' />
            <span className='w-36 h-4 bg-gray-300 rounded-full' />
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='bg-gray-300 h-5 w-5 rounded-full mr-3 ' />
          <div className='flex flex-col flex-grow gap-1'>
            <span className='w-36 h-4 bg-gray-300 rounded-full' />
            <span className='w-48 h-4 bg-gray-300 rounded-full' />
          </div>
        </div>
        <div className='mt-3 flex flex-row justify-between items-center w-full'>
          <div className='flex flex-row h-9'>
            <div className='w-9 h-9 bg-gray-300 rounded-full' />
            <div className='w-9 h-9 bg-gray-300 rounded-full -ml-1.5' />
            <div className='-ml-1.5 h-full w-24 bg-gray-300 flex items-center rounded-full'></div>
          </div>
          <div className='w-24 h-9 bg-gray-300 rounded-full' />
        </div>
      </div>
    </div>
  );
};
