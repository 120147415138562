import React, { useEffect } from 'react';

import { useBottomSheet } from '@/components/bottom-sheet/hooks/useBottomSheet';

import { useActivities } from '../../hooks/useActivities';
import { ListItem } from './components/item/item';
import { IndexListViewLoadingView } from './components/loading-view';

export const IndexListViewPage: React.FC = () => {
  const { data: activities, status } = useActivities();

  const { scrollTo } = useBottomSheet();

  useEffect(() => {
    scrollTo(0);
  }, []);

  return (
    <div className='w-full flex flex-col gap-3 divide-y-2'>
      {status !== 'success' ? (
        <IndexListViewLoadingView />
      ) : (
        activities.map((activity, index) => (
          <React.Fragment key={index}>
            <ListItem
              id={activity.id}
              coverPictureUrl={activity.coverPictureUrl}
              title={activity.title}
              sportType={activity.sport}
              startsAt={activity.startsAt}
              endsAt={activity.endsAt}
              location={activity.location}
              slots={activity.slots}
              participants={activity.participants}
              frequency={activity.frequency}
              withJoinFeature={activity.withJoinFeature}
            />
          </React.Fragment>
        ))
      )}
    </div>
  );
};
