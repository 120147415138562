import { Avatar, AvatarImage, AvatarText } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Participant } from '@/types';

export type ParticipantsProps = {
  participants: Participant[];
  freeSlots?: number;
};

const SinglePerson = ({ participant }: { participant: Participant }) => {
  return (
    <div className='flex flex-col items-center'>
      <Avatar size={48}>
        {participant.profilePictureUrl ? (
          <AvatarImage src={participant.profilePictureUrl} alt={`${participant.firstName}-image`} />
        ) : (
          <AvatarText>{participant.firstName.charAt(0)}</AvatarText>
        )}
      </Avatar>
      <span
        className='text-sm mt-1 text-gray-700 overflow-hidden text-ellipsis text-center'
        style={{ maxWidth: '48px' }}
      >
        {participant.firstName}
      </span>
    </div>
  );
};

const MoreParticipants = ({ participants }: { participants: Participant[] }) => {
  return (
    <div className='flex flex-col items-center'>
      <div className='h-12 w-12 text-sm bg-indigo-50 rounded-full text-indigo-500 flex items-center justify-center font-bold'>
        +{participants.length}
      </div>
      <span className='text-sm mt-1 text-gray-700 w-12 text-center overflow-hidden text-ellipsis'>
        {participants[0] && participants[0].firstName}
        {participants[1] && `, ${participants[1].firstName}`}
        {participants.length > 2 && ', ...'}
      </span>
    </div>
  );
};

const FreeSlots = ({ amount }: { amount: number }) => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center'>
      <div className='h-12 w-12 text-sm bg-white rounded-full border border-indigo-200 text-indigo-500 flex items-center justify-center font-bold'>
        Slots
      </div>
      <span className='text-sm mt-1 text-gray-700 w-12 text-center'>{t('globals.freeSlots', { count: amount })}</span>
    </div>
  );
};

export const IndexDetailedActivityParticipants: React.FC<ParticipantsProps> = ({ participants, freeSlots }) => {
  return (
    <div className='flex flex-row gap-5 justify-center'>
      {participants[0] && <SinglePerson participant={participants[0]} />}
      {participants[1] && <SinglePerson participant={participants[1]} />}
      {participants.length === 3 && participants[2] && <SinglePerson participant={participants[2]} />}
      {participants.length > 3 && <MoreParticipants participants={participants.slice(2)} />}
      {freeSlots !== undefined && <FreeSlots amount={freeSlots} />}
    </div>
  );
};
