import React from 'react';

import { SkeletonItem } from './item/skeleton-item';

export const IndexListViewLoadingView: React.FC = () => {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </>
  );
};
