import { createContext, useContext } from 'react';

import { User } from '@/models/user';
import { AuthMethod } from '@/utils/auth';

export type AuthenticationState = 'BOOTING' | 'SIGNED_OUT' | 'SIGNED_IN';

export type SignUpInterface = {
  firstName: string;
  email: string;
  password: string;
};

type BaseAuthenticator = {
  emailPasswordLogin(email: string, password: string): Promise<void>;
  emailPasswordSignUp(props: SignUpInterface): Promise<void>;
  signOut(): Promise<void>;
  socialLogin(provider: AuthMethod): Promise<void>;
  updateUser(user: User): void;
};

export type SingedInAuthenticatorValue = {
  idToken: string;
  user: User;
  state: 'SIGNED_IN';
};

export type SignedOutAuthenticatorValue = {
  state: 'SIGNED_OUT';
  user: undefined;
};

export type BootingAuthenticatorValue = {
  state: 'BOOTING';
  user: undefined;
};

export type Authenticator = BaseAuthenticator &
  (SingedInAuthenticatorValue | SignedOutAuthenticatorValue | BootingAuthenticatorValue);

export const AuthenticationContext = createContext<Readonly<Authenticator> | undefined>(undefined);

export const AuthenticationProvider = AuthenticationContext.Provider;

export function useAuth(): Authenticator {
  const auth = useContext(AuthenticationContext);

  if (!auth) {
    throw new Error(
      `The component that calls useAuthentication() needs to be a deep child of <AuthenticationProvider>`,
    );
  }

  return auth;
}
