import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type CheckboxProps = { id: string; hasError?: boolean } & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Checkbox = React.forwardRef<HTMLInputElement, PropsWithChildren<CheckboxProps>>(
  ({ children, id, className, hasError, ...props }, ref) => {
    return (
      <div className={twMerge('flex items-center', className)}>
        <input ref={ref} id={id} type='checkbox' className={twMerge('w-4 h-4 rounded')} {...props} />
        <label className={twMerge('ml-2 text-base font-semibold', hasError && 'outline-1 text-red-600')} htmlFor={id}>
          {children}
        </label>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
