import classNames from 'classnames';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { useAuth } from '@/lib/auth';

type AvatarTextProps = {
  children: string;
};

export const AvatarText: React.FC<AvatarTextProps> = ({ children }) => {
  return <span className='font-medium text-gray-600'>{children}</span>;
};

type AvatarImageProps = {
  src: string;
  alt: string;
};

export const AvatarImage: React.FC<AvatarImageProps> = ({ src, alt }) => {
  const { user } = useAuth();
  const blurProfilePictures = useFeatureFlagEnabled('blurProfilePictures');

  return (
    <img
      className={classNames('w-full h-full rounded-full object-cover', {
        'blur-[2px]': blurProfilePictures && !user,
      })}
      src={src}
      alt={alt}
    ></img>
  );
};

type AvatarProps = {
  className?: string;
  size?: number;
};

export const Avatar: React.FC<PropsWithChildren<AvatarProps>> = ({ children, className, size = 32 }) => {
  return (
    <div
      className={twMerge(
        'relative inline-flex items-center justify-center overflow-hidden bg-indigo-100 border border-indigo-200 rounded-full',
        className,
      )}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minHeight: `${size}px`,
        minWidth: `${size}px`,
      }}
    >
      {children}
    </div>
  );
};
