import { Divider } from '@component-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Comments } from '@/components/comments/comments';
import { useActivityById } from '@/hooks/useActivityById';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

import { MyActivitiesDetailsActivity } from './components/activity/activity';
import { MyActivitiesDetailsSkeleton } from './components/skeleton';

export const MyActivitiyDetailsPage: React.FC = () => {
  const { activityId: activeActivityId } = useParams();
  const { data: detailedActivity, status } = useActivityById(activeActivityId || '', {
    enabled: !!activeActivityId,
  });

  const navigate = useCustomNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'my-activities.details' });

  useEffect(() => {
    if (status === 'error') {
      navigate({ pathname: paths.myActivities.base });
    }
  }, [status]);

  if (status !== 'success') {
    return <MyActivitiesDetailsSkeleton />;
  }

  return (
    <div className='mb-10'>
      <MyActivitiesDetailsActivity {...detailedActivity} />
      <div className='ml-7 mt-9 mb-1 font-semibold text-gray-600'>{t('comments.title')}</div>
      <Divider />
      <div className='mt-3 ml-7 mr-5'>
        <Comments activityId={detailedActivity.id} />
      </div>
    </div>
  );
};
