import { config } from '@config';
import { useContext } from 'react';

import { usePostEvent } from '@/api/resources/events';
import { Logger } from '@/lib/logs/logger';
import { isWebViewBrowser } from '@/utils/isWebViewBrowser';

import { AnalyticsContext } from '../analytics-context';

type EventFunc = (props: { name: AnalyticsEvents; params: Record<string, string> }) => void;

type PageviewFunc = (path: string) => void;

type AnalyticsProps = {
  browserStorageId: string;
  affiliateId?: string;
  sessionId: string;
  track: {
    event: EventFunc;
    pageview: PageviewFunc;
  };
};

export enum AnalyticsEvents {
  COOKIE_CONSENT = 'cookie_consent',
  CREATE_ACTIVITY = 'create_activity',
  DELETE_ACTIVITY = 'delete_activity',
  JOIN_ACTIVITY = 'join_activity',
  LEAVE_ACTIVITY = 'leave_activity',
  SEND_COMMENT = 'send_comment',
  SEND_SURVEY = 'send_survey',
  SOCIAL_AUTH = 'social_auth',
  SUBMIT_TYPE_FILTERS = 'submit_type_filters',
  UPDATE_PROFILE_PICTURE = 'update_profile_picture',
  VIEW_DETAILS = 'view_details',
}

export const useAnalytics = (): AnalyticsProps => {
  const context = useContext(AnalyticsContext);
  const { mutateAsync } = usePostEvent();
  const isWebViewBrowserValue = isWebViewBrowser();

  if (!context) {
    throw new Error('useAnalyticsIds must be used within a AnalyticsProvider');
  }

  if (!context.browserStorageId || !context.sessionId) {
    throw new Error('useAnalyticsIds must be used after AnalyticsProvider is initialized');
  }

  const sendTracking = (entry: { name: string; data: Record<string, string> }) => {
    if (config.EVENTS_DISABLED) {
      return;
    }

    mutateAsync({
      bodyData: {
        ...entry,
        sessionId: context.sessionId,
        affiliateId: context.affiliateId,
        browserStorageId: context.browserStorageId,
      },
    }).catch((error) => {
      if (!(error instanceof Error)) {
        Logger.error('Something unclear went wrong saving the tracking event');
      }
      Logger.error(`Something unexpected went wrong saving the tracking event: ${error.message}`);
    });
  };

  const pageview: PageviewFunc = (path) => {
    sendTracking({
      name: 'pageview',
      data: { path, isWebviewBrowser: String(isWebViewBrowserValue) },
    });
  };

  const event: EventFunc = ({ name, params }) => {
    sendTracking({
      name,
      data: params,
    });
  };

  return {
    browserStorageId: context.browserStorageId,
    affiliateId: context.affiliateId,
    sessionId: context.sessionId,
    track: {
      event,
      pageview,
    },
  };
};
