import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import Option from '@mui/base/Option';
import MuiSelect from '@mui/base/Select';
import { SelectOption } from '@mui/base/useOption';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

type SelectInputOptionProps<T> = { value: T; label: string; isActive: boolean };

export const SelectInputOption = <T,>({ value, label, isActive }: SelectInputOptionProps<T>) => {
  return (
    <Option
      value={value}
      slotProps={{
        root: {
          className: twMerge(
            isActive ? 'bg-indigo-500 text-white' : 'text-gray-900',
            'relative cursor-default select-none py-2 pl-3 pr-9',
          ),
        },
      }}
    >
      <span className={twMerge('text-base', 'font-normal', 'block truncate')}>{label}</span>
      {isActive ? (
        <span
          className={twMerge(
            isActive ? 'text-white' : 'text-indigo-600',
            'absolute inset-y-0 right-0 flex items-center pr-4',
          )}
        >
          <CheckIcon className='h-5 w-5' aria-hidden='true' />
        </span>
      ) : null}
    </Option>
  );
};

type SelectInputType<T> = {
  label?: string;
  textClassName?: string;
  selectedItem: {
    value?: T;
    label: string;
  };
  onChange: (value: T | undefined) => void;
  placeholder?: string;
  withIcon?: boolean;
  error?: string;
  className?: string;
  ListBox?: {
    className?: string;
  };
};

export const SelectInput = <T,>({
  label,
  children,
  selectedItem,
  textClassName,
  onChange,
  placeholder,
  withIcon = true,
  error,
  className,
  ListBox,
}: React.PropsWithChildren<SelectInputType<T>>) => {
  const muiOnChange = (_: unknown, newValue: T | null) => {
    onChange(newValue == null || newValue === '' ? undefined : newValue);
  };

  const renderValue = (option: SelectOption<T> | null) => {
    const text = option == null ? placeholder ?? '' : option.label;
    return (
      <>
        <span className={twMerge(option == null && 'text-gray-500', textClassName)}>{text}</span>
        {withIcon && (
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </span>
        )}
      </>
    );
  };

  return (
    <div>
      {label && <label className='block text-base font-medium leading-6 text-gray-800 mb-4'>{label}</label>}
      <MuiSelect
        renderValue={renderValue}
        value={selectedItem?.value == null ? null : selectedItem.value}
        onChange={muiOnChange}
        slotProps={{
          root: {
            className: twMerge(
              'relative',
              'min-w-[320px]',
              'cursor-default',
              'rounded-md',
              'bg-white',
              'py-1.5',
              'pl-3',
              'text-left',
              'text-gray-900',
              'shadow-sm',
              'ring-1',
              'ring-inset',
              'ring-gray-300',
              'focus:outline-none',
              'focus:ring-2',
              'focus:ring-indigo-600',
              'sm:text-sm',
              'sm:leading-6',
              withIcon ? 'pr-10' : 'pr-3',
              error && 'border border-red-600',
              className,
            ),
          },
          listbox: {
            className: twMerge(
              'min-w-[320px]',
              'max-h-60',
              'w-full',
              'overflow-auto',
              'rounded-md',
              'bg-white',
              'py-1',
              'text-base',
              'shadow-lg',
              'ring-1',
              'ring-black',
              'ring-opacity-5',
              'focus:outline-none',
              'sm:text-sm',
              ListBox?.className,
            ),
          },
          popper: {
            className: twMerge('z-30'),
          },
        }}
      >
        {children}
      </MuiSelect>
      {error && <p className='ml-1 mt-1 text-base text-red-600'>{error}</p>}
    </div>
  );
};
