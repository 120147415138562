import { TextArea } from '@component-library';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepTwoFormData } from '@/pages/add-activity/form-types';

type AddActivityStepTwoLocationDescriptionProps = {
  control: Control<StepTwoFormData>;
};

export const AddActivityStepTwoLocationDescription: React.FC<AddActivityStepTwoLocationDescriptionProps> = ({
  control,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-two.location-description' });
  const { field, fieldState } = useController({
    name: 'locationDescription',
    control,
    rules: { required: t('validation.required') },
  });

  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800 mb-1'>{`${t('label')} *`}</label>
      <p className='text-base text-gray-500 mb-2'>{t('hint')}</p>
      <TextArea
        InputProps={{
          className: 'resize-none',
        }}
        rows={3}
        placeholder='Ortsbeschreibung'
        {...field}
        error={fieldState.error?.message}
      />
    </div>
  );
};
