import { Icon } from '@component-library';
import React from 'react';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

const SkeletonHeader: React.FC = () => {
  const navigate = useCustomNavigate();

  const handleOnBackClick = () => {
    navigate({ pathname: paths.home });
  };

  return (
    <div
      className='relative col-span-2 h-16 flex flex-col items-center overflow-hidden justify-center bg-gray-300'
      style={{
        borderTopRightRadius: 'var(--rsbs-overlay-rounded)',
        borderTopLeftRadius: 'var(--rsbs-overlay-rounded)',
      }}
    >
      <div className='absolute right-0 top-0 p-1 z-20 hover:cursor-pointer' onClick={handleOnBackClick}>
        <Icon color='white' iconKey='x' />
      </div>
    </div>
  );
};

export const DetailedActivitySkeletonLoader: React.FC = () => {
  return (
    <div className='flex flex-col w-full animate-pulse'>
      <SkeletonHeader />
      <div className='px-4'>
        <div className='flex flex-col mt-7 items-start gap-4 m-auto max-w-sm'>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-32 h-4 bg-gray-300 rounded-full' />
              <span className='w-24 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-28 h-4 bg-gray-300 rounded-full' />
              <span className='w-36 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <span className='mr-12 bg-gray-300 w-5 h-5 rounded-full'></span>
            <div className='flex flex-col text-gray-900 text-sm gap-1'>
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
              <span className='w-56 h-4 bg-gray-300 rounded-full' />
              <span className='w-24 h-4 bg-gray-300 rounded-full' />
            </div>
          </div>
        </div>
      </div>
      <div className='mt-5 flex flex-row gap-5 justify-center'>
        <div className='flex flex-col gap-1 align-center'>
          <div className='flex flex-col items-center bg-gray-300 rounded-full h-12 w-12' />
          <span className='w-12 h-3 bg-gray-300 rounded-full' />
        </div>
        <div className='flex flex-col gap-1 align-center'>
          <div className='flex flex-col items-center bg-gray-300 rounded-full h-12 w-12' />
          <span className='w-12 h-3 bg-gray-300 rounded-full' />
        </div>
      </div>
      <div className='mt-5 flex flex-col gap-5 items-center'>
        <div className='bg-gray-300 w-56 h-8 rounded-md mt-3' />
        <span className='w-12 h-3 bg-gray-300 rounded-full' />
      </div>
    </div>
  );
};
