import { useApiPostRequest } from '../hooks/useApiPostRequest';

const POST_PROFILE_PICTURE_PATH = '/me/profile-picture';

export type PostProfilePicturePathVariables = undefined;

export type PostProfilePictureQueryParams = undefined;

// TODO: should be { file: string } but does not work
export type PostProfilePictureBodyData = FormData;

type PostProfilePictureSuccessResponse = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  profilePictureUrl?: string;
  privacyPolicyAcceptedAt?: string;
  emailVerifiedAt?: string;
};
type PostProfilePictureErrorResponse = Error;

export const usePostProfilePicture = () => {
  return useApiPostRequest<
    PostProfilePicturePathVariables,
    PostProfilePictureQueryParams,
    PostProfilePictureBodyData,
    PostProfilePictureSuccessResponse,
    PostProfilePictureErrorResponse
  >(POST_PROFILE_PICTURE_PATH);
};
