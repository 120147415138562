import { Loader } from '@component-library';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useBottomSheet } from '@/components/bottom-sheet/hooks/useBottomSheet';
import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useActivityById } from '@/hooks/useActivityById';
import { Auth } from '@/pages/index/components/auth/auth';
import { FullWidthLayout } from '@/pages/index/layouts/full-width.layout';
import { paths } from '@/routing/paths';

export const IndexDetailedActivityLoginAndJoinPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.auth' });
  const { t: baseT } = useTranslation();
  const { activityId: activeActivityId } = useParams();
  const { snapTo } = useBottomSheet();
  const { data: detailedActivity } = useActivityById(activeActivityId || '', {
    enabled: !!activeActivityId,
  });

  const expandBottomSheet = () => {
    snapTo('max');
  };

  useEffect(() => {
    if (!detailedActivity) {
      return;
    }
    expandBottomSheet();
  }, [detailedActivity]);

  if (!activeActivityId) {
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }

  if (!detailedActivity) {
    // NOTE: the loader should never be rendered because usally the activity is already fetched
    // NOTE: and cached because the user is on details page before he clicks on join
    return (
      <div className='w-full flex items-center justify-center pt-10'>
        <Loader />
      </div>
    );
  }

  return (
    <FullWidthLayout
      title={baseT(`globals.sportType.${detailedActivity.sport}`)}
      backTo={{ pathname: paths.details(activeActivityId).base }}
    >
      <div className='text-gray-700 text-lg text-center mt-4 mb-5'>{t('join')}</div>
      <Auth
        joinActivityId={activeActivityId}
        previousPath={paths.details(activeActivityId).loginAndJoin}
        onSuccessPath={paths.details(activeActivityId).base}
      />
    </FullWidthLayout>
  );
};
