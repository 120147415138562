import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { paths } from '@/routing/paths';
import { Frequency } from '@/types';

import { StepFourFormData, StepOneFormData, StepThreeFormData, StepTwoFormData } from '../form-types';

type Data = Partial<StepOneFormData & StepTwoFormData & StepThreeFormData & StepFourFormData>;

export type AddActivityFormContextValue = { data: Data; updateData: (newData: Data) => void; previousPath: string };

export const AddActivityFormContext = createContext<Partial<AddActivityFormContextValue>>({});

export const AddActivityFormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [data, setData] = useState<Data>({ frequency: Frequency.ONCE });
  const { state } = useLocation();
  const previousPath = useMemo(() => state?.previousPath || paths.home, []);

  const updateData = (newData: Data) => {
    setData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <AddActivityFormContext.Provider value={{ data, updateData, previousPath }}>
      {children}
    </AddActivityFormContext.Provider>
  );
};
