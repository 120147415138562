import { NumberPicker } from '@component-library';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepThreeFormData } from '@/pages/add-activity/form-types';

type AddActivityStepThreePriceProps = {
  control: Control<StepThreeFormData>;
};

export const AddActivityStepThreePrice: React.FC<AddActivityStepThreePriceProps> = ({ control }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-three.price' });
  const { field } = useController({
    name: 'price',
    rules: {
      // NOTE: No error message needed because it has a default value and the UI does not allow to enter a not valid value
      required: true,
    },
    control,
  });
  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800 mb-2'>{`${t('label')} *`}</label>
      <NumberPicker value={field.value} onChange={field.onChange} unit='€' />
    </div>
  );
};
