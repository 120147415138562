import { CloseableNavbar, Logo } from '@component-library';
import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

export const AuthPagesLayout: React.FC = () => {
  const { state } = useLocation();
  const previousPath = useMemo(() => state?.previousPath || paths.home, []);
  const navigate = useCustomNavigate();
  return (
    <div className='w-full flex flex-col text-gray-600 items-center mb-8'>
      <CloseableNavbar
        className='fixed'
        onClose={() => {
          navigate({ pathname: previousPath });
        }}
      >
        <CustomLink to={{ pathname: paths.home }} className='px-2'>
          <Logo variant='small' color='brand' />
        </CustomLink>
      </CloseableNavbar>
      <div className='mt-24 px-6 w-full max-w-md'>
        <Outlet />
      </div>
    </div>
  );
};
