import { config } from '@config';
import { usePostHog } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { paths } from '@/routing/paths';

import { CustomLink } from '../custom-link/custom-link';

export const CookieConsentHandler: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const areCookiesUsedWithoutAskingForPermissions = config.COOKIES_USED_WITHOUT_ASKING_FOR_PERMISSIONS === 'true';

  const posthog = usePostHog();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue() === 'true');

  useEffect(() => {
    if (!cookieConsent && !areCookiesUsedWithoutAskingForPermissions) {
      posthog.opt_out_capturing();
      return;
    }

    posthog.opt_in_capturing();
    // eslint-disable-next-line no-underscore-dangle
    posthog._gdpr_update_persistence({ enable_persistence: true, persistence_type: 'cookie' });
  }, [cookieConsent]);

  useEffect(() => {
    track.pageview(location.pathname);
  }, [location.pathname]);

  const handleOnAccept = () => {
    setCookieConsent(true);
    track.event({ name: AnalyticsEvents.COOKIE_CONSENT, params: { cookieConsent: 'accepted' } });
  };

  const handleOnDecline = () => {
    track.event({ name: AnalyticsEvents.COOKIE_CONSENT, params: { cookieConsent: 'declined' } });
  };

  return (
    <>
      {!areCookiesUsedWithoutAskingForPermissions && (
        <CookieConsent
          location='top'
          flipButtons
          onAccept={handleOnAccept}
          onDecline={handleOnDecline}
          buttonWrapperClasses='flex flex-row-reverse md:flex-row'
          buttonStyle={{
            background: '#283593',
            color: '#fff',
            borderRadius: '6px',
            padding: '10px 30px',
          }}
          contentClasses='text-sm'
          enableDeclineButton
          style={{
            alignItems: 'center',
            backgroundColor: '#F5F5F5',
            borderBottom: '1px solid #424242',
            color: '#424242',
            justifyContent: 'center',
          }}
          declineButtonStyle={{
            background: '#fff',
            color: '#424242',
            borderRadius: '6px',
            border: '1px solid #283593',
            padding: '10px 30px',
          }}
          declineButtonText={t('cookie-consent.buttons.reject')}
          buttonText={t('cookie-consent.buttons.accept')}
        >
          <div className='flex flex-col md:flex-row gap-1 items-center'>
            <span className='text-center'>
              {t('cookie-consent.text')} &nbsp;&nbsp;
              <CustomLink className=' underline' to={{ pathname: paths.imprint }}>
                {t('globals.legal.imprint')}
              </CustomLink>
              &nbsp; &nbsp;
              <CustomLink className=' underline' to={{ pathname: paths.privacy }}>
                {t('globals.legal.privacy')}
              </CustomLink>
              &nbsp; &nbsp;
              <CustomLink className=' underline' to={{ pathname: paths.terms }}>
                {t('globals.legal.terms')}
              </CustomLink>
            </span>
          </div>
        </CookieConsent>
      )}
      {children}
    </>
  );
};
