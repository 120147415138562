import { Divider } from '@component-library';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Comments } from '@/components/comments/comments';
import { CustomLink } from '@/components/custom-link/custom-link';
import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useActivityById } from '@/hooks/useActivityById';
import { paths } from '@/routing/paths';

export const IndexDetailedActivityCommentsPage: React.FC = () => {
  const { activityId: activeActivityId } = useParams();
  const { data, status } = useActivityById(activeActivityId ?? '', { enabled: !!activeActivityId });
  const { t } = useTranslation(undefined, { keyPrefix: 'globals' });

  if (!activeActivityId) {
    // NOTE: It could make more sense to throw an error when we have error boundaries in place
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }

  return (
    <div className='w-[calc(100%+16px)] -ml-2'>
      <div className='flex flex-row gap-5 items-center text-gray-600 m-4'>
        <CustomLink to={{ pathname: paths.details(activeActivityId).base }}>
          <ChevronLeftIcon width={24} height={24} />
        </CustomLink>
        {status === 'success' ? (
          <span className='text-xl font-semibold'>{`${t(`sportType.${data.sport}`)} - ${t('comments.title')}`}</span>
        ) : (
          <span className='h-5 w-40 rounded-full bg-gray-300 animate-pulse'></span>
        )}
      </div>
      <Divider className=' border-gray-400' />
      <div className='m-4'>
        <Comments activityId={activeActivityId} />
      </div>
    </div>
  );
};
