import React, { PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { Menu } from '@/components/menu/menu';
import { useActivityById } from '@/hooks/useActivityById';
import { paths } from '@/routing/paths';
import { SportType } from '@/types';
import { getImageSrcBySportType } from '@/utils/getImageSrcBySportType';

export const ActivityPage: React.FC = () => {
  const { activityId } = useParams();

  const { data: detailedActivity, status: statusGetActivity } = useActivityById(activityId || '', {
    enabled: !!activityId,
  });

  if (!activityId) {
    // NOTE: It probably makes more sense to show a 404 page
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }

  if (statusGetActivity !== 'success') {
    return (
      <>
        <div className='h-[154px] w-full pulse bg-gray-300' />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <div className='max-w-5xl m-auto relative'>
        <div className='w-full'>
          <CoverPicture
            coverPictureUrl={detailedActivity.coverPictureUrl}
            logoPictureUrl={detailedActivity.logoPictureUrl}
            sport={detailedActivity.sport}
          >
            <div className='relative w-full h-full'>
              <Menu
                BurgerIconProps={{
                  className: 'text-gray-200 lg:text-gray-700',
                }}
              />
            </div>
          </CoverPicture>
        </div>
        <Outlet />
      </div>
    </>
  );
};

type CoverPictureProps = {
  coverPictureUrl?: string;
  logoPictureUrl?: string;
  sport: SportType;
};

const CoverPicture: React.FC<PropsWithChildren<CoverPictureProps>> = ({
  coverPictureUrl,
  logoPictureUrl,
  sport,
  children,
}) => {
  const sportUrl = coverPictureUrl ?? getImageSrcBySportType(sport);

  return (
    <div
      className='relative col-span-2 h-[154px] flex flex-col items-center overflow-hidden justify-center bg-gray-300'
      style={{
        backgroundImage: `url(${sportUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className='absolute w-full h-full bg-black/40'>{children}</div>
      {logoPictureUrl && <img className='w-[120px] absolute left-8' src={logoPictureUrl} />}
    </div>
  );
};
