import React from 'react';
import { twMerge } from 'tailwind-merge';

type TextAreaProps = {
  label?: string;
  error?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const TextInput = React.forwardRef<HTMLInputElement, TextAreaProps>(
  ({ error, className, label, ...props }, ref) => {
    return (
      <div className='flex flex-col gap-1'>
        {label && (
          <label htmlFor={props.id} className='ml-1 text-base font-semibold'>
            {label}
          </label>
        )}
        <input
          type={props.type || 'text'}
          ref={ref}
          className={twMerge(
            'block',
            'w-full',
            'rounded-md',
            'border-0',
            'p-1.5',
            'text-gray-900',
            'shadow-sm',
            'ring-1',
            'ring-inset',
            'ring-gray-300',
            'placeholder:text-gray-400',
            'focus:outline-none',
            'focus:ring-2',
            'focus:ring-indigo-600',
            'border',
            // NOTE: The text must be at least 16 px to prevent the browser from zooming in on focus
            'text-lg',
            'sm:leading-6',
            className,
            error && 'ring-1 ring-red-500 border-red-500',
          )}
          {...props}
        />
        {error && <p className='ml-1 text-sm text-red-500'>{error}</p>}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

// export const TextInput = React.forwardRef(PlainTextInput);
