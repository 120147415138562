import cx from 'classnames';
import React, { forwardRef } from 'react';

import styles from './index.module.css';

type Props = {
  children: React.ReactNode;
} & Omit<React.PropsWithoutRef<JSX.IntrinsicElements['div']>, 'children'>;

const InnerSheetContent: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({ className, ...props }, ref) => (
  <div
    className={cx(
      styles.spacing,
      'grid grid-flow-row place-items-start text-gray-900 text-xl',
      'px-2 pt-4 gap-4',
      className,
    )}
    {...props}
    ref={ref}
  />
);

export const BottomSheetContent = forwardRef<HTMLDivElement, Props>(InnerSheetContent);
