import profilePicturePlaceholderSrc from '@assets/images/error/404.png';
import { Button } from '@component-library';
import React, { useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { usePostProfilePicture } from '@/api/resources/profile';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useUser } from '@/contexts/hooks/useUser';
import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { User } from '@/models/user';
import cropImage from '@/utils/image/cropImage';

export const MyProfilePicture: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'myProfile' });
  const user = useUser();
  const { updateUser } = useAuth();
  const { mutateAsync } = usePostProfilePicture();
  const [currentPicture, setCurrentPicture] = useState<string | undefined>();
  const { track } = useAnalytics();

  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleProfilePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage) {
      const imageURL = URL.createObjectURL(selectedImage);
      setCurrentPicture(imageURL);
    }
  };

  const handleSubmitImage = async (image: string, area: Area) => {
    track.event({ name: AnalyticsEvents.UPDATE_PROFILE_PICTURE, params: {} });

    const croppedImage = await cropImage(image, area);

    const formData = new FormData();
    formData.append('file', croppedImage);
    const promise = mutateAsync({ bodyData: formData })
      .then((response) => {
        updateUser(new User(response));
      })
      .catch((e) => {
        if (e instanceof Error) {
          Logger.error(`An unexpected error occured while submitting a new profile picture: "${e.message}"`);
        }
      })
      .finally(() => {
        setCurrentPicture(undefined);
      });

    toast.promise(promise, {
      loading: t('form.loading'),
      success: t('form.success'),
      error: t('form.error'),
    });
  };
  const cancelCropping = () => {
    setCurrentPicture(undefined);

    if (!imageInputRef.current) {
      Logger.error('Image input ref is undefined. This should never happen');
      return;
    }

    imageInputRef.current.value = '';
  };

  return (
    <form className='flex flex-col gap-6 w-full mb-8'>
      <input
        ref={imageInputRef}
        className='hidden'
        type='file'
        id='profilePictureInput'
        name='profilePicture'
        accept='image/*'
        onChange={handleProfilePictureChange}
      />
      {currentPicture ? (
        <CropImage image={currentPicture} onSubmit={handleSubmitImage} cancel={cancelCropping} />
      ) : (
        <>
          <img
            width='100%'
            height='100%'
            src={user.profilePictureUrl ?? profilePicturePlaceholderSrc}
            className='object-cover h-40 w-40 rounded-full mx-auto'
            alt='Profile Picture'
          />
          <Button
            type='button'
            className='self-center px-12 mt-2'
            onClick={() => {
              if (!imageInputRef.current) {
                Logger.error('Image input ref is undefined. This should never happen');
                return;
              }

              imageInputRef.current.click();
            }}
          >
            {t('labels.upload')}
          </Button>
        </>
      )}
    </form>
  );
};

type CropImageProps = {
  image: string;
  onSubmit: (image: string, area: Area) => void;
  cancel: () => void;
};

const CropImage: React.FC<CropImageProps> = ({ cancel, image, onSubmit }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const { t } = useTranslation(undefined, { keyPrefix: 'myProfile' });
  const { t: tGlobals } = useTranslation(undefined, { keyPrefix: 'globals' });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete: (croppedArea: Area, pixels: Area) => void = (_croppedArea: Area, pixels: Area) => {
    setCroppedAreaPixels(pixels);
  };

  if (!image) {
    Logger.error('currentPicture is undefined. This should never happen');
    toast.error(tGlobals('errors.unexpected'));
    return null;
  }

  return (
    <>
      <div className='relative object-cover h-40 w-40 box-border rounded-lg mx-auto'>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          showGrid={false}
          aspect={1 / 1}
          cropShape='round'
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit='horizontal-cover'
        />
      </div>
      <div className='flex flex-col gap-1'>
        <Button
          id='save'
          type='button'
          className='mt-2 flex-grow'
          onClick={() => {
            if (!croppedAreaPixels) {
              Logger.error('croppedAreaPixels is undefined. This should never happen');
              toast.error(tGlobals('errors.unexpected'));
              return;
            }

            onSubmit(image, croppedAreaPixels);
          }}
        >
          {t('labels.submit')}
        </Button>
        <Button variant='text' onClick={cancel}>
          {t('labels.cancel')}
        </Button>
      </div>
    </>
  );
};
