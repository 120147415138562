import { Avatar, AvatarGroup, AvatarImage, AvatarText } from '@component-library';
import React, { PropsWithChildren } from 'react';

type Member = {
  firstName: string;
  profilePictureUrl?: string;
};

export type MembersProps = {
  className?: string;
  members: Member[];
  maxAmountAvatar: number;
};

const SinglePerson = ({ member }: { member: Member }) => {
  if (member.profilePictureUrl) {
    return (
      <Avatar>
        <AvatarImage src={member.profilePictureUrl} alt={`${member.firstName}-image`} />
      </Avatar>
    );
  }

  return (
    <Avatar>
      <AvatarText>{member.firstName.charAt(0)}</AvatarText>
    </Avatar>
  );
};

const MoreMembers = ({ amount }: { amount: number }) => {
  return (
    <div className='z-10 h-8 w-8 flex flex-col items-center'>
      <div className='h-8 w-8 text-xs bg-gray-200 rounded-full text-gray-600 flex items-center justify-center font-bold border border-gray-300 '>
        +{amount}
      </div>
    </div>
  );
};

export const Members: React.FC<PropsWithChildren<MembersProps>> = ({
  children,
  className,
  members,
  maxAmountAvatar,
}) => {
  const amountAvatar = maxAmountAvatar > members.length ? members.length : maxAmountAvatar - 1;

  return (
    <AvatarGroup className={className}>
      {members.slice(0, amountAvatar).map((member, index) => (
        <SinglePerson key={index} member={member} />
      ))}

      {members.length > maxAmountAvatar && <MoreMembers amount={members.length - (maxAmountAvatar - 1)} />}
      {members.length === maxAmountAvatar && members[maxAmountAvatar - 1] && (
        <SinglePerson member={members[maxAmountAvatar - 1]} />
      )}
      {children}
    </AvatarGroup>
  );
};
