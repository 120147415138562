import {
  Button,
  CloseableNavbar,
  SelectInputBox,
  SelectInputBoxIcon,
  SelectInputBoxOption,
  SportsIcon,
} from '@component-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetActivitiesCount } from '@/api/resources/activity';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useFilterTypes } from '@/contexts/hooks/useFilterTypes';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useActivities } from '@/pages/index/hooks/useActivities';
import { paths } from '@/routing/paths';
import { SportType } from '@/types';
import { isSportType } from '@/utils/converter/convertStringToSportType';

export const FilterTypesPage: React.FC = () => {
  const { track } = useAnalytics();
  const { state } = useLocation();
  const previousPath = useMemo(() => state?.previousPath || paths.home, []);
  const { t } = useTranslation(undefined, { keyPrefix: 'home.filter' });
  const { t: tGlobals } = useTranslation(undefined, { keyPrefix: 'globals' });
  const { types, setTypes } = useFilterTypes();
  // NOTE: the type of selectedTypes is string[] because of the SelectInputBox component
  // NOTE: it would be better to make the SelectInputBox component generic
  const [selectedTypes, setSelectedTypes] = useState<string[]>(types ?? []);
  const { data, isLoading } = useGetActivitiesCount(undefined, { queryParams: { types: selectedTypes } });
  const { remove } = useActivities();
  const navigate = useCustomNavigate();

  const foundActivitiesCount = (() => {
    if (data === undefined) {
      return 0;
    }

    if (selectedTypes.length === 0) {
      return 0;
    }

    return data;
  })();

  const handleSelectAll = () => {
    setSelectedTypes(Object.values(SportType));
  };

  const handleDeselectAll = () => {
    setSelectedTypes([]);
  };

  const navigateToPreviousPage = () => {
    navigate({ pathname: previousPath });
  };

  const handleSubmit = () => {
    // Removes fetched activities from cache to force loading screen to show up
    remove();
    // NOTE: Quick solution to avoid generic implementation of SelectInputBox. This line is only needed to ensure type safetyness.
    const sportTypes = selectedTypes.filter(isSportType);
    setTypes(sportTypes);

    track.event({ name: AnalyticsEvents.SUBMIT_TYPE_FILTERS, params: { types: sportTypes.join(',') } });

    navigate({ pathname: paths.home });
  };

  return (
    <div className='fixed w-full h-full flex flex-col'>
      <CloseableNavbar className='fixed top-0 w-screen' onClose={navigateToPreviousPage}>
        <span className='text-lg text-gray-700'>{`${t('title')} - ${t('type.title')}`}</span>
      </CloseableNavbar>
      <div className='w-full h-full flex flex-col items-center max-w-md m-auto px-2 pt-24'>
        <SelectInputBox selected={selectedTypes} onChange={setSelectedTypes} className=''>
          {Object.values(SportType)
            .sort((a, b) => {
              return tGlobals(`sportType.${a}`) < tGlobals(`sportType.${b}`) ? -1 : 1;
            })
            .map((type) => (
              <SelectInputBoxOption key={type} value={type}>
                <SelectInputBoxIcon>
                  <SportsIcon sport={type} />
                </SelectInputBoxIcon>
                {tGlobals(`sportType.${type}`)}
              </SelectInputBoxOption>
            ))}
        </SelectInputBox>
        <div className='w-full flex flex-row justify-between text-gray-600'>
          <Button variant='text' className='text-gray-600' onClick={handleSelectAll}>
            {t('type.buttons.selectAll')}
          </Button>
          <Button variant='text' className='text-gray-600' onClick={handleDeselectAll}>
            {t('type.buttons.deselectAll')}
          </Button>
        </div>
        <div className='w-full flex flex-row justify-between text-gray-600 gap-5 max-w-md my-5'>
          <Button variant='outlined' className='flex-grow' onClick={navigateToPreviousPage}>
            {t('type.buttons.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            variant='contained'
            className='flex-grow'
            isLoading={isLoading}
            disabled={foundActivitiesCount === 0}
          >
            {t('type.buttons.results', { count: foundActivitiesCount })}
          </Button>
        </div>
      </div>
    </div>
  );
};
