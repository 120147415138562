import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthPagesLayout } from '@/layouts/auth-pages.layout';
import { MenuPageLayout } from '@/layouts/menu-page.layout';
import { ActivityPage } from '@/pages/activity/activity.page';
import { ActivityCommentsPage } from '@/pages/activity/comments/comments.page';
import { ActivityIndexPage } from '@/pages/activity/index/index.page';
import { FilterTypesPage } from '@/pages/filter/types.page';
import { IndexAuthPage } from '@/pages/index/pages/auth/auth.page';
import { IndexDetailedActivityCommentsPage } from '@/pages/index/pages/detailed-activity/pages/comments.page';
import { IndexDetailedActivityLoginAndJoinPage } from '@/pages/index/pages/detailed-activity/pages/login-and-join.page';
import { IndexFilterLocationPage } from '@/pages/index/pages/filter/pages/location/location.page';
import { LoginPage } from '@/pages/login/login.page';
import { MyActivitiyDetailsPage } from '@/pages/my-activities/pages/details/details.page';
import { MyActivitiesIndexPage } from '@/pages/my-activities/pages/index/index.page';
import { MyActivitiesPage } from '@/pages/my-activities/pages/my-activities.page';
import { MyProfilePage } from '@/pages/my-profile/my-profile.page';
import { ResetPasswordPage } from '@/pages/reset-password/reset-password.page';
import { SignUpPage } from '@/pages/sign-up/sign-up.page';

import { LoadingOverlay } from '../components/loading-overlay';
import { NotFoundPage } from '../pages/404';
import { AddActivityPage } from '../pages/add-activity/add-activity.page';
import { AddActivityStepFourPage } from '../pages/add-activity/pages/step-four/step-four.page';
import { AddActivityStepOnePage } from '../pages/add-activity/pages/step-one/step-one.page';
import { AddActivityStepThreePage } from '../pages/add-activity/pages/step-three/step-three.page';
import { AddActivityStepTwoPage } from '../pages/add-activity/pages/step-two/step-two.page';
import { IndexPageWrapper } from '../pages/index/index.page';
import { IndexDetailedActivityPage } from '../pages/index/pages/detailed-activity/detailed-activity.page';
import { IndexListViewPage } from '../pages/index/pages/list-view/list-view';
import { paths } from './paths';
import { RequireBoot } from './require-boot';
import { ScrollToTop } from './scroll-to-top';
import { SignedInRoute } from './signed-in-route';
import { SignedOutRoute } from './signed-out-route';

const PrivacyPage = React.lazy(() =>
  import('../pages/privacy/privacy.page').then((module) => ({ default: module.PrivacyPage })),
);
const ImprintPage = React.lazy(() =>
  import('../pages/imprint/imprint.page').then((module) => ({ default: module.ImprintPage })),
);
const TermsPage = React.lazy(() =>
  import('../pages/terms/terms.page').then((module) => ({ default: module.TermsPage })),
);
const AboutPage = React.lazy(() =>
  import('../pages/about/about.page').then((module) => ({ default: module.AboutPage })),
);

export const PageRoutes: React.FC = () => {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Routes>
        <Route
          element={
            <>
              <RequireBoot />
              <ScrollToTop />
            </>
          }
        >
          <Route element={<SignedInRoute />}>
            <Route path={paths.addActivity.base} element={<AddActivityPage />}>
              <Route path={paths.addActivity.stepOne} element={<AddActivityStepOnePage />} />
              <Route path={paths.addActivity.stepTwo} element={<AddActivityStepTwoPage />} />
              <Route path={paths.addActivity.stepThree} element={<AddActivityStepThreePage />} />
              <Route path={paths.addActivity.stepFour} element={<AddActivityStepFourPage />} />
            </Route>
          </Route>
          <Route element={<SignedOutRoute />}>
            <Route element={<AuthPagesLayout />}>
              <Route path={paths.login} element={<LoginPage />} />
              <Route path={paths.signUp} element={<SignUpPage />} />
              <Route path={paths.resetPassword} element={<ResetPasswordPage />} />
            </Route>
          </Route>
          <Route path={paths.filter.types} element={<FilterTypesPage />} />
          <Route element={<ActivityPage />}>
            <Route path={paths.activity(':activityId').base} element={<ActivityIndexPage />} />
            <Route path={paths.activity(':activityId').comments} element={<ActivityCommentsPage />} />
          </Route>
          <Route element={<MenuPageLayout />}>
            <Route path={paths.home} element={<IndexPageWrapper />}>
              <Route index element={<IndexListViewPage />} />
              <Route element={<SignedOutRoute />}>
                <Route path={paths.auth} element={<IndexAuthPage />} />
              </Route>
              <Route path={paths.filter.location} element={<IndexFilterLocationPage />} />
              <Route path={paths.details(':activityId').base} element={<IndexDetailedActivityPage />} />
              <Route path={paths.details(':activityId').comments} element={<IndexDetailedActivityCommentsPage />} />
              <Route element={<SignedOutRoute />}>
                <Route
                  path={paths.details(':activityId').loginAndJoin}
                  element={<IndexDetailedActivityLoginAndJoinPage />}
                />
              </Route>
            </Route>
            <Route element={<SignedInRoute />}>
              <Route element={<MyActivitiesPage />} path={paths.myActivities.base}>
                <Route index element={<MyActivitiesIndexPage />} />
                <Route path={paths.myActivities.details(':activityId')} element={<MyActivitiyDetailsPage />} />
              </Route>
              <Route path={paths.myProfile} element={<MyProfilePage />} />
            </Route>
            <Route path={paths.imprint} element={<ImprintPage />} />
            <Route path={paths.privacy} element={<PrivacyPage />} />
            <Route path={paths.terms} element={<TermsPage />} />
            <Route path={paths.about} element={<AboutPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};
