import React from 'react';

import { icons } from '../Icons';

export type IconKey = keyof typeof icons;
export interface IconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  /** The icon's unique identifier */
  iconKey: IconKey;
  /**
   * Viewbox of the SVG Element
   * @default '0 0 24 24'
   */
  viewBox?: string;
}

export const Icon = ({
  iconKey,
  className = '',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  ...rest
}: IconProps) => {
  const SvgIcon = icons[iconKey];

  if (!SvgIcon) {
    throw new Error(`Icon identifier ${iconKey} is not supported.`);
  }

  return (
    <span role='img' data-testid={`icon-${iconKey}`} className='flex icon'>
      <SvgIcon
        width={width}
        height={height}
        className={`overflow-visible ${className}`}
        preserveAspectRatio='xMinYMid meet'
        viewBox={viewBox}
        {...rest}
      />
    </span>
  );
};
