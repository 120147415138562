import React, { PropsWithChildren } from 'react';

type IconTextButtonProps = {
  icon: React.ReactNode;
  onClick?: () => void;
};

export const IconTextButton: React.FC<PropsWithChildren<IconTextButtonProps>> = ({ icon, children, onClick }) => {
  return (
    <div
      className='relative flex justify-center w-full gap-5 py-1.5 border border-gray-700 text-center rounded-md hover:bg-gray-200 cursor-pointer min-w-[280px]'
      onClick={onClick}
    >
      <div className='absolute h-full top-0 bottom-0 left-3 flex items-center'>{icon}</div>
      <span className='text-base font-semibold pl-10'>{children}</span>
    </div>
  );
};
