import { Button } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomLink } from '@/components/custom-link/custom-link';
import { paths } from '@/routing/paths';
import { Frequency, LocationType, Participant } from '@/types';
import { prettifyDateRange } from '@/utils/prettifier/transformDateToTime';
import { getGermanWeekday, transformDateToWeekdayAndDate } from '@/utils/prettifier/transformDateToWeekdayAndDate';

type ActivityProps = {
  id: string;
  title: string;
  slots?: number;
  participants: Participant[];
  location: Omit<LocationType, 'coordinates'>;
  startsAt: Date;
  endsAt?: Date;
  commentCount: number;
  frequency: Frequency;
};

export const MyActivitiesIndexActivity: React.FC<ActivityProps> = ({
  id,
  title,
  startsAt,
  endsAt,
  location,
  participants,
  slots,
  commentCount,
  frequency,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'my-activities.item' });
  const participantText = `${participants.length} ${slots ? ` / ${slots}` : ''} ${t('participants', {
    count: participants.length > 1 || !!slots ? 2 : 1,
  })}`;

  return (
    <div>
      <div className='flex flex-col py-4 pl-7 pr-3 gap-3 text-base'>
        <div className='flex flex-col pr-3 text-gray-800'>
          <span className='font-semibold'>{title}</span>
          {frequency === Frequency.ONCE && (
            <span>{`${transformDateToWeekdayAndDate(startsAt)} ${prettifyDateRange(startsAt, endsAt)}`}</span>
          )}
          {frequency === Frequency.WEEKLY && (
            <span>{`${t('weekly', { day: getGermanWeekday(startsAt) })} ${prettifyDateRange(startsAt, endsAt)}`}</span>
          )}
          <span>{location.description}</span>
          <span>{participantText}</span>
        </div>
        <div className='flex flex-row justify-between items-center'>
          <CustomLink to={{ pathname: paths.myActivities.details(id) }}>
            <Button variant='outlined'>{t('buttons.more')}</Button>
          </CustomLink>
          <span className='text-base'>{t('comments', { count: commentCount })}</span>
        </div>
      </div>
    </div>
  );
};
