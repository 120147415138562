import { client } from '@/api/client';

import { replacePathVariables } from './utils/replacePathVariables';

export const postRequest = async <TResponse>(
  path: string,
  pathVariables?: unknown,
  queryParams?: unknown,
  bodyData?: unknown,
  signal?: AbortSignal,
): Promise<TResponse> => {
  const pathWithVariables = replacePathVariables(path, pathVariables);

  return new Promise((resolve, reject) => {
    client
      .post(pathWithVariables, bodyData, { params: queryParams, signal })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
