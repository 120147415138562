import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsDE from '../lang/de.json';

export const resources = {
  de: {
    translation: translationsDE,
  },
};

i18next.use(initReactI18next).init({
  lng: 'de',
  supportedLngs: ['de'],
  resources,
});
