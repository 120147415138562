import { SportType } from '@/types';

export const isSportType = (sportType: string): sportType is SportType => {
  return Object.values(SportType).includes(sportType as SportType);
};

export const convertStringToSportType = (sportType: string): SportType => {
  if (isSportType(sportType)) {
    return sportType;
  }

  throw new Error('Unknown sport type');
};
