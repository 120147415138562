import Cookies from 'js-cookie';

import { StorageStrategy } from './StorageStrategy';

const isLocalhost = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

export class CookieStorage implements StorageStrategy {
  private readonly options = {
    path: '/',
    expires: 365,
    domain: window.location.hostname,
    secure: !isLocalhost(), // we expect all environments except for localhost to run HTTPS
    sameSite: 'strict',
  } as const;

  setItem(key: string, value: string): string | undefined {
    Cookies.set(key, value, this.options);
    return Cookies.get(key);
  }

  getItem(key: string): string | undefined {
    return Cookies.get(key);
  }

  removeItem(key: string): void {
    return Cookies.remove(key, this.options);
  }
}
