import React, { createContext, PropsWithChildren, useState } from 'react';

import { useTypesFromSearchParams } from '@/hooks/useTypesFromSearchParams';
import { SportType } from '@/types';

export const FilterTypesContext = createContext<
  { types?: SportType[]; setTypes: (types: SportType[]) => void } | undefined
>({
  setTypes: () => {
    throw new Error('FilterTypesContext not initialized');
  },
});

export const FilterTypesContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const searchParamTypes = useTypesFromSearchParams();

  const [types, setTypes] = useState<SportType[] | undefined>(searchParamTypes);

  return <FilterTypesContext.Provider value={{ setTypes, types }}>{children}</FilterTypesContext.Provider>;
};
