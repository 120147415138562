import { useContext } from 'react';

import { BottomSheetContext } from '../bottom-sheet';

export const useBottomSheet = () => {
  const snapTo = useContext(BottomSheetContext)?.snapTo;
  const scrollTo = useContext(BottomSheetContext)?.scrollTo;

  if (!snapTo || !scrollTo) throw new Error('useBottomSheet must be used within a BottomSheetProvider');

  return { snapTo, scrollTo };
};
