import { config } from '@config';
import React, { PropsWithChildren, Suspense, useState } from 'react';
import { MapProps, MapRef } from 'react-map-gl';

import { LoadingOverlay } from '../loading-overlay';

const Map = React.lazy(() => import('react-map-gl'));

const LEIPZIG_COORDINATES = {
  latitude: 51.340199,
  longitude: 12.360103,
  zoom: 12,
};

type LazyLoadedMapProps = {
  mapRef?: React.Ref<MapRef>;
  initialViewState?: MapProps['initialViewState'];
} & Omit<MapProps, 'mapStyle' | 'mapboxAccessToken' | 'style' | 'initialViewState' | 'onIdle' | 'ref'>;

export const LazyLoadedMap: React.FC<PropsWithChildren<LazyLoadedMapProps>> = ({
  children,
  mapRef,
  initialViewState,
  ...props
}) => {
  const [isMapLoading, setIsMapLoading] = useState(true);

  return (
    <>
      <Suspense>
        {isMapLoading && <LoadingOverlay />}
        <Map
          ref={mapRef}
          onIdle={() => {
            setIsMapLoading(false);
          }}
          initialViewState={{
            ...LEIPZIG_COORDINATES,
            ...initialViewState,
          }}
          style={{ width: '100%', height: '100%', position: 'relative' }}
          mapStyle={config.MAP_STYLE}
          mapboxAccessToken={config.MAPBOX_TOKEN}
          {...props}
        >
          {children}
        </Map>
      </Suspense>
    </>
  );
};
