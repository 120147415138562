import './index.css';

import React, { PropsWithChildren, useRef } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';

import { BottomSheetContent } from './content';

type SnapValues = 'max' | 'center' | 'min';
type BottomSheetContextProps = {
  snapTo: (value: SnapValues) => void;
  scrollTo: (top: number) => void;
};

export const BottomSheetContext = React.createContext<BottomSheetContextProps | null>(null);

type CustomBottomSheetProps = {
  /* no props yet */
};

export const CustomBottomSheet: React.FC<PropsWithChildren<CustomBottomSheetProps>> = ({ children }) => {
  const focusRef = useRef<HTMLButtonElement>(null);
  const sheetRef = useRef<BottomSheetRef>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  let sortedSnapPoints: number[] = [];

  const calculateSnapPoints = (maxHeight: number) => {
    if (!contentRef.current?.scrollHeight) {
      return [maxHeight * 0.25, maxHeight * 0.5];
    }

    const maxSnapPoints = Math.min(maxHeight - maxHeight / 10, contentRef.current.scrollHeight + 32);

    const snapPoints = [maxSnapPoints];

    if (maxHeight * 0.25 < maxSnapPoints) {
      snapPoints.push(maxHeight / 4);
    }

    if (maxHeight * 0.5 < maxSnapPoints) {
      snapPoints.push(maxHeight * 0.5);
    }

    sortedSnapPoints = snapPoints.sort();

    return snapPoints;
  };

  const snapTo = (value: SnapValues) => {
    let index = 0;
    if (value === 'max') {
      index = sortedSnapPoints.length - 1;
    }

    if (value === 'center') {
      index = Math.floor(sortedSnapPoints.length / 2);
    }
    // eslint-disable-next-line security/detect-object-injection
    if (!sortedSnapPoints[index]) {
      return;
    }

    // eslint-disable-next-line security/detect-object-injection
    sheetRef.current?.snapTo(sortedSnapPoints[index]);
  };

  const scrollTo = (top: number) => {
    // NOTE: This is a SUPER HACKY solution but haven't found a better one yet
    contentRef.current?.parentElement?.parentElement?.scrollTo({ top });
  };

  return (
    <div className='h-screen'>
      <BottomSheet
        blocking={false}
        open={true}
        skipInitialTransition
        ref={sheetRef}
        initialFocusRef={focusRef}
        defaultSnap={({ maxHeight }) => maxHeight / 4}
        snapPoints={({ maxHeight }) => calculateSnapPoints(maxHeight)}
        expandOnContentDrag={true}
        className='overflow-visible'
      >
        <BottomSheetContent ref={contentRef} id='bottom-sheet-root'>
          <BottomSheetContext.Provider value={{ snapTo, scrollTo }}>{children}</BottomSheetContext.Provider>
        </BottomSheetContent>
      </BottomSheet>
    </div>
  );
};
