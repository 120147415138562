import { useLocation } from 'react-router-dom';

import { Logger } from '@/lib/logs/logger';
import { SportType } from '@/types';
import { isSportType } from '@/utils/converter/convertStringToSportType';

export const useTypesFromSearchParams = (): undefined | SportType[] => {
  const location = useLocation();
  const typeString = new URLSearchParams(location.search).get('types');
  const types = typeString?.split(',');
  let filteredTypes: SportType[] | undefined;
  if (types) {
    // NOTE: the logic of the forEach is not part of the filter function below because that's the only way typescript can infer the type of the array
    types.forEach((type) => {
      if (!isSportType(type)) Logger.warnOnce(`filter parametes contains invalid type: ${type}`);
    });

    filteredTypes = types.filter(isSportType);
  }

  return filteredTypes;
};
