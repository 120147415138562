import { Avatar, AvatarImage, AvatarText, Icon, IconButton, TextArea } from '@component-library';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { useGetActivityCommentsById, usePostActivityCommentsById } from '@/api/resources/activity';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';

import { Comment, CommentInfo, CommentText } from './comment/comment';

type Inputs = {
  content: string;
};

type CommentsProps = {
  activityId: string;
};

export const Comments: React.FC<CommentsProps> = ({ activityId }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.comments' });
  const { track } = useAnalytics();
  const { state } = useAuth();
  const isSignedIn = state === 'SIGNED_IN';
  const { handleSubmit, control, reset, formState } = useForm<Inputs>({ defaultValues: { content: '' } });
  const { fieldState, field } = useController({
    control,
    name: 'content',
    rules: { required: t('form.validation.required') },
  });

  const { data: commentData, status, refetch } = useGetActivityCommentsById({ pathVariables: { activityId } });

  const { mutateAsync } = usePostActivityCommentsById();

  const onSubmit = (data: Inputs) => {
    track.event({ name: AnalyticsEvents.SEND_COMMENT, params: { activityId, content: data.content } });
    const promise = mutateAsync({ bodyData: { content: data.content }, pathVariables: { activityId } })
      .then(() => {
        reset();
        refetch();
      })
      .catch((e) => {
        if (e instanceof Error) {
          Logger.error(`An unexpected error occured while submitting a new comment: "${e.message}"`);
        }
      });

    toast.promise(promise, {
      loading: '...',
      success: t('form.success'),
      error: t('form.error'),
    });
  };

  if (status !== 'success') {
    return (
      <div className='flex flex-col w-full animate-pulse gap-2 mb-8'>
        <div className='flex flex-row w-full gap-2 items-center'>
          <div className='flex-grow h-16 bg-gray-300 rounded-md' />
          <div className='w-8 h-8 bg-gray-300 rounded-full'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-stretch gap-3'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-row gap-2 items-center w-full mb-1'>
          <TextArea
            disabled={!isSignedIn}
            className={twMerge('w-full')}
            InputProps={{ className: 'resize-none' }}
            placeholder={isSignedIn ? t('placeholder.signed-in') : t('placeholder.signed-out')}
            rows={2}
            {...field}
            error={fieldState.error?.message}
          />
          <IconButton disabled={!isSignedIn || !formState.isValid || formState.isSubmitting}>
            <Icon iconKey='send' />
          </IconButton>
        </div>
      </form>
      <>
        {commentData.data.map((comment) => (
          <React.Fragment key={comment.id}>
            <Comment
              avatar={
                <Avatar>
                  {comment.author.profilePictureUrl ? (
                    <AvatarImage
                      alt={comment.author.firstName ?? 'Unknown Person'}
                      src={comment.author.profilePictureUrl}
                    />
                  ) : (
                    <AvatarText>{comment.author.firstName?.charAt(0) ?? 'U'}</AvatarText>
                  )}
                </Avatar>
              }
            >
              <CommentText>{comment.content}</CommentText>
              <CommentInfo>
                {`
                ${comment.author.firstName ?? t('unknown')}  ${t('prefixdate')}
                ${new Date(comment.createdAt).toLocaleDateString('de')} ${t('prefixtime')}
                ${new Date(comment.createdAt).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' })}`}
              </CommentInfo>
            </Comment>
          </React.Fragment>
        ))}
        {commentData.data.length === 0 && (
          <div className='text-center mt-3'>
            <span className='text-lg text-gray-500'>{t('empty')}</span>
          </div>
        )}
      </>
    </div>
  );
};
