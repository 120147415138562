import { PostHogProvider } from 'posthog-js/react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { SportyAuthenticationProvider } from '@/lib/auth';

import { CookieConsentHandler } from './components/cookie-consent-handler';
import { ErrorBoundary } from './components/error-boundary';
import { AnalyticsContextProvider } from './contexts/analytics-context';
import { FilterTypesContextProvider } from './contexts/filter-types-context';
import { PageRoutes } from './routing/PageRoutes';

function App() {
  const queryClient = new QueryClient();

  return (
    <ErrorBoundary>
      <PostHogProvider
        apiKey={process.env.VITE_PUBLIC_POSTHOG_KEY}
        options={{ api_host: process.env.VITE_PUBLIC_POSTHOG_HOST, autocapture: false, persistence: 'memory' }}
      >
        <QueryClientProvider client={queryClient}>
          <SportyAuthenticationProvider>
            <BrowserRouter>
              <FilterTypesContextProvider>
                <AnalyticsContextProvider>
                  <CookieConsentHandler>
                    <Toaster />
                    <PageRoutes />
                  </CookieConsentHandler>
                </AnalyticsContextProvider>
              </FilterTypesContextProvider>
            </BrowserRouter>
          </SportyAuthenticationProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </ErrorBoundary>
  );
}

export default App;
