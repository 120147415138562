import { Drawer, Icon, Logo } from '@component-library';
import { ArrowLeftOnRectangleIcon, PlusIcon, UserIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import React, { FC, PropsWithChildren, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useAuth } from '@/lib/auth';
import { paths } from '@/routing/paths';

import { CustomLink, CustomLinkProps } from '../custom-link/custom-link';

type MenuItemProps = {
  onClick?: () => void;
  withBorder?: boolean;
  LinkProps?: CustomLinkProps;
};

const MenuItem: FC<PropsWithChildren<MenuItemProps>> = ({ children, withBorder = false, LinkProps, onClick }) => {
  const { pathname } = useLocation();

  const isActive = pathname === LinkProps?.to.pathname;

  const item = (
    <div
      className={twMerge(
        'w-full flex flex-row items-center text-gray-600 px-5 py-4 cursor-pointer hover:bg-gray-200',
        withBorder && 'border-t border-b border-gray-600',
        isActive && 'bg-indigo-50',
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );

  if (LinkProps) {
    return <CustomLink {...LinkProps}>{item}</CustomLink>;
  }

  return item;
};

export const MenuItemText: React.FC<PropsWithChildren> = ({ children }) => {
  return <span className='ml-8'>{children}</span>;
};

type MenuProps = {
  BurgerIconProps?: {
    className?: string;
  };
};

export const Menu: React.FC<MenuProps> = ({ BurgerIconProps }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'globals' });
  const [open, setOpen] = useState(false);
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { state, signOut } = useAuth();
  const isSignedIn = state === 'SIGNED_IN';

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleAddActivityClick = () => {
    navigate({ pathname: paths.addActivity.stepOne }, { state: { previousPath: location.pathname } });
  };

  const handleSignOutClick = async () => {
    toast.success(t('menu.logout.success'));
    await signOut();
    closeDrawer();
  };

  return (
    <Drawer open={open} handleOpen={openDrawer} handleClose={closeDrawer} IconProps={BurgerIconProps}>
      <div className='flex flex-col gap-3 items-center w-full pt-10 min-h-full overflow-scroll'>
        <CustomLink className='w-[150px] h-[65px]' to={{ pathname: paths.home }}>
          <Logo variant='large' />
        </CustomLink>
        <div className='flex flex-col justify-between flex-grow gap-5'>
          <div className='w-full flex flex-col mt-16'>
            {isSignedIn ? (
              <>
                <MenuItem
                  LinkProps={{ to: { pathname: paths.myProfile }, state: { previousPath: window.location.pathname } }}
                  onClick={closeDrawer}
                >
                  <UserIcon width={24} height={24} />
                  <MenuItemText>{t('menu.items.my-profile')}</MenuItemText>
                </MenuItem>
                <MenuItem LinkProps={{ to: { pathname: paths.home } }} onClick={closeDrawer}>
                  <Icon iconKey='sporty-club' />
                  <MenuItemText>{t('menu.items.explore')}</MenuItemText>
                </MenuItem>
                <MenuItem LinkProps={{ to: { pathname: paths.myActivities.base } }} onClick={closeDrawer}>
                  <Icon iconKey='sports' />
                  <MenuItemText>{t('menu.items.my-activities')}</MenuItemText>
                </MenuItem>
                <MenuItem onClick={handleAddActivityClick}>
                  <PlusIcon width={24} height={24} />
                  <MenuItemText>{t('menu.items.add-activity')}</MenuItemText>
                </MenuItem>
                <MenuItem onClick={handleSignOutClick}>
                  <ArrowLeftOnRectangleIcon width={24} height={24} />
                  <MenuItemText>{t('menu.items.logout')}</MenuItemText>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem LinkProps={{ to: { pathname: paths.auth } }} onClick={closeDrawer} withBorder>
                  <UserPlusIcon width={24} height={24} />
                  <MenuItemText>{t('menu.items.login')}</MenuItemText>
                </MenuItem>
                <MenuItem LinkProps={{ to: { pathname: paths.home } }} onClick={closeDrawer}>
                  <Icon iconKey='sporty-club' />
                  <MenuItemText>{t('menu.items.explore')}</MenuItemText>
                </MenuItem>
              </>
            )}
          </div>
          <div className='justify-center flex flex-wrap-reverse text-gray-500 mb-4 w-full text-center'>
            <CustomLink to={{ pathname: paths.about }} onClick={closeDrawer} className='mr-3'>
              {t('menu.items.about')}
            </CustomLink>
            <CustomLink to={{ pathname: paths.imprint }} onClick={closeDrawer} className='mr-3'>
              {t('legal.imprint')}
            </CustomLink>
            <CustomLink to={{ pathname: paths.privacy }} onClick={closeDrawer} className='mr-3'>
              {t('legal.privacy')}
            </CustomLink>
            <CustomLink to={{ pathname: paths.terms }} onClick={closeDrawer} className='mr-3'>
              {t('legal.terms')}
            </CustomLink>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
