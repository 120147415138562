import React from 'react';
import { Outlet } from 'react-router-dom';

import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { UserContextProvider } from '@/contexts/user-context';
import { useAuth } from '@/lib/auth';

import { paths } from './paths';

export const SignedInRoute: React.FC = () => {
  const auth = useAuth();

  if (auth.state !== 'SIGNED_IN') {
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }
  const { user } = auth;

  return (
    <UserContextProvider value={{ user }}>
      <Outlet />
    </UserContextProvider>
  );
};
