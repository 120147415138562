import React, { PropsWithChildren } from 'react';

export const InfoBoxIcon: React.FC<PropsWithChildren> = ({ children }) => {
  return <span className='mr-12 text-gray-600'>{children}</span>;
};

export const InfoBoxContent: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className='flex flex-col text-gray-900 text-base max-w-[60vw]'>{children}</div>;
};

type InfoBoxProps = {
  onClick?: () => void;
};

export const InfoBox: React.FC<PropsWithChildren<InfoBoxProps>> = ({ children, onClick }) => {
  return (
    <div className='flex flex-row items-center' onClick={onClick}>
      {children}
    </div>
  );
};
