import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type CircleButtonProps = {
  onClick: () => void;
};

const CircleButton: React.FC<PropsWithChildren<CircleButtonProps>> = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className='flex items-center justify-center w-12 h-12 rounded-full bg-gray-200 text-gray-500 z-10 hover:bg-gray-300 cursor-pointer'
    >
      {children}
    </div>
  );
};

type NumberPickerProps = {
  value: number;
  unit?: string;
  onChange: (value: number) => void;
  withInfinity?: boolean;
  error?: string;
  min?: number;
};

export const NumberPicker: React.FC<NumberPickerProps> = ({
  value,
  unit,
  onChange,
  withInfinity = false,
  min = 0,
  error,
}) => {
  const handleDecreament = () => {
    if (value === min) return;
    if (value === Infinity) {
      onChange(min);
      return;
    }
    onChange(value - 1);
  };

  const handleIncreament = () => {
    if (value === Infinity) {
      onChange(min);
      return;
    }
    onChange(value + 1);
  };

  const handleInfinity = () => {
    onChange(Infinity);
  };

  return (
    <div>
      <div className={twMerge('flex flex-row', withInfinity && 'gap-3')}>
        <div className='flex flex-row -space-x-7 flex-grow'>
          <CircleButton onClick={handleDecreament}>
            <MinusIcon width={34} height={34} />
          </CircleButton>
          <div
            className={twMerge(
              'flex items-center justify-center flex-grow border border-gray-400 bg-white my-[1px] z-0',
              error && 'border-red-600',
            )}
          >
            <span className='text-3xl text-gray-700'>
              {value === Infinity ? <>&#8734;</> : <>{unit ? `${value} ${unit}` : value}</>}
            </span>
          </div>
          <CircleButton onClick={handleIncreament}>
            <PlusIcon width={34} height={34} />
          </CircleButton>
        </div>

        {withInfinity && (
          <CircleButton onClick={handleInfinity}>
            <span className='text-5xl text-center leading-4 pb-2'>&#8734;</span>
          </CircleButton>
        )}
      </div>
      {error && <p className='text-red-600 text-sm mt-1'>{error}</p>}
    </div>
  );
};
