import { Icon, IconTextButton } from '@component-library';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { CustomLink } from '@/components/custom-link/custom-link';
import { LoadingOverlay } from '@/components/loading-overlay';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useJoinActivitiyAfterAuth } from '@/hooks/useJoinActivitiyAfterAuth';
import { useAuth } from '@/lib/auth';
import { AlreadyLinkedToOtherAuthProviderException } from '@/lib/auth/exceptions/already-linked-to-other-auth-provider.exception';
import { paths } from '@/routing/paths';
import { AuthMethod } from '@/utils/auth';
import { isWebViewBrowser } from '@/utils/isWebViewBrowser';

import { ReactComponent as IconFacebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as IconGoogle } from '../../assets/icons/google.svg';
import { WebviewAuth } from './webview-auth';

type AuthProps = {
  joinActivityId?: string;
  previousPath?: string;
  onSuccessPath?: string;
};

export const Auth: React.FC<AuthProps> = ({ joinActivityId, previousPath, onSuccessPath }) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const { socialLogin } = useAuth();
  const navigate = useCustomNavigate();
  const { handleJoinActivity } = useJoinActivitiyAfterAuth();

  const handleSignIn = async (method: AuthMethod) => {
    setIsSigningIn(true);
    socialLogin(method)
      .then(() => {
        if (joinActivityId) {
          handleJoinActivity(joinActivityId).then(() => {
            if (onSuccessPath) navigate({ pathname: onSuccessPath });
          });
        } else {
          navigate({ pathname: paths.home });
        }
      })
      .catch((e) => {
        if (e instanceof AlreadyLinkedToOtherAuthProviderException) {
          toast.error(t('home.auth.errors.authentication.other-provider-already-linked'));
        } else {
          toast.error(t('globals.errors.unexpected'));
        }
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  const handleOnGoogleClick = async () => {
    track.event({
      name: AnalyticsEvents.SOCIAL_AUTH,
      params: { provider: 'google' },
    });
    await handleSignIn(AuthMethod.GOOGLE);
  };

  const handleOnFacebookClick = async () => {
    track.event({
      name: AnalyticsEvents.SOCIAL_AUTH,
      params: { provider: 'facebook' },
    });
    await handleSignIn(AuthMethod.FACEBOOK);
  };

  if (isWebViewBrowser()) {
    return <WebviewAuth joinActivityId={joinActivityId} />;
  }

  return (
    <>
      {isSigningIn && <LoadingOverlay />}
      <div className='flex flex-col gap-4'>
        <IconTextButton icon={<IconGoogle />} onClick={handleOnGoogleClick}>
          {t('home.auth.social.google')}
        </IconTextButton>
        <IconTextButton icon={<IconFacebook />} onClick={handleOnFacebookClick}>
          {t('home.auth.social.facebook')}
        </IconTextButton>
        <CustomLink
          to={{ pathname: paths.login, search: joinActivityId ? `?join-activity-id=${joinActivityId}` : undefined }}
          state={{
            previousPath,
            onSuccessPath,
          }}
        >
          <IconTextButton icon={<Icon iconKey='email-and-at' />}>{t('home.auth.email')}</IconTextButton>
        </CustomLink>
      </div>
    </>
  );
};
