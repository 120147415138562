import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

import { useAddActivity } from '../../context/hooks/use-form-provider';
import { StepOneFormData } from '../../form-types';
import { AddActivityFormLayout } from '../../layouts/add-activity-form.layout';
import { AddActivityStepOneDate } from './components/date/date';
import { AddActivityStepOneDuration } from './components/duration';
import { AddActivityStepOneSportType } from './components/sport-type';

const determineNextValidHourDate = () => {
  const date = new Date();
  date.setHours(date.getHours() + 3);
  date.setMinutes(0);
  return date;
};

export const AddActivityStepOnePage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity' });
  const navigate = useCustomNavigate();
  const { data, updateData } = useAddActivity();
  const { control, handleSubmit } = useForm<StepOneFormData>({
    defaultValues: {
      sportType: data.sportType,
      startDate: data.startDate ?? determineNextValidHourDate(),
      frequency: data.frequency,
      repeatUntil: data.repeatUntil,
      duration: data.duration,
    },
  });

  const handleNext = (formData: StepOneFormData) => {
    updateData(formData);

    navigate({ pathname: paths.addActivity.stepTwo });
  };

  return (
    <AddActivityFormLayout
      onSubmit={handleSubmit(handleNext)}
      activeStep='one'
      FooterProps={{ rightButton: { label: t('layout.footer.buttons.continue') } }}
    >
      <div className='mx-8 sm:mx-auto flex flex-col gap-y-11 max-w-sm'>
        <AddActivityStepOneSportType control={control} />
        <AddActivityStepOneDate control={control} />
        <AddActivityStepOneDuration control={control} />
      </div>
    </AddActivityFormLayout>
  );
};
