import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { Icon, IconButton } from '..';

export type CloseableNavbarProps = {
  className?: string;
  onClose: () => void;
};

export const CloseableNavbar: React.FC<PropsWithChildren<CloseableNavbarProps>> = ({
  children,
  className,
  onClose,
}) => {
  return (
    <div
      className={twMerge('w-full flex flex-row items-center justify-between px-2.5 py-3 shadow-md bg-white', className)}
    >
      {children}
      <IconButton onClick={onClose}>
        <Icon className='text-gray-700' iconKey='x' />
      </IconButton>
    </div>
  );
};
