import { BaseActivity } from '@/types';

import { Logger } from '../logs/logger';
import { BaseActivityResponse, transformBaseActivityResponse } from './transformBaseActivityResponse';

type ActivitiesResponse = BaseActivityResponse[];

export const transformActivitiesResponse = (data: ActivitiesResponse): BaseActivity[] => {
  return data.reduce((acc: BaseActivity[], responseActivity) => {
    try {
      const activity: BaseActivity = {
        ...transformBaseActivityResponse(responseActivity),
      };

      return [...acc, activity];
    } catch (e) {
      Logger.error(`Could not transform list activity ${responseActivity.id}: ${e}`);
      return acc;
    }
  }, []);
};
