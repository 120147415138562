import { useEffect } from 'react';

import { useGetActivities } from '@/api/resources/activity';
import { useFilterTypes } from '@/contexts/hooks/useFilterTypes';
import { Logger } from '@/lib/logs/logger';
import { transformActivitiesResponse } from '@/lib/transformer/transformActivitiesResponse';

export const useActivities = () => {
  const filterTypes = useFilterTypes();

  const { data, status, ...rest } = useGetActivities(undefined, { queryParams: { types: filterTypes.types } });

  useEffect(() => {
    if (status === 'error') {
      Logger.errorOnce(`useActivities failed: ${rest.error?.message}`);
    }
  }, [status]);

  if (status !== 'success') {
    return { data: undefined, status, ...rest };
  }

  return { data: transformActivitiesResponse(data.data), status, ...rest };
};
