import { Icon, IconTextButton } from '@component-library';
import { config } from '@config';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useBottomSheet } from '@/components/bottom-sheet/hooks/useBottomSheet';
import { FullWidthLayout } from '@/pages/index/layouts/full-width.layout';
import { paths } from '@/routing/paths';

export const IndexFilterLocationPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'home.filter' });
  const { state } = useLocation();
  const previousPath = useMemo(() => state?.previousPath || paths.home, []);

  const { snapTo } = useBottomSheet();
  useEffect(() => {
    snapTo('max');
  }, [snapTo]);

  return (
    <FullWidthLayout title={`${t('title')} - ${t('location.title')}`} backTo={{ pathname: previousPath }}>
      <div className='p-3 pb-0 flex flex-col gap-10 mt-3'>
        <p className='whitespace-pre-line text-center text-gray-800 text-lg'>{t('location.text')}</p>
        <a href={`mailto:${config.CONTACT.mail}`}>
          <IconTextButton icon={<Icon className='text-logo' iconKey='sporty-club' />}>
            {t('location.button')}
          </IconTextButton>
        </a>
      </div>
    </FullWidthLayout>
  );
};
