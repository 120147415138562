import { useEffect } from 'react';
import { UseBaseQueryOptions } from 'react-query';

import { GetActivityByIdSuccessResponse, useGetActivityById } from '@/api/resources/activity';
import { Logger } from '@/lib/logs/logger';
import { BaseActivity } from '@/types';

import { transformBaseActivityResponse } from '../lib/transformer/transformBaseActivityResponse';

export type DetailedActivity = BaseActivity & {
  description: string;
  skillLevel?: string;
  price: number;
  createdBy: string;
};

export const transformData = (responseActivity: GetActivityByIdSuccessResponse): DetailedActivity => {
  const activity: DetailedActivity = {
    ...transformBaseActivityResponse(responseActivity),
    price: responseActivity.price,
    description: responseActivity.description,
    skillLevel: responseActivity.skillLevel,
    createdBy: responseActivity.createdBy,
  };

  return activity;
};

type SuccessResponse = { data: DetailedActivity; status: 'success' } & Omit<
  ReturnType<typeof useGetActivityById>,
  'status' | 'data'
>;

type ErrorResponse = { data: undefined; status: 'error' } & Omit<
  ReturnType<typeof useGetActivityById>,
  'status' | 'data'
>;

type LoadingResponse = { data: undefined; status: 'loading' } & Omit<
  ReturnType<typeof useGetActivityById>,
  'status' | 'data'
>;

type IdleResponse = { data: undefined; status: 'idle' } & Omit<
  ReturnType<typeof useGetActivityById>,
  'status' | 'data'
>;

export type UseActivityByIdResponse = SuccessResponse | LoadingResponse | ErrorResponse | IdleResponse;

export const useActivityById = (
  id: string,
  options?: UseBaseQueryOptions<GetActivityByIdSuccessResponse, Error>,
): UseActivityByIdResponse => {
  const { data, status, refetch, ...rest } = useGetActivityById({ pathVariables: { id } }, options);

  useEffect(() => {
    if (status === 'error') {
      Logger.errorOnce(`Could not fetch activity ${id}: ${rest.error?.message}`);
    }
  }, [status]);

  if (status !== 'success') {
    return { data: undefined, status, refetch, ...rest };
  }

  try {
    return { data: transformData(data), status, refetch, ...rest };
  } catch (e) {
    if (e instanceof Error) {
      Logger.error(e.message);
    } else {
      Logger.error('Unknown error transforming activity');
    }
    return { data: undefined, status: 'error', refetch, ...rest };
  }
};
