import React, { useEffect, useRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MapLayerMouseEvent, MapRef, Marker } from 'react-map-gl';
import { twMerge } from 'tailwind-merge';

import { LazyLoadedMap } from '@/components/map/lazy-loaded-map';
import { StepTwoFormData } from '@/pages/add-activity/form-types';

import { ReactComponent as CustomMarker } from '../assets/sc-marker.svg';

type AddActivityStepTwoMapProps = {
  control: Control<StepTwoFormData>;
};

export const AddActivityStepTwoMap: React.FC<AddActivityStepTwoMapProps> = ({ control }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-two.coordinates' });
  const { field, fieldState } = useController({
    name: 'coordinates',
    rules: {
      required: true,
    },
    control,
  });
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (!mapRef.current || !field.value) {
      return;
    }

    mapRef.current.flyTo({
      center: {
        lat: field.value.latitude,
        lng: field.value.longitude,
      },
      speed: 0.25,
    });
  }, [field.value, mapRef.current]);

  const handleClick = (event: MapLayerMouseEvent) => {
    const { lat, lng } = event.lngLat;
    field.onChange({ latitude: lat, longitude: lng });
  };

  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800 mb-2'>{`${t('label')} *`}</label>
      <div className={twMerge('relative w-full h-72 border border-gray-400', fieldState.error && 'border-red-600')}>
        <LazyLoadedMap onClick={handleClick} mapRef={mapRef} initialViewState={{ ...(field.value ? field.value : {}) }}>
          {field.value && (
            <Marker latitude={field.value.latitude} longitude={field.value.longitude} anchor='center' offset={[0, -25]}>
              <CustomMarker />
            </Marker>
          )}
        </LazyLoadedMap>
      </div>
      <p className={twMerge('mt-0.5 text-base text-gray-500 text-center', fieldState.error && 'text-red-600')}>
        {t('hint')}
      </p>
    </div>
  );
};
