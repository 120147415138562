import { useApiPostRequest } from '../hooks/useApiPostRequest';

const POST_EVENT_PATH = '/events';

export type PostEventPathVariables = undefined;

export type PostEventQueryParams = undefined;

export type PostEventBodyData = {
  name: string;
  data: Record<string, string>;
  affiliateId?: string;
  browserStorageId?: string;
  sessionId?: string;
  userId?: string;
};

type PostEventSuccessResponse = undefined;
type PostEventErrorResponse = Error;

export const usePostEvent = () => {
  return useApiPostRequest<
    PostEventPathVariables,
    PostEventQueryParams,
    PostEventBodyData,
    PostEventSuccessResponse,
    PostEventErrorResponse
  >(POST_EVENT_PATH);
};
