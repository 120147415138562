import { ReactComponent as BadmintonIcon } from '@assets/icons/sports/badminton.svg';
import { ReactComponent as BilliardsIcon } from '@assets/icons/sports/billiards.svg';
import { ReactComponent as BoulderIcon } from '@assets/icons/sports/boulder.svg';
import { ReactComponent as CyclingIcon } from '@assets/icons/sports/cycling.svg';
import { ReactComponent as DanceIcon } from '@assets/icons/sports/dance.svg';
import { ReactComponent as FlunkyballIcon } from '@assets/icons/sports/flunkyball.svg';
import { ReactComponent as FrisbeeIcon } from '@assets/icons/sports/frisbee.svg';
import { ReactComponent as GymIcon } from '@assets/icons/sports/gym.svg';
import { ReactComponent as LongboardIcon } from '@assets/icons/sports/longboard.svg';
import { ReactComponent as OutdoorGymIcon } from '@assets/icons/sports/outdoor-gym.svg';
import { ReactComponent as RunningIcon } from '@assets/icons/sports/running.svg';
import { ReactComponent as SkateboardIcon } from '@assets/icons/sports/skateboard.svg';
import { ReactComponent as SoccerIcon } from '@assets/icons/sports/soccer.svg';
import { ReactComponent as SpikeballIcon } from '@assets/icons/sports/spikeball.svg';
import { ReactComponent as SquashIcon } from '@assets/icons/sports/squash.svg';
import { ReactComponent as SwimIcon } from '@assets/icons/sports/swim.svg';
import { ReactComponent as TableTennisIcon } from '@assets/icons/sports/table-tennis.svg';
import { ReactComponent as TennisIcon } from '@assets/icons/sports/tennis.svg';
import { ReactComponent as VolleyballIcon } from '@assets/icons/sports/volleyball.svg';
import { ReactComponent as YogaIcon } from '@assets/icons/sports/yoga.svg';
import React from 'react';

import { exhaustiveCheck } from '@/lib/type-guards/exhaustive-check';
import { SportType } from '@/types';

type SportIconProps = {
  size?: number;
  sport: SportType;
};

type SpecificSportsIconProps = Pick<SportIconProps, 'sport'> & {
  size: {
    width: number;
    height: number;
  };
};

const SpecificSportsIcon: React.FC<SpecificSportsIconProps> = ({ sport, size }) => {
  switch (sport) {
    case SportType.BADMINTON:
      return <BadmintonIcon {...size} />;
    case SportType.BILLIARDS:
      return <BilliardsIcon {...size} />;
    case SportType.BOULDERING:
      return <BoulderIcon {...size} />;
    case SportType.CYCLING:
      return <CyclingIcon {...size} />;
    case SportType.DANCE:
      return <DanceIcon {...size} />;
    case SportType.FLUNKYBALL:
      return <FlunkyballIcon {...size} />;
    case SportType.FRISBEE:
      return <FrisbeeIcon {...size} />;
    case SportType.GYM:
      return <GymIcon {...size} />;
    case SportType.LONGBOARDING:
      return <LongboardIcon {...size} />;
    case SportType.OUTDOOR_GYM:
      return <OutdoorGymIcon {...size} />;
    case SportType.RUNNING:
      return <RunningIcon {...size} />;
    case SportType.SKATEBOARDING:
      return <SkateboardIcon {...size} />;
    case SportType.SOCCER:
      return <SoccerIcon {...size} />;
    case SportType.SPIKEBALL:
      return <SpikeballIcon {...size} />;
    case SportType.SWIMMING:
      return <SwimIcon {...size} />;
    case SportType.SQUASH:
      return <SquashIcon {...size} />;
    case SportType.TABLE_TENNIS:
      return <TableTennisIcon {...size} />;
    case SportType.TENNIS:
      return <TennisIcon {...size} />;
    case SportType.VOLLEYBALL:
      return <VolleyballIcon {...size} />;
    case SportType.YOGA:
      return <YogaIcon {...size} />;
    default:
      return exhaustiveCheck(sport);
  }
};

export const SportsIcon: React.FC<SportIconProps> = ({ sport, size }) => {
  return (
    <SpecificSportsIcon
      sport={sport}
      size={{
        width: size ?? 24,
        height: size ?? 24,
      }}
    />
  );
};
