import { Button, Checkbox, TextInput } from '@component-library';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useJoinActivitiyAfterAuth } from '@/hooks/useJoinActivitiyAfterAuth';
import { useAuth } from '@/lib/auth';
import { EmailAlreadyInUseException } from '@/lib/auth/exceptions/email-already-in-use.exception';
import { validateEmail } from '@/lib/validators/validateEmail';
import { validatePassword } from '@/lib/validators/validatePassword';
import { paths } from '@/routing/paths';

type FormData = {
  firstName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  ofLegalAge: boolean;
  termsAndConditions: boolean;
};

export const SignUpPage: React.FC = () => {
  const { state } = useLocation();
  const previousPath = useMemo(() => state?.previousPath || paths.home, []);
  const onSuccessPath = state?.onSuccessPath;
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const joinActivityId = searchParams.get('join-activity-id');
  const { handleJoinActivity } = useJoinActivitiyAfterAuth();

  const { register, formState, handleSubmit, watch, setError } = useForm<FormData>();
  const { emailPasswordSignUp } = useAuth();

  const password = watch('password');

  const [signUpError, setSignUpError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleSignUp = (data: FormData) => {
    setLoading(true);
    setSignUpError(undefined);

    emailPasswordSignUp({
      email: data.email,
      firstName: data.firstName,
      password: data.password,
    })
      .then(() => {
        if (joinActivityId) {
          handleJoinActivity(joinActivityId);
          navigate({ pathname: onSuccessPath });
        } else {
          navigate({ pathname: previousPath });
        }
      })
      .catch((error) => {
        if (error instanceof EmailAlreadyInUseException) {
          setError('email', { message: t('signUp.errors.emailAlreadyUsed') });
        } else {
          setSignUpError(t('globals.errors.unexpected'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSignUp)} className='flex flex-col gap-4 max-w-lg w-full'>
        <span className='text-3xl ml-2 mb-2'>{t('signUp.title')}</span>
        <div className='flex flex-col'>
          <TextInput
            {...register('firstName', {
              required: t('globals.validation.required'),
            })}
            error={formState.errors.firstName?.message}
            label={`${t('signUp.labels.firstName')} *`}
            placeholder='Max'
          />
          <p className='text-base text-center mt-2'>{t('signUp.firstNameHint')}</p>
        </div>
        <TextInput
          {...register('email', {
            required: t('globals.validation.required'),
            validate: (value) => validateEmail(value) || t('globals.validation.email'),
          })}
          error={formState.errors.email?.message}
          label={`${t('signUp.labels.email')} *`}
          placeholder='maxmustermann@gmail.com'
          type='email'
        />
        <TextInput
          {...register('password', {
            required: t('globals.validation.required'),
            validate: (value) => validatePassword(value) || t('globals.validation.password'),
          })}
          error={formState.errors.password?.message}
          label={`${t('signUp.labels.password')} *`}
          placeholder='*******'
          type='password'
        />
        <TextInput
          {...register('passwordConfirmation', {
            required: t('globals.validation.required'),
            validate: (value) => password === value || t('globals.validation.passwordConfirmation'),
          })}
          error={formState.errors.passwordConfirmation?.message}
          label={`${t('signUp.labels.passwordConfirmation')} *`}
          placeholder='*******'
          type='password'
        />
        <Checkbox
          {...register('ofLegalAge', { required: true })}
          hasError={!!formState.errors.ofLegalAge}
          className='mt-4'
          id='checkbox-of-legal-age'
        >
          {t('signUp.labels.ofLegalAge')} *
        </Checkbox>
        <Checkbox
          {...register('termsAndConditions', { required: true })}
          hasError={!!formState.errors.termsAndConditions}
          id='checkbox-terms-and-conditions'
        >
          <Trans
            i18nKey='signUp.labels.termsAndConditions'
            components={{
              Link: <CustomLink target='_blank' to={{ pathname: paths.terms }} className='underline' />,
            }}
          />
          {' *'}
        </Checkbox>
        {signUpError && <p className='ml-1 text-sm text-red-500'>{signUpError}</p>}
        <Button type='submit' className='px-12 mt-5' variant='outlined' isLoading={loading}>
          {t('signUp.labels.submit')}
        </Button>
      </form>
      <div className='mt-12 font-bold text-base text-center'>
        <Trans
          i18nKey='signUp.alreadyHaveAccount'
          components={{
            Link: (
              <CustomLink
                to={{ pathname: paths.login, search: `?join-activity-id=${joinActivityId}` }}
                state={state}
                className='underline'
              />
            ),
          }}
        />
      </div>
    </>
  );
};
