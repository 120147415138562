import React, { PropsWithChildren } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps, Path } from 'react-router-dom';

import { useAnalytics } from '@/contexts/hooks/useAnalytics';

export type CustomLinkProps = Omit<LinkProps, 'to'> & { to: Partial<Path> };

/**
 * This custom link component will add the affiliate id to the link's search params.
 */
export const CustomLink: React.FC<PropsWithChildren<CustomLinkProps>> = ({ to, ...props }) => {
  const { affiliateId } = useAnalytics();

  const urlParams = new URLSearchParams(to.search);
  if (urlParams.has('aid') || !affiliateId) {
    return <Link to={to} {...props} />;
  }

  urlParams.append('aid', affiliateId);

  return <Link to={{ ...to, search: `?${urlParams.toString()}` }} {...props} />;
};
