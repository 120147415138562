import { isAxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { usePatchActivityJoin } from '@/api/resources/activity';
import { Logger } from '@/lib/logs/logger';

export const useJoinActivitiyAfterAuth = () => {
  const { mutateAsync } = usePatchActivityJoin();
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.join-activity' });

  const handleJoinActivity = async (activityId: string) => {
    const promise = new Promise<string>((resolve, reject) => {
      mutateAsync({ pathVariables: { id: activityId } })
        .then(() => {
          resolve(t('success'));
        })
        .catch((e) => {
          if (isAxiosError(e) && e.response?.status === 409 && e.response?.data?.message === 'ALREADY_JOINED') {
            resolve(t('errors.already-joined'));

            return;
          }
          // NOTE: probably show an specific error for activity is already full

          if (e instanceof Error) Logger.errorOnce(e.message);
          reject(new Error(t('errors.unexpected')));
        });
    });

    await toast.promise(promise, {
      loading: '...',
      success: (message: string) => message,
      error: (error: Error) => {
        return error.message;
      },
    });
  };

  return {
    handleJoinActivity,
  };
};
