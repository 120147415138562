import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Navigate, NavigateProps, Path } from 'react-router-dom';

import { useAnalytics } from '@/contexts/hooks/useAnalytics';

import { NavigationState } from '../../../types/router';

type CustomNavigateProps = NavigateProps & {
  to: Partial<Path>;
  state?: NavigationState;
};

export const CustomNavigate: React.FC<CustomNavigateProps> = ({ to, ...props }) => {
  const { affiliateId } = useAnalytics();

  const urlParams = new URLSearchParams(to.search);
  if (urlParams.has('aid') || !affiliateId) {
    return <Navigate to={to} {...props} />;
  }

  urlParams.append('aid', affiliateId);

  const updatedTo = {
    pathname: to.pathname,
    search: `?${urlParams.toString()}`,
    hash: to.hash,
  };

  return <Navigate to={updatedTo} {...props} />;
};
