import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type IconButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
};

export const IconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  onClick,
  children,
  className,
  disabled = false,
}) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (disabled || !onClick) return;
    onClick(e);
  };

  return (
    <button
      onClick={handleOnClick}
      className={twMerge(
        'rounded-full',
        'p-2',
        'cursor-pointer',
        disabled ? 'text-gray-400' : 'hover:bg-gray-100',
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
