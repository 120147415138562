import { Button, Icon } from '@component-library';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePatchActivityJoin } from '@/api/resources/activity';
import { useBottomSheet } from '@/components/bottom-sheet/hooks/useBottomSheet';
import { CustomLink } from '@/components/custom-link/custom-link';
import { DateInfoBox } from '@/components/info-box/date/date';
import { InfoBox, InfoBoxContent, InfoBoxIcon } from '@/components/info-box/info-box';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing/paths';
import { getImageSrcBySportType } from '@/utils/getImageSrcBySportType';

import { useActivityById } from '../../../../hooks/useActivityById';
import { DetailedActivityHeader } from './components/header';
import { IndexDetailedActivityParticipants } from './components/participants/participants';
import { DetailedActivitySkeletonLoader } from './components/skeleton-loader';
import bottomSheetOverwrite from './index.css?inline';

export const IndexDetailedActivityPage: React.FC = () => {
  const { t } = useTranslation();
  const { activityId: activeActivityId } = useParams();
  const { track } = useAnalytics();
  const navigate = useCustomNavigate();
  const { data: detailedActivity, status: statusGetActivity, refetch } = useActivityById(activeActivityId || '', {
    enabled: !!activeActivityId,
  });
  const { user } = useAuth();
  const { mutateAsync, status: statusJoinActivity } = usePatchActivityJoin();

  const userAlreadyParticipates = detailedActivity && detailedActivity.participants.some(({ id }) => id === user?.id);

  const { snapTo, scrollTo } = useBottomSheet();
  const { state: authState } = useAuth();

  const expandBottomSheet = () => {
    snapTo('max');
  };

  const scrollToTop = () => {
    scrollTo(0);
  };

  useEffect(() => {
    if (statusGetActivity === 'error') {
      navigate({ pathname: paths.home });
      Logger.error(`Error while fetching activity with id ${activeActivityId}`);
      toast.error(t('globals.errors.unexpected'));
    }
  }, [statusGetActivity]);

  useEffect(() => {
    if (!detailedActivity) {
      return;
    }
    scrollToTop();
    expandBottomSheet();
  }, [detailedActivity]);

  const handleOnBackClick = () => {
    navigate({ pathname: paths.home });
  };

  if (!activeActivityId) {
    // NOTE: error should be thrown and error boundary should catch it
    Logger.errorOnce('Critical error: activeActivityId is undefined in the detailed activity component.');
    return null;
  }

  if (statusGetActivity !== 'success') {
    return (
      <>
        <style>{bottomSheetOverwrite}</style>
        <DetailedActivitySkeletonLoader />
      </>
    );
  }

  const isActivityBookedOut =
    detailedActivity.slots !== undefined && detailedActivity.slots <= detailedActivity.participants.length;

  const handleJoinButtonClick = () => {
    track.event({
      name: AnalyticsEvents.JOIN_ACTIVITY,
      params: {
        page: 'details-page',
        sportType: detailedActivity.sport,
        activityId: detailedActivity.id,
      },
    });

    if (authState === 'SIGNED_IN') {
      mutateAsync({ pathVariables: { id: activeActivityId } })
        .then(() => {
          toast.success(t('globals.join-activity.success'));
          refetch();
        })
        .catch(() => {
          // NOTE: Show probably a specific error for activity is already booked out
          toast.error(t('globals.errors.unexpected'));
        });
    } else {
      navigate({ pathname: paths.details(activeActivityId).loginAndJoin });
    }
  };

  return (
    <>
      <style>{bottomSheetOverwrite}</style>
      <div className='flex flex-col w-full max-w-[100vw]'>
        <DetailedActivityHeader
          imageSrc={detailedActivity.coverPictureUrl ?? getImageSrcBySportType(detailedActivity.sport)}
          title={detailedActivity.title ?? t(`globals.sportType.${detailedActivity.sport}`)}
        />
        <div className='px-4'>
          <div className='flex flex-col mt-7 items-start gap-4 m-auto max-w-sm'>
            <DateInfoBox
              startsAt={detailedActivity.startsAt}
              endsAt={detailedActivity.endsAt}
              frequency={detailedActivity.frequency}
              lastDate={detailedActivity.lastDate}
            />
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='location-marker' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <span className='break-words'>{detailedActivity.location.description}</span>
              </InfoBoxContent>
            </InfoBox>
            {detailedActivity.skillLevel && (
              <InfoBox>
                <InfoBoxIcon>
                  <Icon iconKey='skill' />
                </InfoBoxIcon>
                <InfoBoxContent>
                  <span className='break-words'>{detailedActivity.skillLevel}</span>
                </InfoBoxContent>
              </InfoBox>
            )}
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='price' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <span>{t('globals.activity.price', { count: detailedActivity.price })}</span>
              </InfoBoxContent>
            </InfoBox>
            {detailedActivity.link && (
              <InfoBox>
                <InfoBoxIcon>
                  <Icon iconKey='globe' />
                </InfoBoxIcon>
                <InfoBoxContent>
                  <a href={detailedActivity.link} target='_blank' rel='noreferrer' className='break-words underline'>
                    {detailedActivity.link}
                  </a>
                </InfoBoxContent>
              </InfoBox>
            )}
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='menu-alt-4' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <span className='break-words'>{detailedActivity.description}</span>
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='comments' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <CustomLink to={{ pathname: paths.details(activeActivityId).comments }}>
                  <span className='underline'>
                    {t('globals.comments.label', { count: detailedActivity.commentsCount })}
                  </span>
                </CustomLink>
              </InfoBoxContent>
            </InfoBox>
            <div className='py-3 w-full'>
              <IndexDetailedActivityParticipants
                participants={detailedActivity.participants}
                freeSlots={
                  detailedActivity.slots !== undefined
                    ? detailedActivity.slots - detailedActivity.participants.length
                    : undefined
                }
              />
            </div>
            {!userAlreadyParticipates && !isActivityBookedOut && detailedActivity.withJoinFeature && (
              <Button
                onClick={handleJoinButtonClick}
                className='py-2 w-full'
                isLoading={statusJoinActivity === 'loading'}
              >
                {t('home.details.buttons.join')}
              </Button>
            )}
            {isActivityBookedOut && !userAlreadyParticipates && (
              <div className='text-base border rounded-md px-3 py-2 bg-blue-100 border-blue-300 w-full text-center'>
                {t('home.details.booked-out-info-box')}
              </div>
            )}
            {userAlreadyParticipates && (
              <CustomLink className='py-2 w-full' to={{ pathname: paths.myActivities.details(detailedActivity.id) }}>
                <Button className='w-full'>{t('home.details.buttons.show-in-my-activities')}</Button>
              </CustomLink>
            )}
            <Button variant='text' onClick={handleOnBackClick} className='text-sm text-center py-2 w-full'>
              {t('home.details.buttons.back')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
