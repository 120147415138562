import { t } from 'i18next';

function isDST(date: Date) {
  const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

  return Math.max(january, july) !== date.getTimezoneOffset();
}

export function normalizeDSTForServer(date: Date) {
  return new Date(isDST(date) ? date.getTime() + 3600000 : date.getTime());
}

function normalizeDSTForDisplay(date: Date) {
  return new Date(isDST(date) ? date.getTime() - 3600000 : date.getTime());
}

export const prettifyTime = (date: Date): string => {
  const normalizedDate = normalizeDSTForDisplay(date);

  const hours = `0${normalizedDate.getHours()}`.slice(-2);
  const minutes = `0${normalizedDate.getMinutes()}`.slice(-2);
  return `${hours}:${minutes}`;
};

export const prettifyDate = (date: Date): string => {
  const normalizedDate = normalizeDSTForDisplay(date);

  return t('globals.date.date', {
    day: `0${normalizedDate.getDate()}`.slice(-2),
    month: `0${normalizedDate.getMonth() + 1}`.slice(-2),
    year: normalizedDate.getFullYear(),
  });
};

export const prettifyDateRange = (startAt: Date, endsAt?: Date): string => {
  const prettifiedStartDate = prettifyTime(startAt);

  if (!endsAt) {
    return t('globals.date.time.single', { time: prettifiedStartDate });
  }

  const prettifiedEndDate = prettifyTime(endsAt);

  return t('globals.date.time.period', {
    from: prettifiedStartDate,
    to: prettifiedEndDate,
  });
};
