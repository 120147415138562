import { ConsoleLogger } from './ConsoleLogger';
import { LoggingStrategy } from './LoggingStrategy';
import { NoopLogger } from './NoopLogger';

const isProduction = process.env.NODE_ENV === 'production';

const getLogger = () => {
  if (isProduction) return new NoopLogger();

  return new ConsoleLogger();
};

export const Logger: LoggingStrategy = getLogger();
