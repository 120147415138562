import { useContext } from 'react';

import { User } from '@/models/user';

import { UserContext } from '../user-context';

export const useUser = (): User => {
  const value = useContext(UserContext);

  if (!value) {
    throw new Error('Component must be wrapped with <UserProvider>');
  }

  if (!value.user) {
    throw new Error(
      'The user must be defined when using this hook. This hook should only be used if component is wrapped by <SignedInRoute />',
    );
  }

  return value.user;
};
