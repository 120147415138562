import { Button, Modal, ModalContent } from '@component-library';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useDeleteActivity } from '@/api/resources/activity';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';

type DeleteActivityModalProps = {
  activityId: string;
  open: boolean;
  handleClose: () => void;
  onSuccess?: () => void;
};

export const DeleteActivityModal: React.FC<DeleteActivityModalProps> = ({
  activityId,
  handleClose,
  open,
  onSuccess,
}) => {
  const { t: baseT } = useTranslation();
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.activity.delete' });
  const { track } = useAnalytics();

  const { mutateAsync, status } = useDeleteActivity();

  const handleOnSubmitClick = () => {
    track.event({ name: AnalyticsEvents.DELETE_ACTIVITY, params: { activityId } });

    mutateAsync({ pathVariables: { id: activityId } })
      .then(() => {
        handleClose();
        if (onSuccess) onSuccess();
        toast.success(t('success'));
      })
      .catch(() => {
        toast.error(baseT('globals.errors.unexpected'));
      });
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose}>
        <ModalContent>
          <div className='text-base font-bold text-gray-800 text-center'>{t('modal.title')}</div>
          <div className='flex flex-row md:justify-end gap-3 mt-5'>
            <Button
              className='flex-grow md:flex-grow-0 text-red-700'
              variant='outlined'
              onClick={handleOnSubmitClick}
              isLoading={status === 'loading'}
            >
              {t('modal.buttons.submit')}
            </Button>
            <Button className='flex-grow md:flex-grow-0 text-gray-800' variant='outlined' onClick={handleClose}>
              {t('modal.buttons.cancel')}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
