import React, { useState } from 'react';

import { DetailedActivity } from '@/hooks/useActivityById';

import { Icon } from '../../../../component-library/components';
import { InfoBox, InfoBoxContent, InfoBoxIcon } from '../info-box';
import { LocationModal } from './location-modal';

type LocationInfoBoxProps = DetailedActivity['location'];

export const LocationInfoBox: React.FC<LocationInfoBoxProps> = ({ coordinates, description }) => {
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  return (
    <>
      <LocationModal open={isLocationOpen} handleClose={() => setIsLocationOpen(false)} coordinates={coordinates} />
      <InfoBox
        onClick={() => {
          setIsLocationOpen(true);
        }}
      >
        <InfoBoxIcon>
          <Icon iconKey='location-marker' />
        </InfoBoxIcon>
        <InfoBoxContent>
          <div className='cursor-pointer flex flex-row gap-5 items-center text-gray-800'>
            <div className='flex flex-col underline'>{description}</div>
          </div>
        </InfoBoxContent>
      </InfoBox>
    </>
  );
};
