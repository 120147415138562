import { Divider, Icon } from '@component-library';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomLink } from '@/components/custom-link/custom-link';
import { DeleteLeaveActivityButton } from '@/components/delete-leave-activity/button';
import { DateInfoBox } from '@/components/info-box/date/date';
import { InfoBox, InfoBoxContent, InfoBoxIcon } from '@/components/info-box/info-box';
import { LocationInfoBox } from '@/components/info-box/location/location-info-box';
import { Participants } from '@/components/members/participants/participants';
import { DetailedActivity } from '@/hooks/useActivityById';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

type MyActivitiesDetailsActivityProps = DetailedActivity;

export const MyActivitiesDetailsActivity: React.FC<MyActivitiesDetailsActivityProps> = ({
  id,
  createdBy,
  description,
  endsAt,
  location,
  participants,
  price,
  skillLevel,
  slots,
  sport,
  startsAt,
  frequency,
  lastDate,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const displayedTitle = title ?? t(`globals.sportType.${sport}`);

  return (
    <>
      <div className='text-gray-600 flex flex-row ml-7 items-center gap-2 mb-4'>
        <CustomLink to={{ pathname: paths.myActivities.base }}>
          <ChevronLeftIcon width={24} height={24} />
        </CustomLink>
        <span className='text-2xl text-center leading-4'>{displayedTitle}</span>
      </div>
      <Divider />
      <div className='ml-7 flex flex-col mt-7 items-start gap-4 m-auto max-w-sm'>
        <DateInfoBox startsAt={startsAt} endsAt={endsAt} frequency={frequency} lastDate={lastDate} />
        <LocationInfoBox {...location} />
        {skillLevel && (
          <InfoBox>
            <InfoBoxIcon>
              <Icon iconKey='skill' />
            </InfoBoxIcon>
            <InfoBoxContent>
              <span className='break-words'>{skillLevel}</span>
            </InfoBoxContent>
          </InfoBox>
        )}
        <InfoBox>
          <InfoBoxIcon>
            <Icon iconKey='price' />
          </InfoBoxIcon>
          <InfoBoxContent>
            <span>{t('globals.activity.price', { count: price })}</span>
          </InfoBoxContent>
        </InfoBox>
        <InfoBox>
          <InfoBoxIcon>
            <Icon iconKey='menu-alt-4' />
          </InfoBoxIcon>
          <InfoBoxContent>
            <span className='break-words'>{description}</span>
          </InfoBoxContent>
        </InfoBox>
      </div>
      <div className='flex flex-row gap-2 justify-between mt-8 ml-7 mr-5 items-center'>
        <Participants freeSlots={slots ? slots - participants.length : undefined} participants={participants} />
        <DeleteLeaveActivityButton
          activityId={id}
          createdBy={createdBy}
          onSuccess={() => {
            navigate({ pathname: paths.myActivities.base });
          }}
        />
      </div>
    </>
  );
};
