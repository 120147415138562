import React, { PropsWithChildren } from 'react';

type CommentTextProps = {
  children: string;
};

export const CommentText: React.FC<CommentTextProps> = ({ children }) => {
  return <div className='mx-4 mt-2 mb-5 text-base'>{children}</div>;
};

type CommentInfoProps = {
  // make it probably to date
  children: string;
};
export const CommentInfo: React.FC<CommentInfoProps> = ({ children }) => {
  return <span className='text-sm absolute bottom-0 right-1'>{children}</span>;
};

export type CommentProps = {
  avatar: React.ReactNode;
};

export const Comment: React.FC<PropsWithChildren<CommentProps>> = ({ avatar, children }) => {
  return (
    <div className='flex flex-row items-center gap-1.5 w-full'>
      {avatar}
      <div className='relative text-gray-700 border border-gray-200 flex-grow mr-12 rounded-lg shadow-lg'>
        {children}
      </div>
    </div>
  );
};
