import { useContext } from 'react';

import { FilterTypesContext } from '../filter-types-context';

export const useFilterTypes = () => {
  const value = useContext(FilterTypesContext);

  if (!value) {
    throw new Error('Component must be wrapped with <FilterTypesContextProvider>');
  }

  return value;
};
