import {
  Auth,
  AuthError,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  UserCredential,
} from '@firebase/auth';

import { firebaseApp } from '@/utils/firebase';

import { UserExistsForDifferentProviderError } from './UserExistsForDifferentProviderError';

export enum AuthMethod {
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
}

const getProviderForMethod = (method: AuthMethod) => {
  const exhaustiveCheck = (value: never): never => {
    throw new Error(`Unhandled method ${value}`);
  };

  switch (method) {
    case AuthMethod.FACEBOOK:
      return new FacebookAuthProvider();
    case AuthMethod.GOOGLE:
      return new GoogleAuthProvider();
    default:
      return exhaustiveCheck(method);
  }
};

const catchUserExistIsForDifferentProvider = (auth: Auth) => async (error: AuthError): Promise<UserCredential> => {
  if (error.code !== 'auth/account-exists-with-different-credential' || !error.customData.email) {
    throw error;
  }

  const methods = await fetchSignInMethodsForEmail(auth, error.customData.email);

  if (methods.length === 0) {
    throw new Error('No sign in methods available.');
  }

  throw new UserExistsForDifferentProviderError(methods[0], error.customData.email);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const credentialsFromAuthResult = (provider: GoogleAuthProvider | FacebookAuthProvider | any) => {
  if (provider instanceof GoogleAuthProvider) {
    return GoogleAuthProvider.credentialFromResult;
  }

  if (provider instanceof FacebookAuthProvider) {
    return FacebookAuthProvider.credentialFromResult;
  }

  throw new Error(`Provider ${provider.constructor.name} not supported.`);
};

export const socialSignIn = async (method: AuthMethod) => {
  const provider = getProviderForMethod(method);
  provider.addScope('email');

  const resolveCredentials = credentialsFromAuthResult(provider);
  const auth = getAuth(firebaseApp);

  auth.useDeviceLanguage();
  try {
    const response = await signInWithPopup(auth, provider).catch(catchUserExistIsForDifferentProvider(auth));
    const credentials = await resolveCredentials(response);

    if (!credentials || !credentials.accessToken || !auth.currentUser) {
      throw new Error('No credentials provided after social login');
    }

    const idToken = await auth.currentUser.getIdToken(true);

    return {
      accessToken: credentials.accessToken,
      idToken,
      provider: credentials.providerId,
      user: {
        authProviderId: response.user.uid,
        email: response.user.email || (response as any)._tokenResponse.email || undefined, // eslint-disable-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        firstName: (response as any)._tokenResponse.firstName || undefined, // eslint-disable-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        lastName: (response as any)._tokenResponse.lastName || undefined, // eslint-disable-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        displayName: response.user.displayName || undefined,
        profilePictureUrl: response.user.photoURL || undefined,
        emailVerified: response.user.emailVerified || false,
      },
    };
  } catch (e) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    if ((e as any).code) {
      const { code } = e as any; // eslint-disable-line  @typescript-eslint/no-explicit-any
      if (code === 'auth/popup-closed-by-user' || code === 'auth/cancelled-popup-request') {
        return null;
      }
    }

    throw e;
  }
};
