import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

import { useAddActivity } from '../../context/hooks/use-form-provider';
import { StepThreeFormData } from '../../form-types';
import { AddActivityFormLayout } from '../../layouts/add-activity-form.layout';
import { isStepOneComplete } from '../../utils/isStepOneComplete';
import { isStepTwoComplete } from '../../utils/isStepTwoComplete';
import { AddActivityStepThreeSkillLevel } from './components/level';
import { AddActivityStepThreePrice } from './components/price';
import { AddActivityStepThreeSlots } from './components/slots';

export const AddActivityStepThreePage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity' });
  const { data, updateData } = useAddActivity();
  const navigate = useCustomNavigate();
  const { handleSubmit, control, watch } = useForm<StepThreeFormData>({
    defaultValues: {
      slots: data.slots ?? 2,
      skillLevel: data.skillLevel ?? '',
      price: data.price ?? 0,
    },
  });

  if (!isStepOneComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepOne }} />;
  }

  if (!isStepTwoComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepTwo }} />;
  }

  const slots = watch('slots');
  const skillLevel = watch('skillLevel');
  const price = watch('price');

  const handleNext = (updatedData: StepThreeFormData) => {
    updateData({ ...updatedData });
    navigate({ pathname: paths.addActivity.stepFour });
  };

  const handlePrevious = () => {
    updateData({ slots, skillLevel, price });
    navigate({ pathname: paths.addActivity.stepTwo });
  };

  return (
    <AddActivityFormLayout
      onSubmit={handleSubmit(handleNext)}
      activeStep='three'
      FooterProps={{
        leftButton: { label: t('layout.footer.buttons.back'), onClick: handlePrevious },
        rightButton: { label: t('layout.footer.buttons.continue') },
      }}
    >
      <div className='flex flex-col mx-10 mt-3 gap-y-11'>
        <AddActivityStepThreeSlots control={control} />
        <AddActivityStepThreeSkillLevel control={control} />
        <AddActivityStepThreePrice control={control} />
      </div>
    </AddActivityFormLayout>
  );
};
