import { Button, Icon } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Participants } from '@/components/members/participants/participants';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';
import { Frequency, LocationType, Participant, SportType } from '@/types';
import { getImageSrcBySportType } from '@/utils/getImageSrcBySportType';
import { prettifyDateRange } from '@/utils/prettifier/transformDateToTime';
import { getGermanWeekday, transformDateToWeekdayAndDate } from '@/utils/prettifier/transformDateToWeekdayAndDate';

type ListItemProps = {
  id: string;
  sportType: SportType;
  slots?: number;
  participants: Participant[];
  location: Omit<LocationType, 'coordinates'>;
  startsAt: Date;
  endsAt?: Date;
  frequency: Frequency;
  title?: string;
  coverPictureUrl?: string;
  withJoinFeature: boolean;
};

export const ListItem: React.FC<ListItemProps> = ({
  id,
  sportType,
  slots,
  participants,
  location,
  startsAt,
  endsAt,
  frequency,
  title,
  coverPictureUrl,
  withJoinFeature,
}) => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const handleOnDetailsClick = () => {
    track.event({
      name: AnalyticsEvents.VIEW_DETAILS,
      params: {
        page: 'list-page',
        sportType,
        activityId: id,
      },
    });
    navigate({ pathname: paths.details(id).base });
  };

  const displayedTitle = title ?? t(`globals.sportType.${sportType}`);

  return (
    <div className='flex flex-row justify-between text-gray-800 text-base py-2 px-4 max-w-[100vw]'>
      <div className='flex flex-col gap-2 max-w-[60%]'>
        <span className='ml-9 text-lg font-semibold'>{displayedTitle}</span>
        <div className='flex flex-row items-center'>
          <Icon className='mr-3' iconKey='clock' />
          <div className='flex flex-col flex-grow max-w-[95%]'>
            {frequency === Frequency.ONCE && (
              <>
                <span className='break-words'>{transformDateToWeekdayAndDate(startsAt)}</span>
                <span className='break-words'>{prettifyDateRange(startsAt, endsAt)}</span>
              </>
            )}
            {frequency === Frequency.WEEKLY && (
              <>
                <span className='break-words'>{t('home.list.item.weekly', { day: getGermanWeekday(startsAt) })}</span>
                <span className='break-words'>{prettifyDateRange(startsAt, endsAt)}</span>
              </>
            )}
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <Icon className='mr-3' iconKey='location-marker' />
          <div className='flex flex-col flex-grow  max-w-[95%]'>
            <span className='break-words'>{location.description}</span>
          </div>
        </div>
        <div className='ml-1 mt-2'>
          {withJoinFeature && (
            <Participants
              participants={participants}
              freeSlots={slots !== undefined ? slots - participants.length : undefined}
            />
          )}
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <div className='flex flex-row items-center flex-grow'>
          <div
            onClick={handleOnDetailsClick}
            className='relative h-24 w-24 rounded-md flex flex-col justify-around items-center overflow-hidden cursor-pointer text-white bg-gray-200'
            style={{
              backgroundImage: `url(${coverPictureUrl ?? getImageSrcBySportType(sportType)})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        </div>
        <Button className='py-1.5 px-8 mt-3' onClick={handleOnDetailsClick}>
          {t('home.list.item.buttons.details')}
        </Button>
      </div>
    </div>
  );
};
