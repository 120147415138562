import 'mapbox-gl/dist/mapbox-gl.css';

import React from 'react';
import { Outlet } from 'react-router-dom';

import { CustomBottomSheet } from '@/components/bottom-sheet/bottom-sheet';

import { IndexMaps } from './components/map/index-map';

export const IndexPageWrapper: React.FC = () => {
  return (
    <>
      <IndexMaps />
      <CustomBottomSheet>
        <Outlet />
      </CustomBottomSheet>
    </>
  );
};
