// eslint-disable-next-line no-restricted-imports
import { NavigateOptions, Path, useNavigate } from 'react-router-dom';

import { useAnalytics } from '@/contexts/hooks/useAnalytics';

import { NavigationState } from '../../types/router';

interface NavigateOptionsWithTypedState extends NavigateOptions {
  state?: NavigationState;
}

/**
 * This hook is used to navigate to a new location. It will append the affiliate id to the url if it is not already present.
 */
export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const { affiliateId } = useAnalytics();

  const customNavigate = (path: Partial<Path>, options?: NavigateOptionsWithTypedState) => {
    const urlParams = new URLSearchParams(path.search);
    if (urlParams.has('aid') || !affiliateId) {
      navigate(path, options);
      return;
    }

    urlParams.append('aid', affiliateId);

    navigate(
      {
        pathname: path.pathname,
        search: `?${urlParams.toString()}`,
        hash: path.hash,
      },
      options,
    );
  };

  return customNavigate;
};
