import React from 'react';
import { twMerge } from 'tailwind-merge';

type LoaderProps = {
  className?: string;
  size?: number;
};

export const Loader: React.FC<LoaderProps> = ({ size = 48, className }) => {
  return (
    <div>
      <div
        style={{ width: size, height: size, borderWidth: Math.min(size / 8, 4), borderTop: 'transparent' }}
        className={twMerge('border-solid animate-spin rounded-full border-gray-600', className)}
      ></div>
    </div>
  );
};
