import { usePostHog } from 'posthog-js/react';
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import uuid4 from 'uuid4';

export type AnalyticsContextProps = {
  affiliateId?: string;
  browserStorageId?: string;
  sessionId?: string;
};

export const AnalyticsContext = createContext<AnalyticsContextProps>({});

const BROWSER_IDENTIFIER = 'bro_id';

export const AnalyticsContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const posthog = usePostHog();
  const localStorageAid = localStorage.getItem('aid') ?? undefined;
  const paramAid = searchParams.get('aid') ?? undefined;

  const [affiliateId] = useState<string | undefined>(paramAid ?? localStorageAid);

  const [browserStorageId] = useState<string>(localStorage.getItem(BROWSER_IDENTIFIER) ?? uuid4());

  const [sessionId] = useState<string>(uuid4());

  useEffect(() => {
    if (!localStorage.getItem(BROWSER_IDENTIFIER)) {
      // NOTE: This could be cookie consent sensitive
      localStorage.setItem(BROWSER_IDENTIFIER, browserStorageId);
    }
  }, [browserStorageId]);

  useEffect(() => {
    if (!paramAid && localStorageAid) {
      setSearchParams((prev) => {
        prev.set('aid', localStorageAid);
        return prev;
      });
      posthog?.group('affiliateGroup', localStorageAid, { name: localStorageAid });
      return;
    }

    if (!!paramAid && paramAid !== localStorageAid) {
      // NOTE: This could be cookie consent sensitive
      localStorage.setItem('aid', paramAid);
    }

    if (paramAid) {
      posthog?.group('affiliateGroup', paramAid, { name: paramAid });
    }
  }, [affiliateId, posthog]);

  return (
    <AnalyticsContext.Provider value={{ affiliateId, browserStorageId, sessionId }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
