import { config } from '@config';
import { getAuth } from '@firebase/auth';
import axios from 'axios';

import { firebaseApp } from '@/utils/firebase';

export const client = axios.create({
  baseURL: config.BACKEND_URL,
});

client.interceptors.request.use(async (axiosConfig) => {
  const auth = getAuth(firebaseApp);

  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();

    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers.Authorization = token;
  }

  return axiosConfig;
});
