import { ReactComponent as Clock } from '@assets/icons/clock.svg';
import { ReactComponent as Comments } from '@assets/icons/comments.svg';
import { ReactComponent as EmailAndAt } from '@assets/icons/emailandat.svg';
import { ReactComponent as Globe } from '@assets/icons/globe.svg';
import { ReactComponent as InformationCircle } from '@assets/icons/information-circle.svg';
import { ReactComponent as Juggling } from '@assets/icons/juggling.svg';
import { ReactComponent as LocationMarker } from '@assets/icons/location-marker.svg';
import { ReactComponent as Menu } from '@assets/icons/menu.svg';
import { ReactComponent as MenuAlt4 } from '@assets/icons/menu-alt-4.svg';
import { ReactComponent as Price } from '@assets/icons/price.svg';
import { ReactComponent as RunningMan } from '@assets/icons/running-man.svg';
import { ReactComponent as Send } from '@assets/icons/send.svg';
import { ReactComponent as Skill } from '@assets/icons/skill.svg';
import { ReactComponent as Sports } from '@assets/icons/sports.svg';
import { ReactComponent as SportyClub } from '@assets/icons/sporty-club.svg';
import { ReactComponent as X } from '@assets/icons/x.svg';

export const icons = {
  clock: Clock,
  comments: Comments,
  'email-and-at': EmailAndAt,
  globe: Globe,
  'information-circle': InformationCircle,
  juggling: Juggling,
  'location-marker': LocationMarker,
  'menu-alt-4': MenuAlt4,
  menu: Menu,
  price: Price,
  'running-man': RunningMan,
  send: Send,
  skill: Skill,
  sports: Sports,
  'sporty-club': SportyClub,
  x: X,
};
