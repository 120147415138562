export const config = {
  MAPBOX_TOKEN: process.env.VITE_MAPBOX_TOKEN as string,
  SENTRY_DSN: process.env.VITE_SENTRY_DSN || '',
  MAP_STYLE: 'mapbox://styles/wnkchristopher/cli24gon602ha01pna12q3zvk',
  COOKIES_USED_WITHOUT_ASKING_FOR_PERMISSIONS: (process.env.VITE_COOKIES_USED_WITHOUT_ASKING_FOR_PERMISSIONS ||
    'false') as string,
  BACKEND_URL: (process.env.VITE_BACKEND_URL || '') as string,
  FIREBASE: {
    apiKey: 'AIzaSyCZrXalUp4z1H0ZzcMXR-2bD_nmfFtMTXY',
    authDomain: 'sporty-a8e3b.firebaseapp.com',
    projectId: 'sporty-a8e3b',
    storageBucket: 'sporty-a8e3b.appspot.com',
    messagingSenderId: '443711844603',
    appId: '1:443711844603:web:d5640238977e242a4a005e',
    measurementId: 'G-RG1KK3657N',
  },
  CONTACT: {
    mail: 'info@sporty.club',
  },
  EVENTS_DISABLED: process.env.VITE_EVENTS_DISABLED,
};
