import React from 'react';
import { useTranslation } from 'react-i18next';

import { Participant } from '@/types';

import { Members } from '../members';

export type ParticipantsProps = {
  className?: string;
  freeSlots?: number;
  participants: Participant[];
};

const FreeSlots = ({ amount }: { amount?: number }) => {
  const { t } = useTranslation();
  return (
    <div className='z-10  h-full text-xs bg-white text-gray-600 flex items-center text-center px-2 rounded-full font-bold border border-gray-400'>
      {amount !== undefined ? t('globals.freeSlots', { count: amount }) : t('globals.unlimited-slots')}
    </div>
  );
};

export const Participants: React.FC<ParticipantsProps> = ({ className, freeSlots, participants }) => {
  return (
    <Members className={className} members={participants} maxAmountAvatar={3}>
      <FreeSlots amount={freeSlots} />
    </Members>
  );
};
