import { config } from '@config';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MyProfileDisclaimer: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'myProfile' });
  return (
    <>
      <div className='gap-2 box-border w-80 rounded-lg shadow-lg text-center'>
        <div className='my-6'>
          <p className='whitespace-pre-line text-gray-800 text-lg'>
            {t('disclaimer')}
            <a className='underline' href={`mailto:${config.CONTACT.mail}`}>
              {config.CONTACT.mail}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
