import { Modal } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GoogleMaps } from './assets/icons/google-maps.svg';

type LocationModalProps = {
  open: boolean;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  handleClose: () => void;
};

const createGoogleMapsLinkByCoordinates = (coordinates: { latitude: number; longitude: number }) => {
  return `https://www.google.com/maps/place/${coordinates.latitude},${coordinates.longitude}`;
};

export const LocationModal: React.FC<LocationModalProps> = ({ coordinates, handleClose, open }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'globals.activity.location' });

  return (
    <>
      <Modal open={open} handleClose={handleClose}>
        <div className='py-3'>
          <a
            href={createGoogleMapsLinkByCoordinates(coordinates)}
            onClick={handleClose}
            target='_blank'
            className='flex flex-row align-center gap-3 py-2 px-5 hover:bg-gray-200'
            rel='noreferrer'
          >
            <GoogleMaps width={30} height={30} />
            <span className='text-base text-gray-800 flex items-center'>{t('google-maps')}</span>
          </a>
        </div>
      </Modal>
    </>
  );
};
