import { SelectInput, SelectInputOption } from '@component-library';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SportType } from '@/types';

import { StepOneFormData } from '../../../form-types';

type AddActivityStepOneSportTypeProps = {
  control: Control<StepOneFormData>;
};

export const AddActivityStepOneSportType: React.FC<AddActivityStepOneSportTypeProps> = ({ control }) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController({
    rules: {
      required: t('add-activity.step-one.sportType.validation.required'),
    },
    control,
    name: 'sportType',
  });

  return (
    <SelectInput
      label={`${t('add-activity.step-one.sportType.label')} *`}
      placeholder={t('add-activity.step-one.sportType.placeholder')}
      selectedItem={{ value: field.value, label: field.value ? t(`globals.sportType.${field.value}`) : '' }}
      onChange={field.onChange}
      error={fieldState.error?.message}
    >
      {Object.values(SportType)
        .sort((a, b) => {
          return t(`globals.sportType.${a}`) < t(`globals.sportType.${b}`) ? -1 : 1;
        })
        .map((sportType) => (
          <SelectInputOption
            key={sportType}
            value={sportType}
            label={t(`globals.sportType.${sportType}`)}
            isActive={field.value === sportType}
          />
        ))}
    </SelectInput>
  );
};
