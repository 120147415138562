import { CheckIcon } from '@heroicons/react/24/solid';
import React, { createContext, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type SelectInputBoxProps = {
  selected: string[];
  onChange: (selected: string[]) => void;
  className?: string;
};

const SelectBoxContext = createContext<{ selected: string[]; toggleSelected: (value: string) => void }>({
  selected: [],
  toggleSelected: () => {
    throw new Error('SelectBoxContext not initialized');
  },
});

export const SelectInputBox: React.FC<PropsWithChildren<SelectInputBoxProps>> = ({
  children,
  selected,
  onChange,
  className,
}) => {
  const toggleSelected = (value: string) => {
    if (selected.includes(value)) {
      onChange(selected.filter((item) => item !== value));
    } else {
      onChange([...selected, value]);
    }
  };

  return (
    <SelectBoxContext.Provider value={{ selected, toggleSelected }}>
      <div className={twMerge('flex flex-col overflow-y-scroll w-full border border-gray-400', className)}>
        {children}
      </div>
    </SelectBoxContext.Provider>
  );
};

type SelectInputBoxType = {
  value: string;
};

export const SelectInputBoxOption: React.FC<PropsWithChildren<SelectInputBoxType>> = ({ value, children }) => {
  const { selected, toggleSelected } = React.useContext(SelectBoxContext);

  const isSelected = selected.includes(value);

  return (
    <div
      className={twMerge('w-full py-2 px-2 text-gray-900', isSelected && 'bg-indigo-600 text-white')}
      onClick={() => {
        toggleSelected(value);
      }}
    >
      <div className='flex flex-row justify-between cursor-pointer text-lg'>
        <div className='flex flex-row items-center'>{children}</div>
        {isSelected && <CheckIcon width={24} height={24} />}
      </div>
    </div>
  );
};

export const SelectInputBoxIcon: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className='mr-4'>{children}</div>;
};
