import { ReactComponent as BadmintonMarker } from '@assets/icons/map-marker/badminton-marker.svg';
import { ReactComponent as BilliardsMarker } from '@assets/icons/map-marker/billiards-marker.svg';
import { ReactComponent as BoulderMarker } from '@assets/icons/map-marker/boulder-marker.svg';
import { ReactComponent as CyclingMarker } from '@assets/icons/map-marker/cycling-marker.svg';
import { ReactComponent as DanceMarker } from '@assets/icons/map-marker/dance-marker.svg';
import { ReactComponent as FlunkyballMarker } from '@assets/icons/map-marker/flunkyball-marker.svg';
import { ReactComponent as FrisbeeMarker } from '@assets/icons/map-marker/frisbee-marker.svg';
import { ReactComponent as GymMarker } from '@assets/icons/map-marker/gym-marker.svg';
import { ReactComponent as LongboardMarker } from '@assets/icons/map-marker/longboard-marker.svg';
import { ReactComponent as OutdoorGymMarker } from '@assets/icons/map-marker/outdoor-gym-marker.svg';
import { ReactComponent as RunningMarker } from '@assets/icons/map-marker/running-marker.svg';
import { ReactComponent as SkateboardMarker } from '@assets/icons/map-marker/skateboard-marker.svg';
import { ReactComponent as SoccerMarker } from '@assets/icons/map-marker/soccer-marker.svg';
import { ReactComponent as SpikeballMarker } from '@assets/icons/map-marker/spikeball-marker.svg';
import { ReactComponent as SquashMarker } from '@assets/icons/map-marker/squash-marker.svg';
import { ReactComponent as SwimMarker } from '@assets/icons/map-marker/swim-marker.svg';
import { ReactComponent as TableTennisMarker } from '@assets/icons/map-marker/table-tennis-marker.svg';
import { ReactComponent as TennisMarker } from '@assets/icons/map-marker/tennis-marker.svg';
import { ReactComponent as VolleyballMarker } from '@assets/icons/map-marker/volleyball-marker.svg';
import { ReactComponent as YogaMarker } from '@assets/icons/map-marker/yoga-marker.svg';
import React from 'react';

import { exhaustiveCheck } from '@/lib/type-guards/exhaustive-check';
import { SportType } from '@/types';

export const INACTIVE_SIZE = {
  width: 50,
  height: 50,
};

export const ACTIVE_SIZE = {
  width: 80,
  height: 80,
};

type SportMarkerProps = {
  active?: boolean;
  sport: SportType;
  onClick?: () => void;
};

type SpecificSportsMarkerProps = Pick<SportMarkerProps, 'sport'> & {
  size: {
    width: number;
    height: number;
  };
};

const SpecificSportsMarker: React.FC<SpecificSportsMarkerProps> = ({ sport, size }) => {
  switch (sport) {
    case SportType.BADMINTON:
      return <BadmintonMarker {...size} />;
    case SportType.BILLIARDS:
      return <BilliardsMarker {...size} />;
    case SportType.BOULDERING:
      return <BoulderMarker {...size} />;
    case SportType.CYCLING:
      return <CyclingMarker {...size} />;
    case SportType.DANCE:
      return <DanceMarker {...size} />;
    case SportType.FRISBEE:
      return <FrisbeeMarker {...size} />;
    case SportType.FLUNKYBALL:
      return <FlunkyballMarker {...size} />;
    case SportType.GYM:
      return <GymMarker {...size} />;
    case SportType.LONGBOARDING:
      return <LongboardMarker {...size} />;
    case SportType.OUTDOOR_GYM:
      return <OutdoorGymMarker {...size} />;
    case SportType.RUNNING:
      return <RunningMarker {...size} />;
    case SportType.SKATEBOARDING:
      return <SkateboardMarker {...size} />;
    case SportType.SOCCER:
      return <SoccerMarker {...size} />;
    case SportType.SPIKEBALL:
      return <SpikeballMarker {...size} />;
    case SportType.SWIMMING:
      return <SwimMarker {...size} />;
    case SportType.SQUASH:
      return <SquashMarker {...size} />;
    case SportType.TABLE_TENNIS:
      return <TableTennisMarker {...size} />;
    case SportType.TENNIS:
      return <TennisMarker {...size} />;
    case SportType.VOLLEYBALL:
      return <VolleyballMarker {...size} />;
    case SportType.YOGA:
      return <YogaMarker {...size} />;
    default:
      return exhaustiveCheck(sport);
  }
};

export const SportsMarker: React.FC<SportMarkerProps> = ({ active = false, onClick, sport }) => {
  const size = active ? ACTIVE_SIZE : INACTIVE_SIZE;

  return (
    <span onClick={onClick}>
      <SpecificSportsMarker sport={sport} size={size} />
    </span>
  );
};
