import './map.css';

import { ACTIVE_SIZE, Icon, INACTIVE_SIZE, SportsIcon, SportsMarker } from '@component-library';
import { MapPinIcon } from '@heroicons/react/20/solid';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef, Marker } from 'react-map-gl';
import { useParams } from 'react-router-dom';

import { LazyLoadedMap } from '@/components/map/lazy-loaded-map';
import { Menu } from '@/components/menu/menu';
import { useFilterTypes } from '@/contexts/hooks/useFilterTypes';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';
import { SportType } from '@/types';

import { useActivities } from '../../hooks/useActivities';
import { MapPill, MapPillIcon, MapPillText } from './pill/pill';

export const IndexMaps: React.FC = () => {
  const navigate = useCustomNavigate();
  const { data } = useActivities();
  const { activityId: activeActivityId } = useParams();

  const activeActivity = useMemo(() => data?.find((activity) => activity.id === activeActivityId), [
    data,
    activeActivityId,
  ]);

  const [mapRef, setMapRef] = useState<MapRef | null>(null);

  useEffect(() => {
    if (!mapRef || !activeActivity) {
      return;
    }
    mapRef.flyTo({
      padding: {
        top: 0,
        bottom: 20,
        left: 0,
        right: 0,
      },
      center: {
        lat: activeActivity.location.coordinates.latitude,
        lng: activeActivity.location.coordinates.longitude,
      },
      speed: 0.25,
      maxDuration: 3000,
    });
  }, [mapRef, mapRef, activeActivity]);

  const handleClickOnMarker = (activityId: string) => {
    if (activityId === activeActivityId) {
      navigate({ pathname: paths.home });
      return;
    }
    navigate({ pathname: paths.details(activityId).base });
  };

  return (
    <div className='h-screen w-screen absolute top-0 left-0'>
      <LazyLoadedMap mapRef={(ref) => ref && setMapRef(ref)} logoPosition='top-left'>
        {data?.map((activity, index) => (
          <Marker
            key={index}
            latitude={activity.location.coordinates.latitude}
            longitude={activity.location.coordinates.longitude}
            anchor='center'
            style={{
              cursor: 'pointer',
              zIndex: activeActivityId === activity.id ? '1' : 0,
            }}
            offset={[0, -(activeActivityId === activity.id ? ACTIVE_SIZE.height : INACTIVE_SIZE.height) / 2]}
          >
            <SportsMarker
              sport={activity.sport}
              active={activeActivityId === activity.id}
              onClick={() => handleClickOnMarker(activity.id)}
            />
          </Marker>
        ))}
      </LazyLoadedMap>
      <MapFilter>
        <Menu />
      </MapFilter>
    </div>
  );
};

const MapFilter: React.FC<PropsWithChildren> = ({ children }) => {
  const { types } = useFilterTypes();

  return (
    // NOTE: -30px is used to do not overlap the burger menu item
    <div className='absolute top-4 left-0 w-full px-3 py-3 flex flex-row gap-3'>
      <MapPill
        LinkProps={{
          to: { pathname: paths.filter.location },
          state: {
            previousPath: window.location.pathname,
          },
        }}
      >
        <MapPillIcon>
          <MapPinIcon width={18} height={18} />
        </MapPillIcon>
        <MapPillText>
          <span className='text-base pr-1'>Leipzig</span>
        </MapPillText>
      </MapPill>
      <MapPill
        LinkProps={{
          to: { pathname: paths.filter.types },
          state: {
            previousPath: window.location.pathname,
          },
        }}
      >
        <MapFilterTypesPillContent types={types} />
      </MapPill>
      {children}
    </div>
  );
};

type MapFilterTypesPillContentProps = {
  types?: SportType[];
};

const MapFilterTypesPillContent: React.FC<MapFilterTypesPillContentProps> = ({ types }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'home.filter.type' });
  if (!types || types.length === Object.keys(SportType).length) {
    return (
      <>
        <MapPillIcon>
          <Icon iconKey='sports' width={18} height={18} />
        </MapPillIcon>
        <MapPillText>
          <span className='text-base pr-1 flex flex-row gap-2'>{t('all')}</span>
        </MapPillText>
      </>
    );
  }

  const firstThreeSportTypes = types.slice(0, 3);

  return (
    <span className='flex flex-row gap-2 min-w-[50px] justify-center'>
      {firstThreeSportTypes.map((type) => (
        <SportsIcon key={type} sport={type} />
      ))}
      {types.length > firstThreeSportTypes.length && <>...</>}
    </span>
  );
};
