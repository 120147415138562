import React from 'react';
import { Outlet } from 'react-router-dom';

import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useAuth } from '@/lib/auth';

import { paths } from './paths';

export const SignedOutRoute: React.FC = () => {
  const { state } = useAuth();

  if (state !== 'SIGNED_OUT') {
    return <CustomNavigate to={{ pathname: paths.home }} />;
  }

  return <Outlet />;
};
