import { NumberPicker } from '@component-library';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepThreeFormData } from '@/pages/add-activity/form-types';

type AddActivityStepThreeSlotsProps = {
  control: Control<StepThreeFormData>;
};

export const AddActivityStepThreeSlots: React.FC<AddActivityStepThreeSlotsProps> = ({ control }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-three.slots' });
  const { field, fieldState } = useController({
    name: 'slots',
    // NOTE: No error message needed because it has a default value and the UI does not allow to enter a not valid value
    rules: { required: true, min: { value: 2, message: t('validation.min') } },
    control,
  });
  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800 mb-2'>{`${t('label')} *`}</label>
      <NumberPicker
        value={field.value}
        onChange={field.onChange}
        withInfinity
        error={fieldState.error?.message}
        min={2}
      />
    </div>
  );
};
