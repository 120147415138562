import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { usePostActivity } from '@/api/resources/activity';
import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { AnalyticsEvents, useAnalytics } from '@/contexts/hooks/useAnalytics';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing/paths';
import { normalizeDSTForServer as normalizeDST } from '@/utils/prettifier/transformDateToTime';

import { useAddActivity } from '../../context/hooks/use-form-provider';
import { StepFourFormData } from '../../form-types';
import { AddActivityFormLayout } from '../../layouts/add-activity-form.layout';
import { isStepOneComplete } from '../../utils/isStepOneComplete';
import { isStepThreeComplete } from '../../utils/isStepThreeComplete';
import { isStepTwoComplete } from '../../utils/isStepTwoComplete';
import { AddActivityStepFourDescription } from './components/descriptions';

const addMinutesToDate = (date: Date, minutes: number) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutes);
  return newDate;
};

export const AddActivityStepFourPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-four' });
  const { mutateAsync, isLoading } = usePostActivity();
  const { track } = useAnalytics();
  const { data, updateData } = useAddActivity();
  const navigate = useCustomNavigate();
  const { control, handleSubmit, watch } = useForm<StepFourFormData>({
    defaultValues: {
      description: data.description ?? '',
    },
  });

  if (!isStepOneComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepOne }} />;
  }

  if (!isStepTwoComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepTwo }} />;
  }

  if (!isStepThreeComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepThree }} />;
  }

  const description = watch('description');

  const submitForm = (updatedData: StepFourFormData) => {
    const wizardFormData = { ...data, ...updatedData };

    const { duration } = wizardFormData;

    const submitData = {
      type: data.sportType,
      startsAt: normalizeDST(wizardFormData.startDate).toISOString(),
      endsAt: duration ? normalizeDST(addMinutesToDate(wizardFormData.startDate, duration)).toISOString() : undefined,
      coordinates: wizardFormData.coordinates,
      locationDescription: wizardFormData.locationDescription,
      slots: wizardFormData.slots === Infinity ? undefined : wizardFormData.slots,
      skillLevel: wizardFormData.skillLevel === '' ? undefined : wizardFormData.skillLevel,
      price: wizardFormData.price,
      description: wizardFormData.description,
      frequency: wizardFormData.frequency,
      repeatUntil: wizardFormData.repeatUntil ? normalizeDST(wizardFormData.repeatUntil).toISOString() : undefined,
    };

    const requestPromise = mutateAsync({ bodyData: submitData });

    toast.promise(requestPromise, {
      loading: t('submit.loading'),
      success: t('submit.success'),
      error: t('submit.error'),
    });

    requestPromise
      .then((createdActivity) => {
        track.event({
          name: AnalyticsEvents.CREATE_ACTIVITY,
          params: { activityId: createdActivity.id, type: createdActivity.type },
        });
        navigate({ pathname: paths.details(createdActivity.id).base });
      })
      .catch((error) => {
        Logger.error(`Error while creating activity: ${JSON.stringify(submitData)}: ${error}`);
      });
  };

  const handlePrevious = () => {
    updateData({ description });
    navigate({ pathname: paths.addActivity.stepThree });
  };

  return (
    <AddActivityFormLayout
      onSubmit={handleSubmit(submitForm)}
      activeStep='four'
      FooterProps={{
        leftButton: { label: t('layout.buttons.back'), onClick: handlePrevious },
        rightButton: { label: t('layout.buttons.submit'), isLoading },
      }}
    >
      <div className='mx-7'>
        <AddActivityStepFourDescription control={control} />
      </div>
    </AddActivityFormLayout>
  );
};
