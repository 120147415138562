import { Icon } from '@component-library';
import React from 'react';

import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

type DetailedActivityHeaderProps = {
  imageSrc: string;
  title: string;
};

export const DetailedActivityHeader: React.FC<DetailedActivityHeaderProps> = ({ imageSrc, title }) => {
  const navigate = useCustomNavigate();

  const handleOnBackClick = () => {
    navigate({ pathname: paths.home });
  };

  return (
    <div className='w-full'>
      <div
        className='relative col-span-2 h-16 flex flex-col items-center overflow-hidden justify-center bg-gray-200'
        style={{
          backgroundImage: `url(${imageSrc})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderTopRightRadius: 'var(--rsbs-overlay-rounded)',
          borderTopLeftRadius: 'var(--rsbs-overlay-rounded)',
        }}
      >
        <div className='absolute w-full h-full bg-black/40 z-10' />
        <span className='text-white text-2xl z-20 flex flex-row gap-2 items-center pt-2 text-center px-5'>{title}</span>
        <div className='absolute right-0 top-0 p-1 z-20 hover:cursor-pointer' onClick={handleOnBackClick}>
          <Icon color='white' iconKey='x' />
        </div>
      </div>
    </div>
  );
};
