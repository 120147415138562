import {
  StepFourFormData,
  StepOneFormData,
  StepThreeFormData,
  StepTwoFormData,
  ValidStepOneFormData,
  ValidStepTwoFormData,
} from '../form-types';
import { isStepOneComplete } from './isStepOneComplete';

export const isStepTwoComplete = (
  formData: Partial<StepOneFormData & StepTwoFormData & StepThreeFormData & StepFourFormData>,
): formData is ValidStepOneFormData & ValidStepTwoFormData & Partial<StepThreeFormData & StepFourFormData> => {
  return isStepOneComplete(formData) && !!formData.coordinates && !!formData.locationDescription;
};
