import { useMutation } from 'react-query';

import { postRequest } from './requests/post';
import { RequestData } from './requests/types';

export const useApiPostRequest = <PathVariables, QueryParams, BodyData, SuccessResponse, ErrorResponse = Error>(
  path: string,
) => {
  const controller = new AbortController();
  const { signal } = controller;

  const mutation = useMutation<SuccessResponse, ErrorResponse, RequestData<PathVariables, QueryParams, BodyData>>(
    (params) =>
      postRequest<SuccessResponse>(path, params?.pathVariables, params?.queryParams, params?.bodyData, signal),
  );

  const abort = () => {
    controller.abort();
    mutation.reset();
  };

  return {
    abort,
    ...mutation,
  };
};
