import { MapIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePatchActivityJoin } from '@/api/resources/activity';
import { CustomLink } from '@/components/custom-link/custom-link';
import { DeleteLeaveActivityButton } from '@/components/delete-leave-activity/button';
import { DateInfoBox } from '@/components/info-box/date/date';
import { InfoBox, InfoBoxContent, InfoBoxIcon } from '@/components/info-box/info-box';
import { LocationInfoBox } from '@/components/info-box/location/location-info-box';
import { Organizers } from '@/components/members/organizers/organizers';
import { Participants } from '@/components/members/participants/participants';
import { useActivityById } from '@/hooks/useActivityById';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { useAuth } from '@/lib/auth';
import { Logger } from '@/lib/logs/logger';
import { Auth } from '@/pages/index/components/auth/auth';
import { paths } from '@/routing/paths';

import { Button, Divider, Icon, Logo, Modal } from '../../../../component-library/components';
import { ActivityIndexSkeletonPage } from './skeleton.page';

export const ActivityIndexPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useCustomNavigate();

  const { activityId } = useParams();

  const { data: detailedActivity, status: statusGetActivity, refetch } = useActivityById(activityId || '', {
    enabled: !!activityId,
  });

  if (!activityId) {
    // NOTE: error should be thrown and error boundary should catch it
    Logger.errorOnce('Critical error: activeActivityId is undefined in the detailed activity component.');
    return null;
  }

  if (statusGetActivity !== 'success') {
    return <ActivityIndexSkeletonPage />;
  }

  const isUserParticipating = detailedActivity.participants.some((participant) => participant.id === user?.id);
  const isOwner = user?.id === detailedActivity.createdBy;

  const handleLeaveDeleteSuccess = () => {
    if (isOwner) {
      navigate({ pathname: paths.home });
    }
    refetch();
  };

  return (
    <div className='w-full px-6 py-2'>
      <div className='text-3xl text-gray-900 my-1.5'>
        {detailedActivity.title ?? t(`globals.sportType.${detailedActivity.sport}`)}
      </div>
      <Divider className='border-gray-400' />
      <div className='mt-5 px-1'>
        <span className='text-gray-900'>{detailedActivity.description}</span>
        <div className='mt-5 flex flex-col gap-4'>
          {detailedActivity.organizers.length > 0 && <Organizers organizers={detailedActivity.organizers} />}
          <DateInfoBox
            startsAt={detailedActivity.startsAt}
            endsAt={detailedActivity.endsAt}
            frequency={detailedActivity.frequency}
            lastDate={detailedActivity.lastDate}
          />
          <LocationInfoBox {...detailedActivity.location} />
          {detailedActivity.skillLevel && (
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='skill' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <span className='break-words'>{detailedActivity.skillLevel}</span>
              </InfoBoxContent>
            </InfoBox>
          )}
          <InfoBox>
            <InfoBoxIcon>
              <Icon iconKey='price' />
            </InfoBoxIcon>
            <InfoBoxContent>
              <span>{t('globals.activity.price', { count: detailedActivity.price })}</span>
            </InfoBoxContent>
          </InfoBox>
          {detailedActivity.link && (
            <InfoBox>
              <InfoBoxIcon>
                <Icon iconKey='globe' />
              </InfoBoxIcon>
              <InfoBoxContent>
                <a href={detailedActivity.link} target='_blank' rel='noreferrer' className='underline'>
                  {detailedActivity.link}
                </a>
              </InfoBoxContent>
            </InfoBox>
          )}
          <InfoBox>
            <InfoBoxIcon>
              <Icon iconKey='comments' />
            </InfoBoxIcon>
            <InfoBoxContent>
              <CustomLink to={{ pathname: paths.activity(detailedActivity.id).comments }}>
                <span className='underline'>
                  {t('globals.comments.label', { count: detailedActivity.commentsCount })}
                </span>
              </CustomLink>
            </InfoBoxContent>
          </InfoBox>
          {detailedActivity.withJoinFeature && (
            <div className='flex flex-row gap-4 justify-between mt-2 items-center'>
              <Participants
                freeSlots={
                  detailedActivity.slots ? detailedActivity.slots - detailedActivity.participants.length : undefined
                }
                participants={detailedActivity.participants}
              />
              {isUserParticipating ? (
                <DeleteLeaveActivityButton
                  activityId={detailedActivity.id}
                  createdBy={detailedActivity.createdBy}
                  onSuccess={handleLeaveDeleteSuccess}
                />
              ) : (
                <JoinButton activityId={detailedActivity.id} onSuccess={refetch} />
              )}
            </div>
          )}
        </div>
      </div>
      <Divider className='border-gray-400 my-5' />
      <div className='flex flex-row justify-between gap-5 mb-5 items-center'>
        <CustomLink to={{ pathname: paths.home }}>
          <Button className='flex-grow max-w-xs text-gray-900 text-sm py-1.5 text-center'>
            <MapIcon width={16} height={16} />
            {t('activity.button.findMore')}
          </Button>
        </CustomLink>
        <CustomLink to={{ pathname: paths.home }}>
          <Logo variant='small' color='gray' />
        </CustomLink>
      </div>
    </div>
  );
};

type JoinButtonProps = {
  activityId: string;
  onSuccess: () => void;
};

const JoinButton: React.FC<JoinButtonProps> = ({ activityId, onSuccess }) => {
  const { t } = useTranslation();
  const { state: signedInState } = useAuth();
  const { mutateAsync: joinActivity, status: statusJoinActivity } = usePatchActivityJoin();
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);

  const handleJoinActivityClick = () => {
    if (signedInState === 'SIGNED_IN') {
      joinActivity({ pathVariables: { id: activityId } })
        .then(() => {
          toast.success(t('globals.join-activity.success'));
          onSuccess();
        })
        .catch(() => {
          // NOTE: Show probably a specific error for activity is already booked out
          toast.error(t('globals.errors.unexpected'));
        });
    } else if (signedInState === 'SIGNED_OUT') {
      setIsAuthDialogOpen(true);
    }
  };

  const closeDialog = () => {
    setIsAuthDialogOpen(false);
  };

  return (
    <>
      <Modal open={isAuthDialogOpen} handleClose={closeDialog} withXButton>
        <div className='pt-8 pb-4 px-4'>
          <div className='w-full text-center mb-5 text-lg text-indigo-500'>{t('globals.auth.join')}</div>
          <Auth
            joinActivityId={activityId}
            previousPath={paths.activity(activityId).base}
            onSuccessPath={paths.activity(activityId).base}
          />
        </div>
      </Modal>
      <Button
        className='flex-grow max-w-xs text-gray-900 py-1'
        onClick={handleJoinActivityClick}
        isLoading={statusJoinActivity === 'loading'}
      >
        {t('activity.button.join')}
      </Button>
    </>
  );
};
