import { Button } from '@component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomLink } from '@/components/custom-link/custom-link';
import { paths } from '@/routing/paths';

export const MyActivitiesIndexNoActivities: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'my-activities.empty' });

  return (
    <div className='flex flex-col items-center'>
      <span className='mt-10 text-gray-600'>{t('info')}</span>
      <CustomLink to={{ pathname: paths.home }}>
        <Button className='mt-10' variant='outlined'>
          {t('button')}
        </Button>
      </CustomLink>
    </div>
  );
};
