import { QueryKey, UseBaseQueryOptions, useQuery } from 'react-query';

import { getRequest } from './requests/get';
import { RequestData } from './requests/types';

export const useApiGetRequest = <PathVariables, QueryParams, SuccessResponse, ErrorResponse = Error>(
  queryKey: QueryKey,
  path: string,
  params?: RequestData<PathVariables, QueryParams, undefined>,
  options?: UseBaseQueryOptions<SuccessResponse, ErrorResponse>,
) => {
  return useQuery<SuccessResponse, ErrorResponse>(
    queryKey,
    () => getRequest<SuccessResponse>(path, params?.pathVariables, params?.queryParams),
    options,
  );
};
