import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scrolls to the top of the page on every navigation. Does not apply to the
 * browser back/forward buttons, which is the intended behaviour.
 * https://v5.reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
