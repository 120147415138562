import { GetActivityByIdSuccessResponse } from '../../api/resources/activity';
import { BaseActivity, Frequency, Participant } from '../../types';
import { convertStringToSportType } from '../../utils/converter/convertStringToSportType';
import { Logger } from '../logs/logger';

export type BaseActivityResponse = {
  id: string;
  type: string;
  startsAt: string;
  endsAt?: string;
  startTime: string;
  frequency: Frequency;
  durationInMinutes: number;
  firstDate: string;
  lastDate?: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  createdBy: string;
  participants: {
    id: string;
    firstName?: string;
    profilePictureUrl?: string;
  }[];
  organizers: {
    id: string;
    firstName?: string;
    profilePictureUrl?: string;
  }[];
  locationDescription: string;
  slots?: number;
  commentsCount: number;
  title?: string;
  coverPictureUrl?: string;
  logoPictureUrl?: string;
  link?: string;
};

export const mapParticipantResponse = (participants: GetActivityByIdSuccessResponse['participants']): Participant[] =>
  participants.map((participant) => {
    const firstName = participant.firstName ?? 'Nameless';

    if (!participant.firstName) {
      Logger.warn(
        `Participant ${participant.id} has no first name which can happen but a complete account logic should be implemented to prevent this.`,
      );
    }

    return {
      id: participant.id,
      firstName,
      profilePictureUrl: participant.profilePictureUrl,
    };
  });

export const transformBaseActivityResponse = (responseActivity: BaseActivityResponse): BaseActivity => {
  try {
    const sportType = convertStringToSportType(responseActivity.type);
    const startsAt = new Date(responseActivity.startsAt);
    const endsAt = responseActivity.endsAt ? new Date(responseActivity.endsAt) : undefined;
    const startTime = new Date(responseActivity.startTime);
    const firstDate = new Date(responseActivity.firstDate);
    const lastDate = responseActivity.lastDate ? new Date(responseActivity.lastDate) : undefined;

    const activity: BaseActivity = {
      id: responseActivity.id,
      sport: sportType,
      slots: responseActivity.slots,
      participants: mapParticipantResponse(responseActivity.participants),
      organizers: mapParticipantResponse(responseActivity.organizers),
      startsAt,
      endsAt,
      frequency: responseActivity.frequency,
      durationInMinutes: responseActivity.durationInMinutes,
      firstDate,
      lastDate,
      startTime,
      location: {
        description: responseActivity.locationDescription,
        coordinates: responseActivity.coordinates,
      },
      commentsCount: responseActivity.commentsCount,
      coverPictureUrl: responseActivity.coverPictureUrl,
      logoPictureUrl: responseActivity.logoPictureUrl,
      title: responseActivity.title,
      link: responseActivity.link,
      // NOTE: This is a easy solution for now. If a activity has no participants, it is not possible to join it.
      // NOTE: This should be handled in the backend but for now it is faster to do it here.
      withJoinFeature: responseActivity.participants.length > 0,
    };

    return activity;
  } catch (e) {
    throw new Error(`Could not transform detailed activity ${responseActivity.id}: ${e}`);
  }
};
