import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@/contexts/hooks/useUser';

import { MyProfilePicture } from './picture/picture';

export const Profile: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'myProfile' });
  const user = useUser();

  return (
    <>
      <div className='mt-8 m-7 gap-2 mb-4'>
        <MyProfilePicture />
        <div className='flex flex-col gap-6 w-full'>
          <p>
            <b>{`${t('labels.name')}`}:</b> {user.firstName ?? t('form.missingName')}
          </p>
          <p>
            <b>{`${t('labels.email')}`}:</b> {user.email ?? t('form.missingEmail')}
          </p>
        </div>
      </div>
    </>
  );
};
