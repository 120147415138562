/* eslint-disable no-underscore-dangle */
import { IsOptional, IsString } from 'class-validator';

type UserProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
  profilePictureUrl?: string;
  privacyPolicyAcceptedAt?: string;
  emailVerifiedAt?: string;
};

export class User {
  @IsString()
  private _id: string;

  @IsOptional()
  @IsString()
  private _firstName?: string;

  @IsOptional()
  @IsString()
  private _lastName?: string;

  @IsOptional()
  @IsString()
  private _email?: string;

  @IsOptional()
  @IsString()
  private _dateOfBirth?: string;

  @IsOptional()
  @IsString()
  private _profilePictureUrl?: string;

  @IsOptional()
  @IsString()
  private _privacyPolicyAcceptedAt?: string;

  @IsOptional()
  @IsString()
  private _emailVerifiedAt?: string;

  constructor({
    id,
    firstName,
    lastName,
    email,
    dateOfBirth,
    profilePictureUrl,
    privacyPolicyAcceptedAt,
    emailVerifiedAt,
  }: UserProps) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._dateOfBirth = dateOfBirth;
    this._profilePictureUrl = profilePictureUrl;
    this._privacyPolicyAcceptedAt = privacyPolicyAcceptedAt;
    this._emailVerifiedAt = emailVerifiedAt;
  }

  get id(): string {
    return this._id;
  }

  get firstName(): string | undefined {
    return this._firstName;
  }

  get lastName(): string | undefined {
    return this._lastName;
  }

  get email(): string | undefined {
    return this._email;
  }

  get dateOfBirth(): string | undefined {
    return this._dateOfBirth;
  }

  get profilePictureUrl(): string | undefined {
    return this._profilePictureUrl;
  }

  get privacyPolicyAcceptedAt(): string | undefined {
    return this._privacyPolicyAcceptedAt;
  }

  get emailVerifiedAt(): string | undefined {
    return this._emailVerifiedAt;
  }
}
