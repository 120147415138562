import {
  StepFourFormData,
  StepOneFormData,
  StepThreeFormData,
  StepTwoFormData,
  ValidStepOneFormData,
  ValidStepThreeFormData,
  ValidStepTwoFormData,
} from '../form-types';
import { isStepTwoComplete } from './isStepTwoComplete';

export const isStepThreeComplete = (
  formData: Partial<StepOneFormData & StepTwoFormData & StepThreeFormData & StepFourFormData>,
): formData is ValidStepOneFormData & ValidStepTwoFormData & ValidStepThreeFormData & Partial<StepFourFormData> => {
  return isStepTwoComplete(formData) && formData.slots !== undefined && !!formData.price !== undefined;
};
