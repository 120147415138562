import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type AvatarGroupProps = {
  className?: string;
};

export const AvatarGroup: React.FC<PropsWithChildren<AvatarGroupProps>> = ({ children, className }) => {
  return <div className={twMerge('flex flex-row h-8   [&>*:nth-child(n+2)]:-ml-1.5', className)}>{children}</div>;
};
