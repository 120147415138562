import React, { PropsWithChildren } from 'react';

import { AddActivityFooter, AddActivityFooterProps } from '../components/footer/footer';
import { ActiveSteps, AddActivityNavbar } from '../components/navbar/navbar';

type AddActivityPageWrapperProps = {
  onSubmit: () => void;
  activeStep: ActiveSteps;
  FooterProps: AddActivityFooterProps;
};

export const AddActivityFormLayout: React.FC<PropsWithChildren<AddActivityPageWrapperProps>> = ({
  onSubmit,
  activeStep,
  children,
  FooterProps,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className='w-full'>
        <AddActivityNavbar activeStep={activeStep} />
        <div
          className='overflow-y-scroll py-4 h-full mb-16'
          style={{
            // 132 px = height of navbar + footer
            minHeight: `calc(100vh - 132px)`,
          }}
        >
          {children}
        </div>
        <AddActivityFooter {...FooterProps} />
      </div>
    </form>
  );
};
