import React from 'react';

export const SkeletonItem: React.FC = () => {
  return (
    <div className='flex flex-row justify-between text-base py-2 px-4 text-gray-300 animate-pulse'>
      <div className='flex flex-col gap-2'>
        <div className='ml-9 w-28 h-5 bg-gray-300 rounded-full mb-2' />
        <div className='flex flex-row items-center'>
          <div className='bg-gray-300 h-5 w-5 rounded-full mr-3 ml-1' />
          <div className='flex flex-col flex-grow gap-1'>
            <span className='w-32 h-4 bg-gray-300 rounded-full' />
            <span className='w-20 h-4 bg-gray-300 rounded-full' />
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='bg-gray-300 h-5 w-5 rounded-full mr-3 ml-1' />
          <div className='flex flex-col flex-grow gap-1'>
            <span className='w-24 h-4 bg-gray-300 rounded-full' />
            <span className='w-36 h-4 bg-gray-300 rounded-full' />
          </div>
        </div>
        <div className='ml-1 mt-3'>
          <div className='flex flex-row h-9'>
            <div className='w-9 h-9 bg-gray-300 rounded-full' />
            <div className='w-9 h-9 bg-gray-300 rounded-full -ml-1.5' />
            <div className='-ml-1.5 h-full w-24 bg-gray-300 flex items-center rounded-full'></div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <div className='flex flex-row items-center flex-grow'>
          <div className='w-24 h-24 bg-gray-300 rounded-md' />
        </div>
        <div className='w-24 h-8 bg-gray-300 rounded-full mt-3' />
      </div>
    </div>
  );
};
