import React, { ForwardRefRenderFunction } from 'react';
import { twMerge } from 'tailwind-merge';

type TextAreaProps = {
  error?: string;
  className?: string;
  InputProps?: {
    className?: string;
  };
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const PlainTextArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaProps> = (
  { rows = 4, error, className, InputProps, ...props },
  ref,
) => {
  return (
    <div className={className}>
      <textarea
        {...ref}
        rows={rows}
        className={twMerge(
          'block',
          'w-full',
          'rounded-md',
          'border-0',
          'p-1.5',
          'text-gray-900',
          'shadow-sm',
          'ring-1',
          'ring-inset',
          'ring-gray-300',
          'placeholder:text-gray-400',
          'focus:outline-none',
          'focus:ring-2',
          'focus:ring-indigo-600',
          'border',
          // NOTE: The text must be at least 16 px to prevent the browser from zooming in on focus
          'text-lg',
          'sm:leading-6',
          props.disabled && 'bg-gray-100 placeholder:text-gray-600',
          InputProps?.className,
          error && 'ring-1 ring-red-500 border-red-500',
        )}
        {...props}
      />
      {error && <p className='ml-1 mt-1 text-base text-red-500'>{error}</p>}
    </div>
  );
};

export const TextArea = React.forwardRef(PlainTextArea);
