import React from 'react';

export const MyActivitiesIndexActivitySkeleton: React.FC = () => {
  return (
    <div>
      <div className='flex flex-col py-4 pl-7 pr-3 gap-3 animate-pulse'>
        <div className='flex flex-col pr-3 gap-1'>
          <span className='w-28 h-4 bg-gray-300 rounded-full' />
          <span className='w-40 h-4 bg-gray-300 rounded-full' />
          <span className='w-36 h-4 bg-gray-300 rounded-full' />
          <span className='w-32 h-4 bg-gray-300 rounded-full' />
        </div>
        <div className='flex flex-row justify-between items-center'>
          <div className='w-20 h-8 bg-gray-300 rounded-full' />
          <span className='w-28 h-4 bg-gray-300 rounded-full' />
        </div>
      </div>
    </div>
  );
};
