import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CustomNavigate } from '@/components/custom-navigate/custom-navigate';
import { useCustomNavigate } from '@/hooks/useCustomNavigate';
import { paths } from '@/routing/paths';

import { useAddActivity } from '../../context/hooks/use-form-provider';
import { StepTwoFormData } from '../../form-types';
import { AddActivityFormLayout } from '../../layouts/add-activity-form.layout';
import { isStepOneComplete } from '../../utils/isStepOneComplete';
import { AddActivityStepTwoLocationDescription } from './components/location-description';
import { AddActivityStepTwoMap } from './components/map';

export const AddActivityStepTwoPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity' });
  const { data, updateData } = useAddActivity();
  const navigate = useCustomNavigate();
  const { handleSubmit, control, watch } = useForm<StepTwoFormData>({
    defaultValues: {
      coordinates: data.coordinates,
      locationDescription: data.locationDescription,
    },
  });

  if (!isStepOneComplete(data)) {
    return <CustomNavigate to={{ pathname: paths.addActivity.stepOne }} />;
  }

  const coordinates = watch('coordinates');
  const locationDescription = watch('locationDescription');

  const handleNext = (submitData: StepTwoFormData) => {
    updateData({ coordinates: submitData.coordinates, locationDescription: submitData.locationDescription });
    navigate({ pathname: paths.addActivity.stepThree });
  };

  const handlePrevious = () => {
    updateData({ coordinates, locationDescription });
    navigate({ pathname: paths.addActivity.stepOne });
  };

  return (
    <AddActivityFormLayout
      onSubmit={handleSubmit(handleNext)}
      activeStep='two'
      FooterProps={{
        leftButton: { label: t('layout.footer.buttons.back'), onClick: handlePrevious },
        rightButton: { label: 'weiter' },
      }}
    >
      <div className='mx-4 flex flex-col gap-y-8'>
        <AddActivityStepTwoMap control={control} />
        <AddActivityStepTwoLocationDescription control={control} />
      </div>
    </AddActivityFormLayout>
  );
};
