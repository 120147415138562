import { Divider, Logo } from '@component-library';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { Menu } from '@/components/menu/menu';
import { paths } from '@/routing/paths';

import { MyProfileDisclaimer } from './components/disclaimer';
import { Profile } from './components/profile';

export const MyProfilePage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'myProfile' });
  const { state } = useLocation();
  const previousPath = state?.previousPath || paths.home;

  return (
    <>
      <div className='mt-4 pl-7 pr-3 mb-14 relative'>
        <CustomLink to={{ pathname: paths.home }}>
          <Logo variant='medium' />
        </CustomLink>
        <Menu />
      </div>
      <div>
        <div className='flex flex-row ml-7 items-center gap-2 mb-4'>
          <CustomLink to={{ pathname: previousPath }}>
            <ChevronLeftIcon width={24} height={24} />
          </CustomLink>
          <span className='text-2xl text-gray-600'>{t('title')}</span>
        </div>
        <Divider />
        <div className='flex flex-col justify-center items-center'>
          <Profile />
          <MyProfileDisclaimer />
        </div>
      </div>
    </>
  );
};
