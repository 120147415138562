import {
  StepFourFormData,
  StepOneFormData,
  StepThreeFormData,
  StepTwoFormData,
  ValidStepOneFormData,
} from '../form-types';

export const isStepOneComplete = (
  formData: Partial<StepOneFormData & StepTwoFormData & StepThreeFormData & StepFourFormData>,
): formData is ValidStepOneFormData & Partial<StepTwoFormData & StepThreeFormData & StepFourFormData> => {
  return !!formData.sportType && !!formData.startDate && !!formData.frequency;
};
