import { SelectInput, SelectInputOption } from '@component-library';
import React, { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepOneFormData } from '../../../form-types';

type AddActivityStepOneDurationProps = {
  control: Control<StepOneFormData>;
};

export const AddActivityStepOneDuration: React.FC<AddActivityStepOneDurationProps> = ({ control }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-one.duration' });
  const { field } = useController({
    rules: {},
    control,
    name: 'duration',
  });

  const durationOptions: Record<number, string> = useMemo(
    () => ({
      30: t('minutes', { count: 30 }),
      60: t('hours', { count: 1 }),
      90: t('hours', { count: 1.5 }),
      120: t('hours', { count: 2 }),
      150: t('hours', { count: 2.5 }),
      180: t('hours', { count: 3 }),
      210: t('hours', { count: 3.5 }),
      240: t('hours', { count: 4 }),
    }),
    [t],
  );

  return (
    <SelectInput
      label={t('label')}
      placeholder={t('placeholder')}
      selectedItem={{ value: field.value, label: field.value ? durationOptions[field.value] : '' }}
      onChange={field.onChange}
    >
      <SelectInputOption value='' label={t('empty')} isActive={false} />
      {Object.entries(durationOptions).map(([key, value]) => (
        <SelectInputOption key={key} value={Number(key)} label={value} isActive={field.value === Number(key)} />
      ))}
    </SelectInput>
  );
};
