import 'react-datepicker/dist/react-datepicker.css';

import { Icon, IconButton, SelectInput, SelectInputOption } from '@component-library';
import cx from 'classnames';
import de from 'date-fns/locale/de';
import React, { useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { Frequency } from '@/types';
import { prettifyDate } from '@/utils/prettifier/transformDateToTime';

import { StepOneFormData } from '../../../../form-types';
import { DateInfoBox } from './date-info-box';

// NOTE: This is hard coded to german, must be adjusted when we support multiple languages
registerLocale('de', de);

enum DateSection {
  DATE = 'date',
  TIME = 'time',
}

type AddActivityStepOneDateProps = {
  control: Control<StepOneFormData>;
};

export const AddActivityStepOneDate: React.FC<AddActivityStepOneDateProps> = (props) => {
  const [activeDateSelection, setActiveDateSelection] = useState<DateSection>();
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-one.date' });

  const { field: frequencyField } = useController({
    rules: {},
    control: props.control,
    name: 'frequency',
    defaultValue: Frequency.ONCE,
  });
  const { field: repeatUntilField } = useController({
    rules: {},
    control: props.control,
    name: 'repeatUntil',
  });
  const [isLastDateSelectionActive, setLastDateSelectionActive] = useState(false);

  const { field, fieldState } = useController({
    rules: {
      required: true,
      validate: (value) => {
        const now = new Date();
        now.setHours(now.getHours() + 2);
        if (value < now) return t('validation.future');

        return true;
      },
    },
    control: props.control,
    name: 'startDate',
  });

  const prettifyTime = (hour: number) => `${`0${hour}`.slice(-2)}`;

  const prettifiedHour = prettifyTime(field.value.getHours());
  const prettifiedMinute = prettifyTime(field.value.getMinutes());

  const handleChangeDate = (date: Date) => {
    field.onChange(
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), field.value.getHours(), field.value.getMinutes()),
    );

    setActiveDateSelection(DateSection.TIME);
  };

  const handleChangeRepeatUntil = (date: Date | null) => {
    if (!date) {
      repeatUntilField.onChange(undefined);
    } else {
      repeatUntilField.onChange(
        new Date(date.getFullYear(), date.getMonth(), date.getDate(), field.value.getHours(), field.value.getMinutes()),
      );
    }

    setLastDateSelectionActive(false);
  };

  const handleChangeHour = (hour: number) => {
    field.onChange(
      new Date(
        field.value.getFullYear(),
        field.value.getMonth(),
        field.value.getDate(),
        hour,
        field.value.getMinutes(),
      ),
    );
    setActiveDateSelection(DateSection.TIME);
  };

  const handleChangeMinute = (minute: number) => {
    field.onChange(
      new Date(
        field.value.getFullYear(),
        field.value.getMonth(),
        field.value.getDate(),
        field.value.getHours(),
        minute,
      ),
    );

    setActiveDateSelection(undefined);
  };

  const handleOnDateInfoBoxClick = (dateSection: DateSection) => {
    if (activeDateSelection === dateSection) {
      setActiveDateSelection(undefined);
    } else {
      setActiveDateSelection(dateSection);
    }
  };

  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800'>{`${t('label')} *`}</label>
      <div className='flex flex-row justify-between mt-4 gap-4'>
        <DateInfoBox
          isActive={activeDateSelection === DateSection.DATE}
          onClick={() => handleOnDateInfoBoxClick(DateSection.DATE)}
        >
          {prettifyDate(field.value)}
        </DateInfoBox>
        <DateInfoBox
          isActive={activeDateSelection === DateSection.TIME}
          onClick={() => handleOnDateInfoBoxClick(DateSection.TIME)}
        >
          {t('time', { hour: prettifiedHour, minute: prettifiedMinute })}
        </DateInfoBox>
      </div>
      {fieldState.error && <p className='ml-1 mt-1 text-base text-red-600'>{fieldState.error.message}</p>}
      <div className={cx('mt-4', 'flex', 'flex-row')}>
        {activeDateSelection === DateSection.DATE && (
          <div className='flex flex-row justify-center w-full'>
            <ReactDatePicker selected={field.value} onChange={handleChangeDate} inline locale='de' />
          </div>
        )}
        <div
          className={twMerge(
            'w-full flex flex-row gap-3 justify-center',
            // NOTE: This must be part of the dom because otherwise mui does not change the selection value accordingly
            activeDateSelection !== DateSection.TIME && 'hidden',
          )}
        >
          <SelectInput
            onChange={(value) => {
              if (value == null) return;
              handleChangeHour(value);
            }}
            selectedItem={{ value: field.value.getHours(), label: prettifiedHour }}
            withIcon={false}
            className='min-w-fit w-20 h-20 text-center'
            textClassName='text-3xl text-center font-medium'
            ListBox={{
              className: 'min-w-fit w-20',
            }}
          >
            {[...Array(24).keys()].map((value) => {
              const hour = value;
              return (
                <SelectInputOption
                  key={hour}
                  value={hour}
                  label={prettifyTime(hour)}
                  isActive={hour === field.value.getHours()}
                />
              );
            })}
          </SelectInput>

          <div className='flex flex-col justify-evenly'>
            <div className='w-2.5 h-2.5 rounded-full bg-gray-300'></div>
            <div className='w-2.5 h-2.5 rounded-full bg-gray-300'></div>
          </div>

          <SelectInput
            onChange={(value) => {
              if (value == null) return;
              handleChangeMinute(value);
            }}
            selectedItem={{ value: field.value.getMinutes(), label: prettifiedMinute }}
            withIcon={false}
            className='min-w-fit w-20 h-20 text-center'
            textClassName='text-3xl text-center font-medium'
            ListBox={{
              className: 'min-w-fit w-20',
            }}
          >
            {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((minute) => {
              return (
                <SelectInputOption
                  key={minute}
                  value={minute}
                  label={prettifyTime(minute)}
                  isActive={minute === field.value.getMinutes()}
                />
              );
            })}
          </SelectInput>
        </div>
      </div>
      <div className={cx('mt-4', 'flex', 'flex-row gap-4')}>
        <div className='flex-1'>
          <SelectInput
            selectedItem={{ value: frequencyField.value, label: `${t(`repeat.option.${frequencyField.value}`)}` }}
            onChange={(e) => frequencyField.onChange(e || Frequency.ONCE)}
            className='min-w-0 w-full'
          >
            <SelectInputOption
              value={Frequency.ONCE}
              label={t('repeat.option.once')}
              isActive={frequencyField.value === Frequency.ONCE}
            />
            <SelectInputOption
              value={Frequency.WEEKLY}
              label={t('repeat.option.weekly')}
              isActive={frequencyField.value === Frequency.WEEKLY}
            />
          </SelectInput>
        </div>
        <div className='flex-1'>
          {frequencyField.value === Frequency.WEEKLY && (
            <>
              <DateInfoBox
                isActive={isLastDateSelectionActive}
                onClick={() => setLastDateSelectionActive((prev) => !prev)}
              >
                <div className='inline-flex items-center'>
                  {repeatUntilField.value
                    ? t('repeat.until.end', {
                        date: t('date', {
                          day: `0${repeatUntilField.value.getDate()}`.slice(-2),
                          month: `0${repeatUntilField.value.getMonth() + 1}`.slice(-2),
                          year: repeatUntilField.value.getFullYear(),
                        }),
                      })
                    : t('repeat.until.not_set')}
                  {repeatUntilField.value && (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleChangeRepeatUntil(null);
                      }}
                      className='py-0'
                    >
                      <Icon iconKey='x' height={20} width={20} />
                    </IconButton>
                  )}
                </div>
              </DateInfoBox>
            </>
          )}
        </div>
      </div>
      {isLastDateSelectionActive && (
        <div className='flex mt-4 flex-row justify-center w-full'>
          <ReactDatePicker
            selected={repeatUntilField.value}
            onChange={handleChangeRepeatUntil}
            locale='de'
            minDate={field.value}
            inline
          />
        </div>
      )}
    </div>
  );
};
