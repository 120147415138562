import { TextInput } from '@component-library';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepThreeFormData } from '@/pages/add-activity/form-types';

type AddActivityStepThreeSkillLevelProps = {
  control: Control<StepThreeFormData>;
};

export const AddActivityStepThreeSkillLevel: React.FC<AddActivityStepThreeSkillLevelProps> = ({ control }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'add-activity.step-three.level' });
  const { field, fieldState } = useController({
    name: 'skillLevel',
    control,
  });
  return (
    <div>
      <label className='block text-base font-medium leading-6 text-gray-800 mb-1'>{t('label')}</label>
      <p className='text-base text-gray-500 mb-2'>{t('hint')}</p>
      <TextInput
        value={field.value}
        onChange={field.onChange}
        placeholder='z.B. beim Laufen die Geschwindigkeit'
        error={fieldState.error?.message}
      />
    </div>
  );
};
