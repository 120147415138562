import { Logo } from '@component-library';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { CustomLink } from '@/components/custom-link/custom-link';
import { Menu } from '@/components/menu/menu';
import { paths } from '@/routing/paths';

export const MyActivitiesPage: React.FC = () => {
  return (
    <div>
      <div className='mt-4 pl-7 pr-3 mb-14 relative'>
        <CustomLink to={{ pathname: paths.home }}>
          <Logo variant='medium' />
        </CustomLink>
        <Menu />
      </div>
      <Outlet />
    </div>
  );
};
